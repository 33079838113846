import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['navlinkDropdown', 'navlinkSidebar', 'chevronDown', 'chevronUp']

  toggleCollapse() {
    this.navlinkDropdownTarget.classList.toggle('expanded')
    this.navlinkSidebarTarget.classList.toggle('collapse')
    this.chevronDownTarget.classList.toggle('hidden')
    this.chevronUpTarget.classList.toggle('hidden')
  }
}
