import { GLOBAL_STATE } from "../../state";

function SetImageQr(event) {
  const { country_code } = GLOBAL_STATE

  if (country_code === "CHILE") {
    return '/verification-image.png'
  } else if (country_code === "MEXICO") {
    return '/verification-mx.png'
  }

  return 'verification-usa.png'
}


export default SetImageQr;
