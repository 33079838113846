import React from "react";
import StepLayout from "../StepLayout";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "i18n-js";
import PublicBtn from "../../../components/PublicBtn";
import Typography from "@material-ui/core/Typography";
import useStyles from "./Steps.styles";

function StepOneSessionContent({ next }) {
  const classes = useStyles();

  return (
    <Grid>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Typography variant="h4">
          <label className={classes.wizardSubTitle}>
            {I18n.t("modules.register.before_enter")}
          </label>
        </Typography>
      </Grid>
      <Grid className={classes.titleBox}>
        <Typography variant="h1" className={classes.wizardTitle}>
            {I18n.t("modules.register.register_property")}
        </Typography>
      </Grid>
      <PublicBtn label={I18n.t("modules.register.next")} onClick={next} />
    </Grid>
  );
}

function StepSessionOne({ previous, next }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={1}
      numberStep={2}
      StepContent={StepOneSessionContent}
    ></StepLayout>
  );
}

export default StepSessionOne;
