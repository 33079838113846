import { Controller } from "stimulus";
import AutoNumeric from 'autonumeric';

const AUTONUMERIC_FORMAT = {
  currencySymbol: '$ ',
  currencySymbolPlacement: 'p',
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalPlaces: 0,
  minimumValue: 1,
  overrideMinMaxLimits: 'invalid',
  unformatOnSubmit: true
}

export default class extends Controller {
  static targets = [ 'totalAmount', 'price', 'numberOfMonth' ]

  connect() {
    new AutoNumeric(this.totalAmountTarget, AUTONUMERIC_FORMAT);
    new AutoNumeric(this.priceTarget, AUTONUMERIC_FORMAT);
  }

  calculateAmount(event) {
    const months = parseInt(this.numberOfMonthTarget.value);
    const totalAmountAutoNumeric = AutoNumeric.getAutoNumericElement(this.totalAmountTarget);
    const priceAutoNumeric = AutoNumeric.getAutoNumericElement(this.priceTarget);
    const numberOfMonth = this.numberOfMonthTarget.value;
    const numberOfMonthEmpty = document.getElementById('number_of_months-warning');
    $('#changes').val(true);

    if (numberOfMonth === '') {
      this.totalAmountTarget.readOnly = false;
      this.priceTarget.readOnly = false;
      if (numberOfMonthEmpty.style.display !== 'block') numberOfMonthEmpty.style.display = 'block';
      return
    }

    switch (event.currentTarget) {
      case this.priceTarget:
        if (this.priceTarget.value === '') {
          this.totalAmountTarget.readOnly = false;
          totalAmountAutoNumeric.set('');
          return
        }
        const price = priceAutoNumeric.getNumber();
        totalAmountAutoNumeric.set(price * months);
        this.totalAmountTarget.readOnly = true;
        this.totalAmountTarget.dispatchEvent(new Event('input'));
        break;
      case this.totalAmountTarget:
        if (this.totalAmountTarget.value === '') {
          this.priceTarget.readOnly = false;
          priceAutoNumeric.set('');
          return
        }
        const totalAmount = totalAmountAutoNumeric.getNumber();
        priceAutoNumeric.set(totalAmount / months);
        this.priceTarget.readOnly = true;
        this.priceTarget.dispatchEvent(new Event('input'));
        break;
      case this.numberOfMonthTarget:
        if (numberOfMonthEmpty.style.display === 'block') numberOfMonthEmpty.style.display = 'none';
        this.recalculateAmount(priceAutoNumeric, totalAmountAutoNumeric, numberOfMonth);
        break;
    }
  }

  recalculateAmount(priceAutoNumeric, totalAmountAutoNumeric, numberOfMonth) {
    if (this.priceTarget.value !== '' && numberOfMonth !== '' && this.priceTarget.readOnly !== true) {
      totalAmountAutoNumeric.set(priceAutoNumeric.getNumber() * numberOfMonth)
      this.totalAmountTarget.readOnly = true;
    } else if (totalAmountAutoNumeric.getNumber() !== 0 && numberOfMonth !== '' && this.totalAmountTarget.readOnly !== true) {
      priceAutoNumeric.set(totalAmountAutoNumeric.getNumber() / numberOfMonth)
      this.priceTarget.readOnly = true;
    }
  }

  setFormType(event) {
    const supplierField = document.getElementById('supplier_form_field');
    const chargedToField = document.getElementById('charged_to_form_field');
    const serviceBillingPeriodExpenseField = document.getElementById('service_billing_period_expense_field');
    switch (event.currentTarget.value) {
      case '1':
        chargedToField.style.display = 'none'
        supplierField.removeAttribute('style')
        serviceBillingPeriodExpenseField.style.display = 'none'
        break;
      case '2':
        supplierField.style.display = 'none'
        chargedToField.removeAttribute('style')
        serviceBillingPeriodExpenseField.removeAttribute('style')
        break;
    }
  }
}

