import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modalLink"]

  connect() {
    this.modalLinkTarget.addEventListener('click', this.preventMiddleClick);
    this.modalLinkTarget.addEventListener('auxclick', this.preventMiddleClick);
    this.modalLinkTarget.addEventListener('contextmenu', this.preventContextMenu);
  }

  preventMiddleClick(event) {
    if (event.button !== 0 || event.ctrlKey || event.metaKey || event.shiftKey || event.button === 1) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  preventContextMenu(event) {
    event.preventDefault();
  }

  disconnect() {
    this.modalLinkTarget.removeEventListener('click', this.preventMiddleClick);
    this.modalLinkTarget.removeEventListener('auxclick', this.preventMiddleClick);
    this.modalLinkTarget.removeEventListener('contextmenu', this.preventContextMenu);
  }
}
