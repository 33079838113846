import { GLOBAL_STATE } from "../state";

function HomePage(props) {
  const { noCommunity, loggedAsPropertyUser } = GLOBAL_STATE;
  if (noCommunity) {
    window.location.href = "/admin/home?not_now=true";
  }
  if (loggedAsPropertyUser) {
    window.location.href = "/copropietario/propiedad?not_now=true";
  } else {
    window.location.href = "/home/index?not_now=true";
  }
  return null;
}
export default HomePage;
