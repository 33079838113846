import { Controller } from "stimulus"

export default class extends Controller {

  initialize(){
    this.initializeMultiSelect();
    const event = new Event('multiselect:connect');
    document.dispatchEvent(event);
  }

  initializeMultiSelect(){
    this.element.querySelectorAll('select.multiselect').forEach((select) => {
      const options = $(select).data('option');
      this.parseOnChangeCode(options);
      $(select).multiselect(options);
      this.styleDropDown();

      // The next code is to dispatch an event when the multiselect is connected, this is if you want to add more than one multiselect in the same page
      const event = new Event(`multiselect:connect:select:${select.id}`);
      document.dispatchEvent(event);

    });
  }

  parseOnChangeCode(options) {
    if (!options['onChange']) return

    const onChangeFunction = eval(options['onChange'])
    options['onChange'] = onChangeFunction
  }

  styleDropDown() {
    $('.multiselect').attr('style', 'width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: left; padding-right: 20px')
    $('.multiselect').find('.caret').addClass('fa fa-chevron-down').removeClass('caret')
  }
}
