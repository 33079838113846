import BaseController from './base_controller'

import {Html5Qrcode} from "html5-qrcode";

export default class extends BaseController {
  static targets = ['scanner', 'errorMessage', 'errorContainer', 'successContainer',
                    'warningContainer', 'warningMessage', 'propertyName', 'invitationName']

  async connect() {
    this.html5QrCode = new Html5Qrcode("reader", /* verbose= */ false);
    this.cameras = await Html5Qrcode.getCameras();
    this.cameraId = this.cameras[0].id
    this.startCamera();
    this.renderOptions(this.cameras);
  }

  renderOptions(cameras) {
    let action = 'click->cf-selector#changeOption';
    let selector = document.getElementById('camera-selector-selector');
    let dropdownMenu = document.getElementById('camera-selector-dropdown-menu');

    for (let i = 0; i < cameras.length; i++) {
      let camera = cameras[i];
      this.appendNewOption(selector, camera.label, camera.id, i == 0)
    }
    document.getElementById('camera-selector-button').querySelector('.selected-value').innerHTML = cameras[0].label;
  }

  async handleChangeCamera(event) {
    this.cameraId = event.target.value;
    await this.html5QrCode.stop();
    this.startCamera();
  }

  async onScanSuccess(decodedText, decodedResult) {
    this.token = decodedText;
    await this.html5QrCode.stop();
    $(this.scannerTarget).collapse("hide");

    $.ajax({
      dataType: 'json',
      url: this.translateRoute('check_invitation_guest_registries'),
      data: { token: decodedText },
      token: decodedText,
      type: 'get',
      success:  this.handleCheckInvitationResponse.bind(this)
    });
  }

  handleCheckInvitationResponse(data) {
    if(data['response'] == 'ok') {
      this.showAcceptInvitation(data);
      this.renderFormGuest(this.token);
    }
    else if(data['response'] == 'soft_error') {
      this.showEligibleInvitation(data);
      this.renderFormGuest(this.token);
    }
    else {
      this.errorMessageTarget.innerHTML = data['message'];
      $(this.errorContainerTarget).collapse('show');
    }
  }

  startCamera() {
    this.html5QrCode.start(
      this.cameraId,
      {
        fps: 10,
        qrbox: { width: 250, height: 250 }
      },
      (decodedText, decodedResult) => {
        this.onScanSuccess(decodedText, decodedResult);
      });
  }

  retry() {
    this.startCamera();

    $(this.scannerTarget).collapse("show");
    $(this.successContainerTarget).collapse("hide");
    $(this.errorContainerTarget).collapse("hide");
    $(this.warningContainerTarget).hide();
  }

  renderFormGuest(token) {
    let form_guest = document.getElementById('form_guest');
    if( form_guest ) { remove_element(form_guest) };

    $.ajax({
      url: this.translateRoute('check_invitation_guest_registries'),
      data: {token:token},
      type: 'GET'
    });
  }

  setSuccessData(data) {
    this.propertyNameTarget.innerHTML = data['property'];
    this.invitationNameTarget.innerHTML = data['name'];
    $(this.successContainerTarget).collapse('show');
  }

  showAcceptInvitation(data) {
    this.setSuccessData(data);

    this.successContainerTarget.addEventListener('DOMNodeInserted', this.showPropertyOwners);
  }

  showPropertyOwners = () => {
    const checker = document.getElementById('send_email');
    const ownerSelector = document.getElementById('owner_selector');
    const markExit = document.getElementById('guest_registry_guest_entries_attributes_0_entry_type_exit');
    const markEntrance = document.getElementById('guest_registry_guest_entries_attributes_0_entry_type_entrance');

    if (markExit) {
      markExit.addEventListener('change', (event) => {
        if(event.currentTarget.checked) {
          checker.checked = false;
          checker.disabled = true;
          ownerSelector.style.display = 'none';
        }
      })
    }

    markEntrance.addEventListener('change', (event) => {
      if(event.currentTarget.checked) {
        checker.disabled = false;
      }
    })
    checker.addEventListener("change", () => {
      if(checker.checked) {
        ownerSelector.style.display = "block";
      }
      else {
        ownerSelector.style.display = "none";
      }
    })
  }

  showEligibleInvitation(data) {
    this.setSuccessData(data);

    this.warningMessageTarget.innerHTML = data['message'];
    $(this.warningContainerTarget).show();

    if(data['error_type'] == 'time') {
      this.successContainerTarget.addEventListener('DOMNodeInserted', () => {
        this.showPropertyOwners();
        if (document.getElementById('guest_registry_estimated_leaving_date')) {
          const leave_time = document.getElementById('guest_registry_estimated_leaving_date');
          const register_time = document.getElementById('guest_registry_registered_at');

          leave_time.style.border = '1px solid red';
          register_time.style.border = '1px solid red';
        }
      })
    }
  }
}
