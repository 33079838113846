import React, { useState } from "react";
import SocialSignupButtons from "../../../components/BaseComponents/SocialSignupButtons";
import StepLayout from "../StepLayout";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import I18n from "i18n-js";
import PublicBtn from "../../../components/PublicBtn";
import { useLazyQuery } from "@apollo/react-hooks";
import { VERIFY_EMAIL_MUTATION } from "../../../api";
import { GLOBAL_STATE, setGlobalState } from "../../../state";
import { isEmailValid } from "../../../utilities/actions";
import WizardTitle from "../WizardTitle";
import MailSignupButton from "../../../components/BaseComponents/mailSignupButton";
import useStyles from "./Steps.styles";

function StepOneContent({ next }) {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [showKnownEmailError, setShowKnownEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { email } = GLOBAL_STATE;
  const [userEmail, setUserEmail] = useState(email);
  const [sendRequest, { data, loading }] = useLazyQuery(VERIFY_EMAIL_MUTATION, {
    onCompleted: (data) => {
      if (data && data.validAuthProvider) {
        if (data.validAuthProvider.validAuthProvider) {
          next();
        } else {
          setShowKnownEmailError(true);
          setShowError(true);
        }
      } else {
        return false;
      }
    },
  });

  function verifyEmail() {
    if (isEmailValid(userEmail)) {
      setGlobalState({
        email: userEmail,
      });
      sendRequest({ variables: { input: { email: userEmail } } });
      setErrorMessage(
        I18n.t("modules.components.email_text_field.known_email")
      );
      setShowError(false);
    } else {
      setShowError(true);
      setShowKnownEmailError(false);
      setErrorMessage("");
    }
  }

  return (
    <Grid container className={classes.formContainer}>
      <WizardTitle number={1} />
      <TextField
        autoComplete="email"
        autoFocus
        className={classes.textfield}
        error={showError}
        fullWidth
        id="email"
        inputProps={{ maxLength: 50 }}
        label={I18n.t("placeholders.user.email")}
        margin="normal"
        helperText={showError ? errorMessage : null}
        name="email"
        variant="outlined"
        defaultValue={email}
        onChange={(event) => {
          setUserEmail(event.target.value);
          setShowError(false);
          setShowKnownEmailError(false);
        }}
      />
      <div className={classes.continue}>
        <PublicBtn
          xs={11}
          md={8}
          disabled={showError || !isEmailValid(userEmail)}
          label={I18n.t("modules.register.next")}
          onClick={verifyEmail}
        />
      </div>
      {showKnownEmailError && <MailSignupButton userEmail={userEmail} />}
      <SocialSignupButtons />
    </Grid>
  );
}

function StepOne({ previous, next }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={1}
      numberStep={1}
      StepContent={StepOneContent}
    />
  );
}

export default StepOne;
