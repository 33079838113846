import Grid from "@material-ui/core/Grid";
import useStyles from "./Login.styles";
import { GLOBAL_STATE } from "../../state";

const LandingIframe = () => {
  const marketingLandingUrl = GLOBAL_STATE.marketingLandingUrl || 'https://www.comunidadfeliz.cl/prueba-de-login';
  const classes = useStyles();
  const html = `<iframe src=${marketingLandingUrl} class=${classes.iframe} />`;

  return (
    <Grid
      className={classes.iframe_container}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default LandingIframe;
