import { Controller } from 'stimulus';

export default class extends Controller {
  showFileName(event) {
    let file_name = event.target.files[0].name

    $('.photo-container').css('display', 'initial')
    $('.file-name').html(file_name)
  }

  showFileNameModal(event) {
    let file = event.target.files[0]
    let file_name = file.name
    let local_image_url = URL.createObjectURL(file);
    $('.photo-container-modal').css('display', 'initial')
    $('.file-name-modal').html(file_name)
    
    let image_span = document.querySelector('.file-name-modal')
    image_span.setAttribute('data-local-url', local_image_url);
  }

  removeFile() {
    $('.photo-container').css('display', 'none')
    $('.file-name').html('')
    document.getElementById('package_image').value = ''
    $('#package-container-image').val('')
  }

  removeFileModal() {
    $('.photo-container-modal').css('display', 'none')
    $('.file-name-modal').html('')
    $('#remove_image').val(true)

    document.getElementById('package_image').value = ''
    $('#package-container-image').val('')
  }

  checkSubmitButton() {
    let comment = $('#package_comment').val()?.trim();
    let property = $('#package_property_id').val()?.trim();
    let receiver = $('#package_receiver').val()?.trim();

    $('#submit-package-form-btn').attr('disabled', !(comment != '' && property != '' && (receiver != '' && receiver != window.other_employee_value)))
  }

  openPackageImage(event) {
    let redirect_url = event.target.dataset.localUrl;
    if (redirect_url === undefined) {
      redirect_url = event.target.dataset.url;
    }
    window.open(redirect_url, '_blank');
  }
}
