import AutoNumeric from "autonumeric"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item", "items", "item_template", "add", "destroy"];

  add(event) {
    const newItem = this.item_templateTarget.content.cloneNode(true);
    this.itemsTarget.appendChild(newItem);

    $("[data-toggle='tooltip']").tooltip();
    $.reloadAllAutonumeric();
  }

  destroy(event) {
    const index = this.destroyTargets.findIndex(t => t === event.target);
    const itemElement = this.itemTargets[index];
    itemElement.remove();
  }
}
