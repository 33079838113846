import React from "react";
import { LoginForm } from "../features/Login/index";
import MainContainer from '../components/layout/MainContainer';

function LoginPage(props) {
  return (
    <MainContainer>
      <LoginForm {...props} />
    </MainContainer>
  );
}

export default (LoginPage);
