import { Controller } from 'stimulus';
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ['originKastor', 'importer', 'importerOrigin', 'tenantSession', 'url']

  update_value(event) {
    const importer = event.currentTarget.value
    this.importerOriginTarget.value = importer
    switch (importer) {
      case 'Kastor':
        this.originKastorTarget.removeAttribute('style')
        this.originKastorTarget.classList.remove('wide')
        this.urlTarget.innerHTML = I18n.t('helpers.label.importer.kastor_subdomain')
        this.tenantSessionTarget.style.display = 'none'
        break
      case 'Edipro':
        this.originKastorTarget.classList.add('wide')
        this.originKastorTarget.removeAttribute('style')
        this.tenantSessionTarget.removeAttribute('style')
        this.urlTarget.innerHTML = I18n.t('helpers.label.importer.edipro_subdomain')
        break
      default:
        this.originKastorTarget.style.display = 'none'
        this.tenantSessionTarget.style.display = 'none'
    }
  }
}
