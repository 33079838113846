import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "initial_show_target", "hide_target" ]

  call(event){
    if (event.currentTarget.dataset.initialToggle === 'true'){
      this.show(this.initial_show_targetTargets);
      this.hide(this.hide_targetTargets);
    } else {
      this.show(this.hide_targetTargets);
      this.hide(this.initial_show_targetTargets);
    }
  }

  show(targets){
    targets.forEach(target => {
      target.classList.remove('collapse')
    });
  }

  hide(targets){
    targets.forEach(target => {
      target.classList.add('collapse')
    });
  }
}
