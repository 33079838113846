import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'fineTitle', 'form']

  openModal(event) {
    let currentTarget = event.currentTarget
    $(this.modalTarget).modal()

    $('#template_name').html(currentTarget.dataset.fineTitle)
    this.formTarget.setAttribute('action', currentTarget.dataset.actionUrl)
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }
}
