import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  public_btn: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.public.public_btn_color,
    width: "100%",
    fontStyle: "normal",
    paddingTop: 10,
    fontStretch: "normal",
    letterSpacing: "normal",
    background: theme.palette.public.public_btn_bg,
    borderRadius: "5px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.public.public_btn_bg,
      boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.40)",
    },
    "&:disabled": {
      color: theme.palette.public.public_btn_disabled,
      opacity: 0.4,
    },
  },
}));

function PublicBtn(props) {
    const classes = useStyles();
    const {
        label,
        disabled
    } = props;
    return (
      <Button className={classes.public_btn} disabled={disabled} {...props}>
        {label}
      </Button>
    );
}

PublicBtn.propTypes = {
    label: PropTypes.string.isRequired,
};

export default PublicBtn;
