import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ['checkbox', 'details']

  toggleDates() {
    console.log(this.checkboxTarget.value)
    if (this.checkboxTarget.value === 'true') {
      this.detailsTarget.classList.remove("hidden");
      this.checkboxTarget.value = 'true';
    } else {
      this.blankDates();
      this.blankUrl(window.location.href);
      this.checkboxTarget.value = 'false';
      this.detailsTarget.classList.add("hidden")
    }
  }

  hiddenDates() {
    document.querySelector('input#start_date').value = '';
    document.querySelector('input#end_date').value = '';
  }

  blankUrl(initUrl) {
    let url = new URL(initUrl);
    let params = new URLSearchParams(url.search);
    params.delete('end_date');
    params.delete('start_date');
    params.delete('filter_by_date');
    url.search = params.toString()
    history.pushState({}, '', url.toString());
  }

  blankDates() {
    this.hiddenDates()
    const selectors = document.querySelectorAll('.date-selector');
  
    selectors.forEach((selector) => {
      const formValue = selector.querySelector("input[type='hidden']");
      const selectorName = selector.querySelector("input[type='text']");
      const changeEvent = new Event('change');
      const optional = selector.querySelectorAll("input[type='hidden']")[1]?.value;
      const date = '';
  
      if (optional === 'true' && date === '') {
        selectorName.value = date;
        formValue.value = date;
      } else {
        selectorName.value = this.formatDate(date);
        formValue.value = date;
      }
      formValue.dispatchEvent(changeEvent);
    });
  }  
}
