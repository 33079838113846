import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "textField" ]
  static values = { options: Object }

  call(event){
    const options = this.optionsValue;
    const text = this.textFieldTarget.value;

    this.textFieldTarget.value = this.formatText(text, options);
  }

  private

  alphanumeric(text) {
    return text.replace(/[^A-Za-z0-9]/gmi, '');
  }

  formatText(text, options) {
    if(options['uppercase']) text = this.upcase(text);
    if(options['unaccent']) text = this.unaccent(text);
    if(options['alphanumeric']) text = this.alphanumeric(text);

    return text;
  }

  unaccent(text) {
    return text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  }

  upcase(text) {
    return text.toUpperCase();
  }
}
