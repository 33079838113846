import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    this.validateChekbox();
    this.initializeDate();
    this.addWarning();
  }

  initializeDate(event){
    let conference_errors = document.getElementById('errors').value
    let post_record = document.getElementById('post_record').value

    if(post_record == 'true' && conference_errors == ''){
      document.getElementById('conference_event_start-selector-input').value = ''
      document.getElementById('conference_event_end-selector-input').value = ''
    }

  }

  validateChekbox(event){
    let conference_check = document.getElementById('post_add_conference')

    conference_event_start.disabled = conference_check?.checked ? false : true
  }

  addWarning(event){
    let conference_errors = document.getElementById('errors').value
    let event_start_selector = document.getElementById('conference_event_start-selector-input')
    let event_end_selector = document.getElementById('conference_event_end-selector-input')

    if(conference_errors == 'event_start'){
      event_start_selector.classList.add('invalid_input')
    }else if(conference_errors == 'event_end'){
      event_end_selector.classList.add('invalid_input')
    }
  }

  changeDate(event){
    let event_start_selector = document.getElementById('conference_event_start-selector-input')
    let event_end_selector = document.getElementById('conference_event_end-selector-input')
    let input_end = document.getElementById('conference_event_end')
    let input_start = document.getElementById('conference_event_start')

    if(input_start && input_start.value != '' && input_end && event_start_selector && event_end_selector){
      // change in iso format
      let date = new Date(input_start.value);
      date.setHours(date.getHours() + 1);
      let newDateValue = date.toISOString().slice(0, 16);
      input_end.value = newDateValue;

      // change hour in input
      let format_date = event_start_selector.value.split('- ')
      let [hours, minutes] = format_date[1].split(':').map(Number);
      let newHours = (hours + 1) % 24;
      let formattedTime = `${newHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      let dates = format_date[0] + '- ' + formattedTime
      event_end_selector.value = dates
    }
  }
}
