import gql from "graphql-tag";

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ($userInput: UserPasswordInput!) {
    updateUserPassword(input: $userInput) {
      message
    }
  }
`;
export default CHANGE_PASSWORD_MUTATION;
