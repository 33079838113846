import I18n from "i18n-js";
import moment from "moment";
moment.locale("es");


export const isPaymentOutDateRange = (date) => {
    var  current_time = new Date()

    // pago en el futuro
    if (moment(date).isAfter(current_time, 'day')){
        return {
            isOutDateRange: true,
            message: I18n.t('messages.warnings.payments.date_in_future')
        }
    }

    // pago un mes en el pasado
    var  one_month_before = new Date()
    one_month_before.setMonth(one_month_before.getMonth() - 1)

    if (moment(one_month_before).isSame(date, 'day')){
        return {
            isOutDateRange: true,
            message: I18n.t('messages.warnings.payments.date_one_month_in_past')
        }
    }

    return {
        isOutDateRange: false,
        message: ""
    }
};



