import React from 'react'
import { Global } from '@emotion/react'
import { ApolloProvider } from '@apollo/react-hooks'

import client from '../utilities/apolloClient/apolloClient'
import App from './App'
import PublicApp from './PublicApp'
import globalStyles from '../utilities/globalStyles'

type Props = {
  token: string,
  communityId: number,
};

const Index: React.FC<Props> = (props) => {
  return (
    <ApolloProvider client={client}>
      <Global styles={globalStyles.global} />
      {props.token ? <App data={props} /> : <PublicApp data={props} />}
    </ApolloProvider>
  );
}

export default Index
