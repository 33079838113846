import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "warningMessage"]

  connect() {
    this.submitButton = document.getElementById('form_submit_button')
    this.validateInput()
    this.inputTarget.addEventListener("input", this.validateInput.bind(this))
  }

  validateInput() {
    const input = this.inputTarget.value.trim()
    const words = input.split(/\s+/).filter(word => word.length > 0)
    const isValid = words.length >= 1 && words.length <= 50 && input.length >= 10

    this.submitButton.disabled = !isValid

    this.warningMessageTarget.classList.toggle('hidden', isValid)
  }

  onSubmit() {
    this.submitButton.disabled = true
  }
}
