import React, { useEffect, useState } from "react";
import StepLayout from "../StepLayout";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from "@material-ui/core";
import I18n from "i18n-js";
import { useMutation } from "@apollo/react-hooks";
import useStyles from "./Steps.styles";
import { GLOBAL_STATE, setGlobalState } from "../../../state";
import { CREATE_PROPERTY_USER_REQUEST, CREATE_USER_MUTATION } from "../../../api";
import PasswordField from "../../../components/PasswordField";
import PublicBtn from "../../../components/PublicBtn";
import WizardTitle from "../WizardTitle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { sendLoginRequest } from "../../Login/LoginForm";

const PRIVACY_NOTICE_URL = {
  CHILE: "https://www.comunidadfeliz.cl/informacion/aviso-de-privacidad",
  MEXICO: "https://www.comunidadfeliz.mx/avisodeprivacidad",
}

export const PASSWORD_CRITERIA = [
  { type: "length", regex: /.{8,}/ },
  { type: "uppercase", regex: /[A-Z]/ },
  { type: "lowercase", regex: /[a-z]/ },
  { type: "number", regex: /\d/ },
  { type: "special", regex: /[!@#$%^&*()_+=\[\]{};:<>|./?,-]/ },
];

export const PasswordCriterion = ({ type, regex, password }) => {
  const classes = useStyles();

  const isValid = regex.test(password);

  return (
    <p className={classes.passwordCriteria}>
      {!password ? (
        <RemoveCircleIcon className={classes.passwordCriteriaIcon} />
      ) : isValid ? (
        <CheckCircleIcon className={classes.passwordCriteriaValidIcon} />
      ) : (
        <CancelIcon className={classes.passwordCriteriaInvalidIcon} />
      )}
      <span>{I18n.t(`modules.register.password_criteria.${type}`)}</span>
    </p>
  );
}

function StepFiveContent({ backdrop }) {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const {
    code = null,
    email,
    firstName,
    lastName,
    country_code,
    communityId,
    propertyId,
    res2_redirection
  } = GLOBAL_STATE;
  const [disableButton, setDisableButton] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    I18n.t("modules.new_password.mismatch_data")
  );
  const [acceptedTos, setAcceptedTos] = useState(true);
  const [urlToResidents, setUrlToResidents] = useState("");
  const [residentsToken, setResidentsToken] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [sendCreateUser, { data, loading, error }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        if (data && data.createUser) {
          setGlobalState({
            userToken: data.createUser.token,
          });
          sendPropertyRequest({
            variables: {
                communityId: communityId,
                propertyId: propertyId,
                code: code,
            }
          });
        } else {
          setShowError(true);
          backdrop.current.deactivate();
        }
      },
      onError(errors) {
        let errorMessages = "";
        errors.graphQLErrors?.forEach((error) => {
          errorMessages += error.message;
        });
        setErrorMessage(errorMessages);
        backdrop.current.deactivate();
        showError ? null : setShowError(true);
      },
    }
  );

  const [
    sendPropertyRequest,
    { dataQRPropertyRequest, loadingQRPropertyRequest, errorQRPropertyRequest },
  ] = useMutation(CREATE_PROPERTY_USER_REQUEST, {
    onCompleted: (data) => {
      if (data && data.createPropertyUserRequest) {
        login();
      } else {
        setShowError(true);
      }
    },
    onError(errors) {
      let errorMessages = "";
      errors.graphQLErrors?.forEach((error) => {
        errorMessages += error.message;
      });
      setErrorMessage(errorMessages);
      backdrop.current.deactivate();
      showError ? null : setShowError(true);
    },
  });

  function login() {
    const sessionUrl = "/sessions.json";
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const data = { email, password: newPassword, csrfToken };
    const onError = () => {
      backdrop.current.deactivate();
      setShowError(true);
    }
    const onLoad = (response) => {
      const path = response.url.split("?")[0];
      const token = response.url.split("=")[1];

      setUrlToResidents(path);
      setResidentsToken(token);
      if (token) {
        setShouldRedirect(true);
      } else {
        if (response.url !== "/") {
          location.href = response.url;
        } else {
          onError();
          setErrorMessage(response.message);
        }
      }
    };

    sendLoginRequest(sessionUrl, data, onLoad, onError);
  }

  function createUser() {
    backdrop.current.activate();
    if (!acceptedTos) return;

    const variables = {
      acceptedTos: true,
      authProvider: {
        email: { email: email, password: newPassword },
      },
      country: country_code,
      communityId: communityId,
      firstName: firstName,
      lastName: lastName,
    };

    sendCreateUser({ variables });
  }

  useEffect(() => {
    const validConfirmation =
      !newPassword.length ||
      !passwordConfirmation.length ||
      newPassword === passwordConfirmation;
    setDisableButton(!validConfirmation);
    setShowError(!validConfirmation);
  }, [newPassword, passwordConfirmation]);

  useEffect(() => {
    if (shouldRedirect) {
      document.getElementById("redirect").submit();
    }
  }, [shouldRedirect]);

  return (
    <Grid>
      <form name="redirect" id="redirect" method="post" action={urlToResidents} style={{"display": "none"}}>
          <TextField
            name="code"
            value={residentsToken}
          ></TextField>
        </form>
      <Grid>
        <WizardTitle number={4} />
        <PasswordField
          onChange={({ target }) => setNewPassword(target.value)}
          autoFocus
          label={I18n.t("modules.register.add_password")}
        />
      </Grid>
      <Grid>
        <PasswordField
          onChange={({ target }) => setPasswordConfirmation(target.value)}
          label={I18n.t("modules.register.password_field_placeholder")}
          error={showError}
          helperText={showError ? errorMessage : ""}
        />
      </Grid>
      <Grid>
        <p className={classes.passwordCriteria}>
          {I18n.t("modules.register.password_criteria.title")}
        </p>
        {PASSWORD_CRITERIA.map((criterion) => (
          <PasswordCriterion key={criterion.name} password={newPassword} {...criterion} />
        ))}
      </Grid>
      <Grid className={classes.checkboxContainer}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              label={
                <p
                  dangerouslySetInnerHTML={{
                    __html: I18n.t("modules.register.privacy_notice", {
                      url: PRIVACY_NOTICE_URL[country_code],
                    }),
                  }}
                />
              }
              control={
                <Checkbox
                  name="privacy"
                  checked={acceptedTos}
                  classes={{ root: classes.radio, checked: classes.checked }}
                  onChange={(e) => setAcceptedTos(e.target.checked)}
                />
              }
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid>
        <PublicBtn
          disabled={
            disableButton ||
            !acceptedTos ||
            newPassword !== passwordConfirmation ||
            PASSWORD_CRITERIA.some(({ regex }) => !regex.test(newPassword))
          }
          label={I18n.t("modules.register.finalize")}
          onClick={createUser}
        />
      </Grid>
    </Grid>
  );
}

function StepFive({ previous, next }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={4}
      numberStep={4}
      StepContent={StepFiveContent}
    ></StepLayout>
  );
}

export default StepFive;