import gql from "graphql-tag";

const UPDATE_USER_PASSWORD_RECOVERY_MUTATION = gql`
  mutation ($userInput: UserPasswordRecoveryInput!) {
    updateUserPasswordRecovery(input: $userInput) {
      message
      success
    }
  }
`;
export default UPDATE_USER_PASSWORD_RECOVERY_MUTATION;
