import BaseController from './base_controller'
import I18n from 'i18n-js'

export default class extends BaseController {
  static targets = ['dropdown', 'buttonText', 'formInput', 'option', 'selectorWithOptions', 'selectAll', 'component']

  // Single choice

  changeOption(event) {
    const newSelectedOption = event.currentTarget
    this.changeName(newSelectedOption)
    this.switchOption(newSelectedOption)
    this.changeValue(newSelectedOption.dataset.value)
  }

  changeName(option) {
    this.buttonTextTarget.innerText = option.dataset.name;
  }

  switchOption(option) {
    const oldSelectedOption = this.dropdownTarget.querySelector('.cf-selector-option.selected')
    if (oldSelectedOption) oldSelectedOption.classList.remove('selected')
    option.classList.add('selected')
  }

  changeValue(value) {
    this.formInputTarget.value = value;
    this.formInputTarget.dispatchEvent(new Event('change'))
  }

  // Multiple choice

  toggleOption(event) {
    event.stopPropagation()
    const newSelectedOption = event.currentTarget
    newSelectedOption.classList.toggle('selected')
    this.toggleSelection(newSelectedOption.dataset.value)
    this.checkIfAllSelected()
    this.updateName()
  }

  checkIfAllSelected() {
    const options = this.optionTargets
    const allSelected = options.every(option => option.classList.contains('selected'))
    this.selectAllTarget.classList.toggle('selected', allSelected)
  }

  forceOption(option, selected) {
    option.classList.toggle('selected', selected)
    this.forceSelection(option.dataset.value, selected)
    this.updateName()
  }

  forceSelection(value, selected) {
    let options = this.selectorWithOptionsTarget.options
    let optionReferenced = Array.from(options).find(option => option.value == value)
    optionReferenced.selected = selected
  }

  toggleSelection(value) {
    let options = this.selectorWithOptionsTarget.options
    let optionReferenced = Array.from(options).find(option => option.value == value)
    optionReferenced.selected = !optionReferenced.selected
  }

  forceAll(event) {
    event.stopPropagation()
    const allOptions = event.currentTarget
    const unselectAll = allOptions.classList.contains('selected')
    this.optionTargets.forEach(option => this.forceOption(option, !unselectAll))
    this.selectAllTarget.classList.toggle('selected')
  }

  updateName() {
    const selected = this.optionTargets.filter(option => option.classList.contains('selected'))
    const selectedCount = selected.length
    const object = this.dropdownTarget.dataset.object
    const optionsCount = this.optionTargets.length
    if (selectedCount > 0) {
      if (optionsCount == selectedCount) {
        this.buttonTextTarget.innerText = I18n.t(`components.cf_selector.${object}.all_selected`)
      } else if (selectedCount > this.componentTarget.dataset.maxNameOptions) {
        this.buttonTextTarget.innerText = `${selectedCount} ${I18n.t(`components.cf_selector.${object}.selected`)}`
      } else {
        this.buttonTextTarget.innerText = selected.map(option => option.dataset.name).join(', ')
      }
    } else {
      this.buttonTextTarget.innerText = I18n.t(`components.cf_selector.${object}.none_selected`)
    }
  }

  // Filter

  filterOption(string, option) {
    let name = option.dataset.name
    let group = option.dataset.group
    let hide = true
    if (group) {
      hide = name.toLowerCase().includes(string) || group.toLowerCase().includes(string)
    } else {
      hide = name.toLowerCase().includes(string)
    }
    option.classList.toggle('hidden', !hide)
  }

  filterHeader(string, header) {
    let name = header.getAttribute('data-names')
    let value = header.getAttribute('data-value')
    let hide = name.toLowerCase().includes(string) || value.toLowerCase().includes(string)
    header.classList.toggle('hidden', !hide)
  }

  filterOptions(event) {
    const filterValue = event.currentTarget.value.toLowerCase()
    const dropdown = this.dropdownTarget
    const dropdownOptions = this.optionTargets
    const dropdownHeaders = dropdown.querySelectorAll('.dropdown-header')

    dropdownOptions.forEach(option => this.filterOption(filterValue, option))
    dropdownHeaders.forEach(header => this.filterHeader(filterValue, header))
  }
}
