import BaseController from './base_controller';
import I18n from "i18n-js";

const COMMON_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+=\[\]{};:<>|./?,-])/;
const EMAIL_SUBSTRING = "{email_3}";

export default class extends BaseController {
  static targets = ["passwordInput", "message", "submit"];

  initialize() {
    this.initialMessage = this.messageTarget.textContent;
  }

  getPasswordComplexityPattern(hasEmailSubstring) {
    const lengthPattern = hasEmailSubstring ? /.{5,}$/ : /.{8,}$/;
    return new RegExp(COMMON_PATTERN.source + lengthPattern.source);
  }

  verifyPasswordComplexity() {
    const password = this.passwordInputTarget.value;
    const hasEmailSubstring = password.includes(EMAIL_SUBSTRING);
    const complexityPattern = this.getPasswordComplexityPattern(hasEmailSubstring);

    if (complexityPattern.test(password.replace(EMAIL_SUBSTRING, ""))) {
      this.messageTarget.textContent = this.initialMessage;
      this.submitTarget.disabled = false;
    } else {
      this.messageTarget.textContent = I18n.t(
        "activerecord.errors.models.user.attributes.password.password_complexity"
      );
      this.submitTarget.disabled = true;
    }
  }
}
