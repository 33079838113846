import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sidebar', 'navlinkDropdown', 'navlinkSidebar', 'activeNav']

  connect() {
    if (this.hasActiveNavTarget) this.activeNavTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  mouseEnter() {
    this.sidebarTarget.classList.add('active')
    if (this.hasActiveNavTarget) {
      if (this.activeNavTarget.dataset.controller) {
        let dropdown = this.activeNavTarget.querySelector('.navlink-dropdown')
        dropdown.dispatchEvent(new Event('click'))
      }
    }
  }

  mouseLeave() {
    this.sidebarTarget.classList.remove('active')
    this.navlinkDropdownTargets.forEach(navlink => this.toggleCollapse(navlink))
  }

  toggleCollapse(navlink) {
    let expanded = navlink.classList.contains('expanded')
    if (expanded) navlink.dispatchEvent(new Event('click'))
  }
}
