import { Controller } from 'stimulus';

export default class extends Controller {
  async close(event) {
    event.preventDefault();

    const community_id = event.target.dataset.value;

    await fetch(`/comunidades/${community_id}/hide_unique_relations_banner`, {
      method: 'POST',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    }).then(response => {})
  }
}
