import { Controller } from "stimulus";

const formIdentifier = '#update-labels-form';
const inputCheckboxIdentifier = 'input[type="checkbox"]';
const modalIdentifier = '#update-labels';
const submitButtonIdentifier = '#update-labels-button';

export default class extends Controller {
  // Auxiliary functions

  checkboxes() {
    const form = document.querySelector(formIdentifier)
    return Array.from(form.querySelectorAll(inputCheckboxIdentifier))
  }

  getPencilButtonDataset() {
    const submitButtonDataset = document.querySelector(submitButtonIdentifier).dataset
    const pencilButtonDataset = document.getElementById(submitButtonDataset.pencilButtonId).dataset

    return pencilButtonDataset
  }

  handleResponse(data) {
    if (data.success) {
      this.updateModalInitialInformation(data)
      this.refreshLabelsColumnsText(data)
      triggerNotice(data.message)
      allow_multiple_notices()
    } else {
      triggerAlert(data.message)
      allow_multiple_alerts()
    }
  }

  labelsModal(show) {
    show ? $(modalIdentifier).modal() : $(modalIdentifier).modal('hide')
  }

  refreshLabelsColumnsText(controllerResponseData) {
    const newLabelsColumnContent = controllerResponseData.labelsText
    const newLabelsColumnResponsiveContent = controllerResponseData.labelsTextResponsive

    const labelsColumn = document.getElementById(`property_user_${controllerResponseData.propertyUserId}_labels_td`)
    const labelsColumnResponsive = document.getElementById(`property_user_${controllerResponseData.propertyUserId}_labels_td_responsive`)
    if (labelsColumn) labelsColumn.textContent = newLabelsColumnContent
    if (labelsColumnResponsive) labelsColumnResponsive.textContent = newLabelsColumnResponsiveContent
  }

  sendRequest(url) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    return fetch(url, {
      method: 'PUT',
      headers: headers
    })
    .then(response => response.json())
  }

  updateLabels(checkedLabels = []) {
    let urlWithQueryParams = this.getPencilButtonDataset().url
    if (checkedLabels.length > 0) urlWithQueryParams += `?labels=${checkedLabels.join(',')}`
    this.sendRequest(urlWithQueryParams).then(data => this.handleResponse(data))
  }

  updateModalInitialInformation(controllerResponseData) {
    const pencilButtonDataset = this.getPencilButtonDataset()
    pencilButtonDataset.labelsArray = JSON.stringify(controllerResponseData.updatedLabels)
  }

  verifyValidSelection() {
    const checkboxes = this.checkboxes()
    const submitButtonClassList = document.querySelector(submitButtonIdentifier).classList
    const validSelection = checkboxes.some((checkbox) => checkbox.checked)
    validSelection ? submitButtonClassList.remove('disabled') : submitButtonClassList.add('disabled')
  }

  // Actions called from views

  submit(_event) {
    this.labelsModal(false)
    const checkboxes = this.checkboxes()
    const checkedLabels = checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
    this.updateLabels(checkedLabels)
  }

  toggleLabelCheckbox(event) {
    event.currentTarget.toggleAttribute('checked')
    this.verifyValidSelection()
  }

  triggerModal(event) {
    const pencilButton = event.currentTarget
    const submitButtonDataset = document.querySelector(submitButtonIdentifier).dataset
    submitButtonDataset.pencilButtonId = pencilButton.id
    const labelsArray = JSON.parse(pencilButton.dataset.labelsArray).map(label => parseInt(label))
    this.checkboxes().forEach(checkbox => checkbox.checked = labelsArray.includes(parseInt(checkbox.value)))
    this.verifyValidSelection()
    this.labelsModal(true)
  }
}
