import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect(){
    this.initMultiSelect()
  }

  defaultOptions() {
    let options = {
      buttonWidth: '100%',
      disableIfEmpty: true,
      dropRight: false,
      enableCaseInsensitiveFiltering: true,
      filterBehavior: 'text',
      filterPlaceholder: 'Buscar...',
      maxHeight: 300
    }

    if (this.element.dataset.useonselectall) {
      options['onChange'] = this.onSelectAllOption
    }

    return options
  }

  globalOptions() {
    return Object.assign(this.defaultOptions(), JSON.parse(this.element.dataset.options))
  }

  initMultiSelect() {
    let options = this.defaultOptions()
    if (this.element.dataset.options) {
      options = this.globalOptions()
    }

    this.inputTargets.forEach((target) => {
      let targetOptions = Object.assign(options, target.dataset.options)
      $(target).attr('style', 'display: none;')
      $(target).multiselect(targetOptions)
    })
    this.styleDropDown(options)
  }

  styleDropDown(options) {
    if (options.containerStyle) {
      $('.multiselect-container.dropdown-menu').attr('style', options.containerStyle)
    }
    $('.multiselect').attr('style', 'width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: left')
    $('.multiselect').find('.caret').addClass('fa fa-chevron-down').removeClass('caret')
  }

  resetMultiSelect() {
    document.querySelectorAll('.multiselect-native-select').forEach((selector) => {
      let select = selector.querySelector('select')
      selector.parentElement.innerHTML = select.outerHTML
    })
  }

  onSelectAllOption(option, checked) {
    if (option[0].value != 0 && checked) {
      $(option.parent()).multiselect('deselect', ['0']);
    } else if (checked) {
      $(option.parent()).multiselect('deselectAll');
      $(option.parent()).multiselect('select', ['0']);
    }
  }
}
