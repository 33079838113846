import React from "react";
import MainContainer from "../components/layout/MainContainer";
import { LoginForm, LandingIframe } from "../features/Login";

const LandingPage = (props) => {
  return (
    <MainContainer>
      <LoginForm {...props} />
      <LandingIframe />
    </MainContainer>
  );
};

export default LandingPage;
