import { Controller } from "stimulus"

// When a inline script load is necessary Ex: auto-numeric (didn't found other examples)
// **any** edit action should be disabled (or hide, depending of the type of the action) manually
// by adding a disabled attribute for actions triggered via an inputs/submits buttons or by
// adding a hidden class otherwise (for links or generic divs) to avoid data inconsistencies
// that could be stored while the inline script is loading

// See the partial views/admin/invoices/_form.html.haml for an example of how this controller is used

export default class extends Controller {
  static targets = ['disabledAction'];

  connect() {
    this.enableActions()
  }

  enableActions() {
    this.disabledActionTargets.forEach(disabledAction => this.enableFunctions(disabledAction));
  }

  enableFunctions(disabledAction) {
    return {
      'A': disabledAction.classList.remove('hidden'),
      'DIV': disabledAction.classList.remove('hidden'),
      'BUTTON': disabledAction.removeAttribute('disabled'),
      'INPUT': disabledAction.removeAttribute('disabled')
    }[disabledAction.tagName]
  }
}
