import I18n from "i18n-js";

function addLocale() {
  if (window.location.href.includes('happyhoa')) {
    // this line is for usa production
    I18n.locale = 'en';
  }
}

export default addLocale;
