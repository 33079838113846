import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "downButton", "upButton"]
  static values = { class: String }

  toggle(event) {
    const targetDetails = document.getElementById(event.currentTarget.dataset.target)
    targetDetails.classList.toggle(this.classValue || "hidden")
    this.toggleButtons(targetDetails.id)
  }

  toggleButtons(selectedTargetId) {
    this.downButtonTargets.forEach(button => {
      if (button.dataset.target === selectedTargetId) {
        button.classList.toggle("hidden")
      }
    })
    this.upButtonTargets.forEach(button => {
      if (button.dataset.target === selectedTargetId) {
        button.classList.toggle("hidden")
      }
    })
  }
}
