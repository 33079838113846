import { Controller } from "stimulus";
import I18n from "i18n-js";

const bulkLabelsModalTextIdentifier = '#bulk-property-user-text';

export default class extends Controller {
  static targets = [ 'checkbox', 'defaultButton', 'editButton', 'someSelectedButton', 'counter' ];

  static values = {
    communityId: String,
    allIds: {
      type: Array,
      default: []
    }
  }

  connect() {
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
    this.initializeFromStorage(this.checkedItemsStorage('get'))
  }

  handleBeforeUnload() {
    const controllerPath = '/access_control'
    const targetUrl = document.activeElement.href || ''

    // We reset the local storage if the user is leaving the controller
    if (!targetUrl.includes(controllerPath)) {
      this.checkedItemsStorage('reset')
    }
  }

  initializeFromStorage(checked_items) {
    if (checked_items.length === 0) return

    const allPropertyUsersCheckbox = document.getElementById('all-property-users')

    if (checked_items.length === this.allIdsValue.length) allPropertyUsersCheckbox.checked = true
    this.checkboxTargets.forEach((checkbox) => {
      if (checked_items.includes(checkbox.dataset.id)) checkbox.checked = true
    })
    this.updateShownButtons()
    this.updateCounter()
   }

  toggleSelectAllCheckboxes(event) {
    if (event.currentTarget.checked) {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = true
      })
      this.checkedItemsStorage('set', this.allIdsValue)
    } else {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = false
      })
      this.checkedItemsStorage('reset')
    }

    this.updateShownButtons()

    if (this.counterTarget) this.updateCounter()
  }

  updateShownButtons() {
    if (Array.from(this.checkboxTargets).some((checkbox) => checkbox.checked)) {
      this.defaultButtonTarget.classList.add('hidden')
      this.editButtonTarget.setAttribute('disabled', 'true')
      this.someSelectedButtonTargets.forEach(button => button.classList.remove('hidden'))
    } else {
      this.defaultButtonTarget.classList.remove('hidden')
      this.editButtonTarget.removeAttribute('disabled')
      this.someSelectedButtonTargets.forEach(button => button.classList.add('hidden'))
    }
  }

  updateCounter() {
    const checked_count = this.checkedItemsStorage('get').length
    const bulkLabelsModalText = document.querySelector(bulkLabelsModalTextIdentifier)

    if (checked_count == 1) {
      this.counterTarget.classList.remove('hidden')
      this.counterTarget.innerText = I18n.t('views.access_control.row_selected')
      bulkLabelsModalText.innerText = I18n.t('views.access_control.bulk_enable_single')
    } else if (checked_count > 1) {
      this.counterTarget.classList.remove('hidden')
      this.counterTarget.innerText = I18n.t('views.access_control.rows_selected', {count: checked_count})
      bulkLabelsModalText.innerText = I18n.t('views.access_control.bulk_enable', {count: checked_count})
    } else {
      this.counterTarget.classList.add('hidden')
      bulkLabelsModalText.innerText = I18n.t('views.access_control.activate_community_labels')
    }
  }

  saveInLocalStorage(event) {
    const currentCheckbox = event.currentTarget

    let checked_items = this.checkedItemsStorage('get')
    const itemIsStored = checked_items.includes(currentCheckbox.dataset.id)

    if (currentCheckbox.checked && !itemIsStored) {
      checked_items.push(currentCheckbox.dataset.id)
    } else if (!currentCheckbox.checked && itemIsStored) {
      checked_items = checked_items.filter(item => item !== currentCheckbox.dataset.id)
    }

    this.checkedItemsStorage('set', checked_items)
  }

  submitIds(event) {
    event.preventDefault()
    const ids_hidden_field = event.currentTarget.elements['ids']
    const ids = this.checkedItemsStorage('get')

    ids_hidden_field.value = ids
    this.checkedItemsStorage('reset')
    event.currentTarget.submit()
  }

  checkedItemsStorage(action = 'get', new_value = null) {
    const localStorageKey = `access_control_index_checked_items_${this.communityIdValue}`

    if (action === 'set') return localStorage.setItem(localStorageKey, JSON.stringify(new_value))
    if (action === 'reset') return localStorage.setItem(localStorageKey, '[]')
    
    return JSON.parse(localStorage.getItem(localStorageKey) || '[]')
  }
}
