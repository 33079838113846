// Based on ClipboardController: https://github.com/stimulus-components/stimulus-clipboard

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  connect() {
    if (!this.hasButtonTarget) return;

    this.originalContent = this.buttonTarget.innerHTML;
  }

  copy(event) {
    event.preventDefault();

    const actualInputType = this.sourceTarget.type
    if (actualInputType === 'hidden') this.sourceTarget.type = 'text'

    this.sourceTarget.select();
    document.execCommand('copy');

    if (actualInputType === 'hidden') this.sourceTarget.type = 'hidden'

    this.copied();
  }

  copied() {
    if (!this.hasButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.buttonTarget.innerHTML = this.data.get('successContent');

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
    }, this.successDurationValue);
  }
}
