import React from "react";
import { Wizard, Step, match } from "react-redux-wizard";

import {
  PropertySignUpStep,
  ManualPropertySignupStep,
  QrPropertySignupStep,
  StepSessionOne,
} from "./steps";

const RegisterWithToken = () => {
  return (
    <div className="RegisterPartOwnerPage">
      <Wizard name="RegisterPartOwnerFormWizard">
        <Step name="step-one" component={StepSessionOne} next="step-three" />
        <Step
          name="step-three"
          component={PropertySignUpStep}
          next={match("/choice", {
            "step-qr-property-signup": "qr",
            "step-manual-property-signup": "manual",
          })}
        />
        <Step
          name="step-manual-property-signup"
          component={ManualPropertySignupStep}
          next={() => "step-five"}
        />
        <Step
          name="step-qr-property-signup"
          component={QrPropertySignupStep}
          next={() => "step-five"}
        />
      </Wizard>
    </div>
  );
};

export default RegisterWithToken;
