import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['spinner']

  downloadResultsPdf(e) {
    const button = e.currentTarget;
    e.preventDefault();
    this.spinnerTarget.classList.remove('hidden');
    button.classList.add('hidden');

    fetch(e.currentTarget.dataset.path)
      .then((response) => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = button.dataset.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.spinnerTarget.classList.add('hidden');
        button.classList.remove('hidden');
      })
  }
}