import React from "react";
import { Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "i18n-js";

const useStyles = makeStyles((theme) => ({
  mediaButton: {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "20% 40% 20%",
    fontWeight: "400",
    alignItems: "center",
    color: "#000",
    width: "100%",
    height: "3em",
    background: "#fff",
    textTransform: "none",
    padding: 0,
    marginBottom: "2em",
    boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 10px rgba(91, 110, 130, 0.22)",
    },
  },
  MediaLogo: {
    display: "flex",
    alignItems: "center",
  },
}));

const MailSignupButton = ({ userEmail }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.mediaButton}
      onClick={() => {
        window.top.location.href = "/log_in?email=".concat(userEmail);
      }}
    >
      <Grid container justifyContent="center">
        <Link href={"/log_in?email=".concat(userEmail)} className={classes.MediaLogo}>
          <img
            alt="Facebook"
            style={{ height: "1.3em" }}
            src="email_icon.svg"
          ></img>
        </Link>
      </Grid>
      <Grid container justifyContent="center">
        {I18n.t("modules.login.email_login")}
      </Grid>
    </div>
  );
};

export default MailSignupButton;
