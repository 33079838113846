import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkboxes', 'checkAll']

  static values = {
    communityId: String,
    isNotifying: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.checkPaymentsSavedInLocalStorage()
    this.updateUrl()
    this.checkIfIsNotifying()
  }

  checkIfIsNotifying() {
    if (this.isNotifyingValue) {
      this.checkboxesTargets.forEach((checkbox) =>
        checkbox.disabled = true
      )

      this.checkAllTarget.disabled = true
      document.getElementById('notify-payments-btn-trigger').disabled = true
    }
  }

  check(event) {
    const id = event.target.dataset.paymentId

    this.saveInLocalStorage(id, false)
    this.checkIfAtLeastOneIsCheched()
    this.updateUrl()
  }

  toggleDisabled(value) {
    document.getElementById('notify-payments-btn-trigger').disabled = value
  }

  toggleCheckAll(event) {
    let checked = event.target.checked
    let checkboxes = this.checkboxesTargets.filter(item => item.checked != checked)

    checkboxes.forEach((checkbox) =>
      this.handleCheckbox(checkbox, checked)
    );

    this.checkIfAtLeastOneIsCheched()
  }

  checkIfAtLeastOneIsCheched() {
    let checkboxes = this.checkboxesTargets
    let checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    let checkedInLocalStorage = localStorage.getItem(`not_notified_payments_${this.communityIdValue}`)

    if (checkedInLocalStorage != null && checkedInLocalStorage != '') {
      checkedOne = true;
    }

    this.toggleDisabled(!checkedOne)

    if (!checkedOne && (checkedInLocalStorage == null || checkedInLocalStorage == '')) {
      localStorage.removeItem(`not_notified_payments_${this.communityIdValue}`)
    }

    this.checkIfallChecksAreChecked()
    this.updateCounter()
  }

  saveInLocalStorage(payment_id, fromChekAll) {
    let not_notified_payments = localStorage.getItem(`not_notified_payments_${this.communityIdValue}`)?.split(',') || []

    if (payment_id && !not_notified_payments.includes(`${payment_id}`)) {
      not_notified_payments.push(payment_id);
      localStorage.setItem(`not_notified_payments_${this.communityIdValue}`, not_notified_payments);
    } else {
      not_notified_payments = not_notified_payments.filter(item => item !== `${payment_id}`)
      localStorage.setItem(`not_notified_payments_${this.communityIdValue}`, not_notified_payments);
    }
  }

  handleCheckbox(checkbox, checked) {
    const id = checkbox.dataset.paymentId
    this.saveInLocalStorage(`${id}`, true)
    checkbox.checked = checked
  }

  checkPaymentsSavedInLocalStorage() {
    let not_notified_payments = localStorage.getItem(`not_notified_payments_${this.communityIdValue}`)?.split(',') || []
    let checkboxes = this.checkboxesTargets

    checkboxes.forEach((checkbox) =>
      checkbox.checked = not_notified_payments.includes(`${checkbox.dataset.paymentId}`)
    );

    this.checkIfallChecksAreChecked()
    this.checkIfAtLeastOneIsCheched()
  }

  checkIfallChecksAreChecked() {
    let checkboxes = this.checkboxesTargets
    let allChecksAreChecked = Array.prototype.slice.call(checkboxes).every(x => x.checked);

    if (checkboxes.length == 0) {
      this.checkAllTarget.checked = false
      this.checkAllTarget.disabled = true
      return
    }

    if (this.hasCheckAllTarget) {
      this.checkAllTarget.checked = allChecksAreChecked
    }
  }

  updateCounter() {
    let not_notified_payments = localStorage.getItem(`not_notified_payments_${this.communityIdValue}`)?.split(',') || []

    document.getElementById('payments-counter').innerHTML = not_notified_payments.length
    this.updateUrl()
  }

  openModal() {
    $('#confirmation-modal').modal('show')
  }

  updateUrl() {
    let not_notified_payments = localStorage.getItem(`not_notified_payments_${this.communityIdValue}`)?.split(',') || []

    let button = document.getElementById('submit-bulk-payments-button');
    let url = button.href.split('?')[0];
    url += '?payment_ids=' + not_notified_payments

    button.href = url
  }
}
