import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['advancedOptionsButton', 'hideable', 'companionList', 'requirable']
  static values = { companionGuestCount: Number, personalIdentificationType: String }

  toggleHideable() {
    let hidden = this.hideableTargets[0].getAttribute('style')
    this.hideableTargets.forEach(field => {
      if (hidden) {
        field.removeAttribute('style')
      } else {
        field.style.display = 'none'
      }
      this.toggleRequirable(hidden)
    })
    if (hidden) {
      this.advancedOptionsButtonTarget.innerHTML = I18n.t('views.guest_registry.form.show_less')
    } else {
      this.advancedOptionsButtonTarget.innerHTML = I18n.t('views.guest_registry.form.show_more')
    }
  }

  toggleRequirable(hidden) {
    this.requirableTargets.forEach(field => {
      hidden ? field.required = true : field.required = false;
    })
  }
  maskRut() {
    $('.rut-field').each(function() {
      $(this).mask('00.000.000-A', { reverse: true, placeholder: '11.111.111-1' });
    });
  }

  hideCompleteInformationModal(){
    $('#complete_information').modal('hide');
  }

  addCompanion() {
    const count = this.companionGuestCountValue
    this.companionGuestCountValue += 1
    let formField = document.createElement('div')
    formField.classList.add('form-field', 'two-inputs-and-button')
    let nameField = document.createElement('input')
    nameField.type = 'text'
    nameField.classList.add('form-control', 'input-sm')
    nameField.name = `companion_guest[${count}][name]`
    nameField.id = `companion_guest_${count}_name`
    nameField.placeholder = I18n.t('activerecord.attributes.guest_registry.name')
    formField.appendChild(nameField)
    let identificationField = document.createElement('input')
    identificationField.type = 'text'
    identificationField.classList.add('form-control', 'input-sm')
    identificationField.name = `companion_guest[${count}][cid]`
    identificationField.id = `companion_guest_${count}_cid`
    identificationField.placeholder = this.personalIdentificationTypeValue
    formField.appendChild(identificationField)
    let button = document.createElement('div')
    button.classList.add('btn', 'btn-default', 'btn-sm')
    let icon = document.createElement('i')
    icon.classList.add('fa', 'fa-trash-o')
    button.appendChild(icon)
    button.innerHTML += I18n.t('common.delete')
    button.setAttribute('data-action', 'click->guest-registry--form#deleteRow')
    formField.appendChild(button)
    this.companionListTarget.appendChild(formField)
  }

  deleteRow(event) {
    event.currentTarget.closest('.form-field').remove()
  }

  cancelButton() {
    window.location.reload();
  }
}
