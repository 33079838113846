import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.action.active,
    textDecoration: "none",
    "&:hover": {
      fontWeight: "bold",
    },
    "&:disabled": {
      color: theme.palette.action.disabled,
      opacity: 0.4,
    },
  },
}));

function RouterLink(props) {
  const classes = useStyles();
  return (
    <Link className={classes.root} {...props}>
      {props.label}
    </Link>
  );
}

export default RouterLink;
