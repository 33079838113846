
function validateDate(event, current) {
  let selectStartDate = current.startDateInputTarget.value.split('-')
  let selectEndtDate = current.endDateInputTarget.value.split('-')
  let startDate = new Date(selectStartDate[0], selectStartDate[1], selectStartDate[2])
  let endDate = new Date(selectEndtDate[0], selectEndtDate[1], selectEndtDate[2])
  let selectId = event.currentTarget.dataset.selectid
  let warningStartDate = document.getElementById(`js-days-startdate-warning-${selectId}`)
  let warningEndDate = document.getElementById(`js-days-enddate-warning-${selectId}`)
  let startDateInput = document.getElementById(`${selectId}-start-selector-input`)
  let endDateInput = document.getElementById(`${selectId}-end-selector-input`)
  let input = event.currentTarget.id.split('-')[1]
  event.target.parentElement.dataset.dateSelectorDefaultDateValue = event.target.value

  if (endDate < startDate || startDate > endDate) {
    if (input == 'start') {
      startDateInput.classList.add('warning-input');
      warningStartDate.style.display = 'block';
    } else {
      endDateInput.classList.add('warning-input');
      warningEndDate.style.display = 'block';
    }

    return false
  }

  startDateInput.classList.remove('warning-input');
  endDateInput.classList.remove('warning-input');
  warningStartDate.style.display = 'none'
  warningEndDate.style.display = 'none'

  return true
}

export { validateDate };
