import { Controller } from "stimulus"

export default class extends Controller {
  selectAll(event) {
    let checked = event.target.checked
    let checkboxes = document.querySelectorAll('[check_box_type=solidarity-loan-discount]')

    if (checked && checkboxes.length > 0){
      checkboxes.forEach(function (checkbox){ checkbox.checked = true })
    } else {
      checkboxes.forEach(function (checkbox){ checkbox.checked = false })
    }
  }
}
