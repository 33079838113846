import { CssBaseline, Grid } from "@material-ui/core";

const MainContainer = ({ children }) => {
  return (
    <Grid container component="main">
      <CssBaseline />
      {children}
    </Grid>
  );
};

export default MainContainer;
