import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['remunerationCreditsPath'];

  connect() {
    this.validateEmptyTable();
  }

  validateEmptyTable() {
    if (this.element.getElementsByClassName('table-row').length === 0 && this.remunerationCreditsPathTarget) {
      window.location = this.remunerationCreditsPathTarget.value;
    }
  }
}
