import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cancelButton", "input", "saveButton"];
  static values = { id: Number };

  async update() {
    this.toggleDisabledElements(true);

    await this.performUpdateRequest();

    this.toggleDisabledElements(false);
    this.hideTooltips();
  }

  price() {
    const priceInputAutoNumericElement = AutoNumeric.getAutoNumericElement(
      this.inputTarget
    );

    return priceInputAutoNumericElement.getNumber();
  }

  endpoint() {
    return Routes.bill_path(this.idValue);
  }

  async performUpdateRequest() {
    await $.ajax({
      url: this.endpoint(),
      type: "PATCH",
      data: { bill: { price: this.price() } },
      dataType: "script",
    });
  }

  toggleDisabledElements(disabled) {
    [
      this.cancelButtonTarget.disabled,
      this.inputTarget.disabled,
      this.saveButtonTarget.disabled,
    ] = Array(3).fill(disabled);
  }

  hideTooltips() {
    $(this.saveButtonTarget).tooltip("hide");
    $(this.cancelButtonTarget).tooltip("hide");
  }
}
