import { Controller } from "stimulus"
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = { currencySymbol: '$', currencySymbolPlacement: 'p', digitGroupSeparator: '.', decimalCharacter: ',', decimalPlaces: 0 }

export default class extends Controller {
  static targets = [ 'draftForm', 'draftReason', 'draftDays', 'draftStartDate', 'draftEndDate',
                      'discountDays', 'reasonInput', 'daysInput', 'startDateInput', 'endDateInput',
                      'destroyForm', 'destroyDays', 'license', 'updateLicense', 'addLicenseButton',
                      'updateForm', 'updateReason', 'updateDays', 'updateStartDate', 'updateEndDate',
                      'deleteLicenseButton', 'newLicense' ];

  licenseTargetConnected(element) {
    let licenseId = element.closest('turbo-frame').getAttribute('id').split('_').pop()
    if (element.dataset.field == 'ultimo_total_imponible_sin_licencia') {
      new AutoNumeric(element, AUTONUMERIC_FORMAT)
    }

    const self = this
    element.addEventListener('change', (event) => {
      self.updateLicenseFieldValue(licenseId, event.target)
    })
  }

  updateLicenseDaysValue() {
    let days = 0
    this.licenseTargets.forEach(target => {
      if (target.getAttribute('id') == 'days') days += parseInt(target.value)
    })
    document.getElementById('salary_payment_dias_licencia').value = days
  }

  updateLicenseFieldValue(licenseId, target) {
    let id = licenseId ? `#license_form_${licenseId}` : 'form#new_license'
    let licenseForm = document.querySelector(id)
    let field = target.dataset.field
    let dataField = licenseForm.querySelector(`[data-field="${field}"]`)

    if (field == 'start_date' || field == 'end_date') this.validateDateForm(target);

    if (field == 'ultimo_total_imponible_sin_licencia') {
      dataField.value =  AutoNumeric.getAutoNumericElement(target).getNumber()
    } else {
      dataField.value = target.value
    }
    if (licenseId) {
      if (this.validateDateForm(target)) {
        const changeEvent = new Event('change');
        dataField.dispatchEvent(changeEvent);
      }
    }
  }

  newLicenseTargetConnected(element) {
    if (element.dataset.field == 'ultimo_total_imponible_sin_licencia') {
      new AutoNumeric(element, AUTONUMERIC_FORMAT)
    }

    element.addEventListener('change', (event) => this.updateLicenseFieldValue(false, event.target))
  }

  updateLicenseTargetConnected(element) {
    let licenseId = element.closest('form').getAttribute('id').split('_').pop()
    element.addEventListener('change', () => this.submitLicenseForm(licenseId))
  }

  addLicenseButtonTargetConnected(element) {
    element.addEventListener('click', () => this.submitLicenseForm(false))
  }

  submitLicenseForm(licenseId) {
    let id = licenseId ? `#license_form_${licenseId}` : 'form#new_license'
    let valid_date = this.validateDateForm(event.target)

    if (valid_date) this.updateLicenseDaysValue();

    if (!licenseId && valid_date) {
      let newValue = document.querySelector("turbo-frame[id='new_license_draft']").querySelector('#days').value
      let newAmount = document.querySelector("turbo-frame[id='new_license_draft']").querySelector('#ultimo_total_imponible_sin_licencia')
      let licenseDays = document.getElementById('salary_payment_dias_licencia')
      let licenseAmount = document.getElementById('salary_payment_ultimo_total_imponible_sin_licencia')
      let LicenseFormAmount = document.querySelector(id).querySelector('#license_draft_ultimo_total_imponible_sin_licencia')
      let amount = AutoNumeric.getAutoNumericElement(newAmount).getNumber() || newAmount.value
      licenseDays.value = parseInt(licenseDays.value) + parseInt(newValue)
      licenseAmount.value = AutoNumeric.getAutoNumericElement(newAmount).getNumber()
      LicenseFormAmount.value = amount
    }
    document.querySelector(id).requestSubmit()
  }

  createDiscountInDraft() {
    let selectId = event.currentTarget.dataset.selectid
    this.draftReasonTarget.value = this.reasonInputTarget.value
    this.draftDaysTarget.value = this.daysInputTarget.value
    this.draftStartDateTarget.value = document.getElementById(`${selectId}-start-selector-input`).value
    this.draftEndDateTarget.value = document.getElementById(`${selectId}-end-selector-input`).value

    if (this.draftReasonTarget.value != '' && this.draftDaysTarget.value != ''
    && this.draftStartDateTarget.value != '' && this.draftEndDateTarget.value != ''){
      if (this.validateDateForm(event.target)) {
        this.discountDaysTarget.value = parseInt(this.discountDaysTarget.value) + parseInt(this.draftDaysTarget.value)
      }

      this.draftFormTarget.requestSubmit()
    }
  }

  destroyDiscountInDraft(event) {
    let index = parseInt(event.currentTarget.dataset.index)
    this.discountDaysTarget.value = parseInt(this.discountDaysTarget.value) - parseInt(this.destroyDaysTargets[index].value)
    this.destroyFormTargets[index].requestSubmit()
  }

  updateDiscountInDraft(event) {
    let index = parseInt(event.currentTarget.dataset.index)
    let field = event.currentTarget.dataset.field

    switch (field) {
      case 'reason':
        this.updateReasonTargets[index].value = event.currentTarget.value;
        break;
      case 'days':
        if (!this.validateDateForm(event.target)) {
          break;
        }

        let newValue = (parseInt(event.currentTarget.value) || 0);
        if (newValue < 0) newValue = 0;

        this.updateDaysTargets[index].value = newValue;
        this.discountDaysTarget.value = parseInt(this.discountDaysTarget.value) - parseInt(event.currentTarget.defaultValue);
        this.discountDaysTarget.value = parseInt(this.discountDaysTarget.value) + newValue;
        break;
      case 'start_date':
        this.updateStartDateTargets[index].value = event.currentTarget.value;
        this.validateDateForm(event.target);
        break;
      case 'end_date':
        this.updateEndDateTargets[index].value = event.currentTarget.value;
        this.validateDateForm(event.target);
        break;
    }

    if (this.validateDateForm(event.target)) {
      this.updateFormTargets[index].requestSubmit()
    }
  }

  validateNewDateDiscountForm(event) {
    let selectId = event.target.dataset.selectid
    let selectStartDate =  document.getElementById(`${selectId}-start`)
    let selectEndDate = document.getElementById(`${selectId}-end`)

    if (selectStartDate.value != '' && selectEndDate.value != '') {
      this.validateDateForm(event.target);
    }
  }

  validateAllDate() {
    this.updateLicenseDaysValue();
    let all_inputs = document.querySelectorAll(".add-padding")
    let exit_error = false
    for (let div of all_inputs) {
      let i = 0;
      for (let input of div.querySelectorAll(".selected-value") ) {
        if (i % 2 === 0) {
          let selectId = input.id.split('-')
          let dateToEvaL = document.getElementById(`${selectId[0]}-end`)

          if (dateToEvaL != null && dateToEvaL.value != '') {
            if (this.validateDateForm(dateToEvaL)) {
              document.getElementById('date_error').value = false
            } else {
              document.getElementById('date_error').value = true
              exit_error = true
              break
            }

          }
        }
        i++;
      }
      if (exit_error) {
        break
      }
    }
  }

  validateDateForm(current) {
    let selectId = current.dataset == undefined ? current.target.dataset.selectid : current.dataset.selectid

    if (selectId == undefined) {
      return
    }

    let selectStartDate = document.getElementById(`${selectId}-start`).value.split('-')
    let selectEndDate = document.getElementById(`${selectId}-end`).value.split('-')
    let startDate = new Date(selectStartDate[0], selectStartDate[1], selectStartDate[2])
    let endDate = new Date(selectEndDate[0], selectEndDate[1], selectEndDate[2])
    let warningStartDate = document.getElementById(`js-days-startdate-warning-${selectId}`)
    let warningEndDate = document.getElementById(`js-days-enddate-warning-${selectId}`)
    let startDateInput = document.getElementById(`${selectId}-start-selector-input`)
    let endDateInput = document.getElementById(`${selectId}-end-selector-input`)
    let input = current.dataset == undefined ? current.target.id.split('-')[1] : current.id.split('-')[1]
    current.parentElement.dataset.dateSelectorDefaultDateValue = current.value

    if (endDate < startDate || startDate > endDate) {
      if (input == 'start') {
        startDateInput.classList.add('warning-input');
        warningStartDate.style.display = 'block';
      } else {
        endDateInput.classList.add('warning-input');
        warningEndDate.style.display = 'block';
      }

      return false
    }

    startDateInput.classList.remove('warning-input');
    endDateInput.classList.remove('warning-input');
    warningStartDate.style.display = 'none'
    warningEndDate.style.display = 'none'

    return true
  }
}
