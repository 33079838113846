import { createTheme } from "@material-ui/core"

const theme = createTheme({
  palette: {
    common: {
      black: '#444954',
      white: '#FFFFFF',
    },
    text: {
      primary: '#444954',
      secondary: '#5B6E82',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: '#A6B3C1',
    },
    primary: {
      main: '#49F2A5',
      light: '#C8FBE4',
      dark: '#00B470',
      contrastText: '#FFF',
    },
    secondary: {
      light: '#505CEF',
      main: '#505CEF',
      dark: '#2E35A5',
      contrastText: '#FFF',
    },
    error: {
      light: '#E57373',
      main: '#FFD3DE',
      dark: '#F44336',
      contrastText: '#FFF',
    },
    background: {
      default: '#F9F9F9',
      paper: '#ffffff',
    },
    lines: {
      default: '#eaeaea',
    },
    public: {
      btn_media_hover: '#EAEAEA',
      light: '#EFF3FA',
      default_btn: '#EFF3FA',
      smooth_background: '#F7F8FD',
      focus: '#444954',
      public_btn_bg: '#49F2A5',
      public_btn_color: '#09814B',
      public_btn_hover: '#49F2A5',
      public_btn_disabled: '#0ecb68',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif !important',
    h1: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    subtitle1: {
      fontFamily: 'Montserrat, sans-serif !important',
      fontSize: '1.5rem',
    },
    subtitle2: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    body1: {
      fontFamily: 'Montserrat, sans-serif !important',
      color: '#444954',
      fontSize: '1.6rem',
    },
    body2: {
      fontFamily: 'Montserrat, sans-serif !important',
      fontSize: '1.3rem',
    },
    button: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
    caption: {
      fontFamily: 'Montserrat, sans-serif !important',
      fontSize: '1.3rem',
    },
    overline: {
      fontFamily: 'Montserrat, sans-serif !important',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiSelect: {
      root: {
        fontSize: '1.6rem',
      },
    },
  },
} as any)

export default theme
