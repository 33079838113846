import React, { Component } from 'react';
import {setGlobalState} from "../state";
import {Redirect, Route, Switch} from "react-router-dom";
import HomePage from "../pages/HomePage";
import {RegisterPartOwnerPage} from "../pages";
import {responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import theme from './theme';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

class App extends Component {

  constructor(props) {
    super(props);
    setGlobalState({
      email: props.data.email,
      userToken: props.data.token,
      propertyId: props.data.property_id,
      communityId: props.data.community_id,
      lastUri: props.data.last_uri,
      loggedAsPropertyUser: props.data.logged_as_property_user,
      createdByOauth: props.data.created_by_oauth,
      historyData: props.data.historyData,
      dataSource: props.data.dataSource,
      bill_id: props.data.bill_id,
      incomeFromMorosity: props.data.incomeFromMorositye,
      noCommunity: props.data.no_community,
      res2_redirection: props.data.res2_redirection,
    });
  }

  components = {
    home: "/",
    sign_up: "/sign_up"
  };

  state = {
    redirect: this.components[this.props.data.page],
    props: this.props.data
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: this.state.redirect,
        props: this.state.props
      }} />
    }
  };

    render(){
      let publicTheme = responsiveFontSizes(theme);
      if(this.props.data.community_id || this.props.data.no_community){
        return (
          <div className="App">
              <ThemeProvider theme={publicTheme}>
                <EmotionThemeProvider theme={publicTheme}>
                  {this.renderRedirect()}
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/log_in" component={HomePage} />
                    <Route exact path="/sign_up" component={RegisterPartOwnerPage} />
                  </Switch>
                </EmotionThemeProvider>
              </ThemeProvider>
            </div>
        );
      } else {
        return (
            <div className="App">
              <ThemeProvider theme={publicTheme}>
                <RegisterPartOwnerPage />
              </ThemeProvider>
            </div>
        );
      }
    }
}

export default App
