import BaseController from './base_controller'
import I18n from "i18n-js";

export default class extends BaseController {
  static values = { subcategories: Object, subcategoryName: String }
  static targets = ['serviceBillingForm', 'warningUniqueRutModal']

  connect () {
    const categorySelect = document.getElementById('category_name-hidden-value');
    const subcategorySelect = document.getElementById('subcategory_name-hidden-value')
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');

    if (subcategorySelect) {
      categoryNameInput.value = categorySelect.value
      subcategoryNameInput.value = subcategorySelect.value
    }
    this.warningUniqueRutModalTarget.removeAttribute('aria-hidden');
  }

  showFormCategory (selector) {
    const category = selector.target.value
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
    } else {
      categoryForm.classList.add('hide')
    }
  }

  selectCategory (event) {
    const category = event.target?.value
    const subCategorySelect = document.getElementById('subcategory_name-hidden-value');
    const subCategorySelector = document.getElementById('subcategory_name-selector');
    const subcategoryNameButton = document.getElementById('subcategory_name-button');
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    subCategorySelect.removeAttribute('value')
    this.clearOptions(subCategorySelector)

    subCategorySelect.parentElement.getElementsByClassName('selected-value')[0].textContent = I18n.t('views.service_billings.form.none_selected')
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
      subcategoryNameButton.setAttribute('disabled', true)
      this.resetInput();
      categoryNameInput.classList.remove('disabled')
    } else {
      subcategoryNameButton.removeAttribute('disabled')
      categoryNameInput.value = category
      subcategoryNameInput.value = ''
      categoryForm.classList.add('hide')
      this.appendOptions(category, subCategorySelector)
    }
  }

  appendOptions (category, selector) {
    const subCategoriesOptions = this.subcategoriesValue[category];

    if (subCategoriesOptions) {
      for (let subcategory of subCategoriesOptions) {
        this.appendNewOption(selector, subcategory, subcategory)
      }
    }

    this.appendNewOption(selector, I18n.t('views.service_billings.form.other_category'), 0)
  }

  validateUniqueRut(event) {
    const supplierRut = document.getElementById('supplier_rut').value;
    const priceInput = document.getElementById('service_billing_price');
    const autonumericElem = AutoNumeric.getAutoNumericElement(priceInput)
    if (autonumericElem) {
      priceInput.value = autonumericElem.getNumber();
    }
    this.convertToRawValues();
    if (supplierRut.length == 0) return this.serviceBillingFormTarget.submit();
    const communityId = event.target.dataset.communityId;
    const countryCode = event.target.dataset.countryCode;

    let params = new URLSearchParams({ rut: JSON.stringify(supplierRut), community_id: JSON.stringify(communityId), country_code: JSON.stringify(countryCode) });

    return fetch('/validate_unique_rut.json' + '?' + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.exists) {
        $('#warning-unique-modal').modal('show');
      } else {
        this.serviceBillingFormTarget.submit();
      }
    });
  }

  removeOptions (selector) {
    const options = selector.parentElement.getElementsByClassName('dropdown-item-selectable')
      Array.from(options).forEach((option) => {
      option.parentElement.removeChild(option)
    });
  }

  convertToRawValues() {
    const autonumericInputs = document.querySelectorAll('input[data-autonumeric]')
    autonumericInputs.forEach((input) => {
      let e = AutoNumeric.getAutoNumericElement(input)
      input.value = e.rawValue
    })
  }

  selectSubcategory (selector) {
    const subcategory = selector.target.value
    const categorySelect = document.getElementById('category_name-hidden-value');
    const inputCategoryName = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    const categoryForm = document.getElementById('new_category');

    if (subcategory === '0') {
      categoryForm.classList.remove('hide')
      if (categorySelect.value) {
        inputCategoryName.value = categorySelect.value
        inputCategoryName.classList.add('disabled')
      }
      subcategoryNameInput.value = ''
    } else {
      subcategoryNameInput.value = subcategory
      inputCategoryName.value = categorySelect.value
      categoryForm.classList.add('hide')
      inputCategoryName.classList.add('disabled')
    }
  }

  resetInput() {
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    categoryNameInput.value = ''
    subcategoryNameInput.value = ''
  }
}
