import { DeleteForeverTwoTone } from '@material-ui/icons'
import AutoNumeric from 'autonumeric'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'body',
    'row',
    'rowTemplate'
  ]

  connect() {
    if (this.rowTargets.length == 0) {
      this.addRow()
    }
  }

  addRow() {
    const baseRow = this.rowTemplateTarget.content
    const body = this.bodyTarget || this.element.querySelector('tbody')
    body.appendChild(document.importNode(baseRow, true))
    $.reloadAllAutonumeric();
  }

  deleteRow(event) {
    const row = event.target.closest('tr')
    row.remove()
  }
}
