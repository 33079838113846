import { Controller } from "stimulus";

export default class extends Controller {
  static values = { propertyId: Number }

  call() {
    const property_id = this.propertyIdValue

    // show manual assignation div
    document.getElementsByClassName(`manual_assignation${property_id}`)[0].style.display = ''

    let debt_content =  document.getElementById('debt-content-' + property_id)
    let loader_content = document.getElementById('loader-content-' + property_id)

    if (debt_content.innerHTML != ''){ return }

    let data = new URLSearchParams({ 'property[id]': property_id })
    const url = window.debt_assignation_path + '?' + data
    const headers = { 'contentType': 'text/html',
                      'Accept': 'text/html',
                      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }

    fetch(url, { method: 'GET', headers: headers})
      .then(response => response.text() )
      .then(data => { // jquery is used to add html with embedded js script in an element
                      $(`.manual_assignation${property_id} .debt-content`).html(data)
                      loader_content.style.display = 'none' })
  }
}
