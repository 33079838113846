import { Controller } from 'stimulus';
export default class extends Controller {

  connect() {
    this.addSidebarEvents();
  }

  addSidebarEvents() {
    document.addEventListener('turbo:load', function() {
      $('[data-toggle="tooltip"]').tooltip();

      let onHoverIn = function () {
        if (($('.mobile-burger').css('display')=='none')){
          $('#sidebar').hasClass('active') && $('#sidebar').removeClass('active');
        }
      }

      let onHoverOut = function () {
        if (($('.mobile-burger').css('display')=='none')){
          !$('#sidebar').hasClass('active') && $('#sidebar').addClass('active')
        }
      }

      $('#sidebar').mouseenter(onHoverIn).mouseleave(onHoverOut);
    })
  }
}
