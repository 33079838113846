import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['requestType', 'requestTypeLabel'];

  setRequestType({ params, target }) {
    this.requestTypeTarget.value = params.requestType;
    this.requestTypeLabelTarget.innerText = target.innerHTML;
  }
};
