import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.public.smooth_background,
    width: "100%",
    marginTop: "-15px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label:{
    height: "0px"
  },
  icon: {
    width: "30%",
    color: "#fffaaa"
  }
    
}));

const useDotStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.public.focus,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.public.focus,
    zIndex: 1,
    fontSize: 18,
  },
}));

function DotStepIcon(props) {
  const classes = useDotStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root, {[classes.active]: active})}>
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function OnboardingSteps(props) {
  const classes = useStyles();
  const [steps, setSteps] = useState(props.steps);
  
  return (
    <div className={classes.root}>
      <Stepper className={classes.root} activeStep={props.number} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              style={{ height: 0 }}
              StepIconComponent={DotStepIcon}>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
export default OnboardingSteps;