import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "template", "linkTemplate"];
  static values = {
    cancel: String,
    confirm: String,
    message: String,
    title: String,
  };

  toggleModal(event) {
    event.preventDefault();

    if (this.hasTemplateTarget) {
      this.buildModalFromTemplate();
    }

    $(this.modalTarget).modal();
  }

  submitForm() {
    $(this.modalTarget).modal('hide');

    return true;
  }

  buildModalFromTemplate() {
    const replacements = {
      CANCEL: this.cancelValue,
      CONFIRM: this.confirmValue,
      MESSAGE: this.messageValue,
      TITLE: this.titleValue,
    };
    const modal = this.replaceRegex(
      this.templateTarget.innerHTML,
      replacements
    );

    this.modalTarget.innerHTML = modal;
  }

  replaceRegex(modal, replacements) {
    const regex = new RegExp(Object.keys(replacements).join("|"), "g");

    return modal.replace(regex, (key) => replacements[key]);
  }
}
