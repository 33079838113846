import { Controller } from "stimulus"
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = [ 'expirationDate', 'expirationDateInput' ]

  initialize() {
    this.pensionerValidationButton();
    this.addWarningCesantia();
    this.addWarningIps();
  }

  pensionerValidationButton() {
    let pensioner = 'false'
    if (document.getElementById('pensioner_validation')) pensioner = document.getElementById('pensioner_validation').value
    let submitButton = document.getElementById('submitButton');

    if (pensioner == 'true') submitButton.disabled = true;
  }

  addWarningPension(event){
    let employeeType = event.currentTarget != undefined ? event.currentTarget.value : event.value;
    let warning = document.getElementById("js-text_pension_error");

    if (employeeType == '0') {
      document.getElementById('salary_tipo_empleado-button').classList.add('warning-select')
      if(warning != null) warning.removeAttribute('style');
    } else {
      if(warning != null) warning.style.display = 'none';
      document.getElementById('salary_tipo_empleado-button').classList.remove("warning-select");
    }

    this.validateWarnings();
  }

  triggerPensionValidation(event) {
    let params = JSON.parse(event.currentTarget.dataset.params)
    let invalidPensionType = params.active_without_pension
    let pensionerValidation = document.getElementById('age_pensioner_validation').value;

    if (invalidPensionType || pensionerValidation == 'true') this.addWarningPension(event)
  }

  addWarningCesantia(){
    let afp_type = document.getElementById("salary_afp");
    let has_cesantia = document.getElementById("salary_has_seguro_cesantia");
    let warning = document.getElementById("js-text-cesantia-error");

    if (afp_type.value == I18n.t('views.commons.has_not') && has_cesantia.value == 'true') {
      afp_type.classList.add('warning-select')
      if(warning != null) warning.removeAttribute('style');

      this.addTooltip();
    } else {
      if(warning != null) warning.style.display = 'none';
      afp_type.classList.remove("warning-select");
    }

    this.validateWarnings();
  }

  addWarningIps(){
    let salary_has_ips = document.getElementById('salary_has_ips');
    let salary_ex_caja_regimen = document.getElementById('ex_caja_regimen-hidden-value');
    let salary_ex_caja_regimen_select = document.getElementById('ex_caja_regimen-button');
    let salary_tasa_cotizacion_ex_caja = document.getElementById('salary_tasa_cotizacion_ex_caja');
    let warning_caja_regimen = document.getElementById('js-text-ex_caja_regimen-error');
    let warning_tasa_cotizacion = document.getElementById('js-text-tasa_cotizacion-error');

    if (salary_has_ips.value == 'true') {
      if (salary_ex_caja_regimen.value == I18n.t('views.remunerations.salaries.ips_code_empty')) {
        salary_ex_caja_regimen_select.classList.add("warning-select");
        if(warning_caja_regimen != null) warning_caja_regimen.removeAttribute('style');
      } else {
        salary_ex_caja_regimen_select.classList.remove("warning-select");
        if(warning_caja_regimen != null) warning_caja_regimen.style.display = 'none';
      }

      if (salary_tasa_cotizacion_ex_caja.value == undefined || salary_tasa_cotizacion_ex_caja.value == '' || !(parseFloat(salary_tasa_cotizacion_ex_caja.value) > 0) ) {
        salary_tasa_cotizacion_ex_caja.classList.add("warning-select");
        if(warning_tasa_cotizacion != null) warning_tasa_cotizacion.removeAttribute('style');
      } else {
        salary_tasa_cotizacion_ex_caja.classList.remove("warning-select");
        if(warning_tasa_cotizacion != null) warning_tasa_cotizacion.style.display = 'none';
      }

    } else {
      if(warning_caja_regimen != null) warning_caja_regimen.style.display = 'none';
      if(warning_tasa_cotizacion != null) warning_tasa_cotizacion.style.display = 'none';
      salary_ex_caja_regimen_select.classList.remove("warning-select");
      salary_tasa_cotizacion_ex_caja.classList.remove("warning-select");
    }

    this.validateWarnings();
  }

  invalidateIpsInputs(){
    let salary_has_ips = document.getElementById('salary_has_ips').value;
    let salary_ex_caja_regimen = document.getElementById('ex_caja_regimen-hidden-value').value;
    let salary_tasa_cotizacion_ex_caja = document.getElementById('salary_tasa_cotizacion_ex_caja').value;

    if (salary_has_ips == 'true') {
      if (salary_ex_caja_regimen == I18n.t('views.remunerations.salaries.ips_code_empty')) return true;
      if (salary_tasa_cotizacion_ex_caja == undefined || salary_tasa_cotizacion_ex_caja == '' || !(parseFloat(salary_tasa_cotizacion_ex_caja) > 0) ) return true;
    }

    return false;
  }

  validateWarnings(){
    let afpType = document.getElementById("salary_afp");
    let hasCesantia = document.getElementById("salary_has_seguro_cesantia");
    let pensionerValidation = document.getElementById('age_pensioner_validation').value;
    let employeeType = document.getElementById('salary_tipo_empleado-hidden-value').value;
    let submitButton = document.getElementById('submitButton');

    if (employeeType == '0' && pensionerValidation == 'true' ||
        afpType.value == I18n.t('views.commons.has_not') && hasCesantia.value == 'true' || this.invalidateIpsInputs()) {
      submitButton.disabled = true;
      this.addTooltip();
      return false;
    }

    submitButton.disabled = false;
    this.removeTooltip();
    return true;
  }

  addTooltip(){
    $('[data-toggle="tooltip"]').tooltip('enable');
  }

  removeTooltip(){
    $('[data-toggle="tooltip"]').tooltip('disable');
  }

  hideExpirationDate(event){
    if (event.currentTarget.value == 'Indefinido') {
      this.expirationDateTarget.classList.add('hidden')
      this.expirationDateInputTarget.value = ''
    }
    else this.expirationDateTarget.classList.remove('hidden')
  }
}
