import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.modalTurboFrame.removeAttribute("src");
    this.modalTurboFrame.removeAttribute("reloadable");
    this.show();
  }

  show() {
    $(this.element).modal("show");
  }

  hide() {
    $(this.element).modal("hide");
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
