import React from "react";
import { GLOBAL_STATE } from "../state";
import {
  RegisterWithoutToken,
  RegisterWithToken,
} from "../features/RegisterPartOwner";

const RegisterPartOwnerPage = () => {
  const { userToken } = GLOBAL_STATE;

  return userToken ? <RegisterWithToken /> : <RegisterWithoutToken />;
};

export default RegisterPartOwnerPage;
