import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'disabledButton' ]

  call(event){
    event.currentTarget.disabled = true
    event.currentTarget.classList.add('disabled')
  }

  replaceWithDisableButton(event){
    event.currentTarget.classList.add('hidden')
    this.disabledButtonTarget.classList.remove('hidden')
  }
}
