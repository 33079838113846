import { Controller } from "stimulus"

export default class extends Controller {

  validateFieldInput(event){
    const files = event.target.files.length;
    const button = document.getElementById('submit_upload_file_button');

    if(files > 0){
      button.disabled = false;
    } else {
      button.disabled = true;
    }

  }

  deleteSelectedFile(event){
    const files = document.getElementById('file_input');
    const inputValue =  event.target.value;

    if(inputValue == ''){
      files.value = '';
      const changeEvent = new Event('change');
      files.dispatchEvent(changeEvent);
    }
  }
}
