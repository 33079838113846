import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal', 'accountName', 'form' ]

  openModal(event) {
    let currentTarget = event.currentTarget

    if(currentTarget.disabled) {
      return false;
    }

    $(this.modalTarget).modal()

    this.formTarget.setAttribute('action', currentTarget.dataset.actionUrl)
    this.formTarget.setAttribute('href', currentTarget.dataset.actionUrl)
    this.accountNameTarget.textContent = currentTarget.dataset.accountName
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }
}
