import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    action: String,
    modal: String
  }

  showModal() {
    trackEvent(
      'StartDemoCommunity',
      { action: this.modalValue }
    )
  }

  async startDemo() {
    trackEvent(
      'StartDemoCommunity',
      { action: this.actionValue }
    ).then(async () => {
      document.getElementById('start-demo-submit-button').click()
    })
  }
}
