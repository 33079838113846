import { Controller } from 'stimulus';

export default class extends Controller {

  toggleManyPaid(event) {
    const form = document.getElementById('toggle-many-paid-form');
    const checkboxes = document.getElementById('service-billings-table').querySelectorAll('.checkbox_user:checked');

    const ids = Array.from(checkboxes, checkbox => checkbox.name.match(/\d+/)[0]);
    console.log(ids);

    form.querySelectorAll('.hidden-input').forEach(el => el.remove());

    ids.forEach( id => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.className = 'hidden-input';
      input.name = 'service_billings[]';
      input.value = id;
      form.appendChild(input);
    });

    form.submit();
  }
}
