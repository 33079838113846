import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'icon']

  initialize() {
    this.hidden = true;
  }

  toggle(event) {
    if (this.hidden) {
      this.hidden = false

      this.inputTarget.type = 'text'
      this.iconTarget.classList.remove('fa-eye-slash')
      this.iconTarget.classList.add('fa-eye')
    } else {
      this.hidden = true

      this.inputTarget.type = 'password'
      this.iconTarget.classList.remove('fa-eye')
      this.iconTarget.classList.add('fa-eye-slash')
    }
  }
}
