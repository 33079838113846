import { Controller } from "stimulus"
import AutoNumeric from 'autonumeric'
import I18n from 'i18n-js'

const AUTONUMERIC_FORMAT = { currencySymbol: '$', currencySymbolPlacement: 'p', digitGroupSeparator: '.', decimalCharacter: ',', decimalPlaces: 0 }
const MONTH_START_NEW_WEEK_HOURS = 4;
const YEAR_START_NEW_WEEK_HOURS = 2024;
const AUTONUMERIC_FORMAT_DISCOUNT_HOURS = { decimalCharacter: '.', decimalPlaces: 2, decimalCharacterAlternative: ',', allowDecimalPadding: false }
const MAX_DISCOUNT_HOURS = 360
const PENSIONER_AGE = 65

export default class extends Controller {
  static values = { salaryId: Number, employeeId: Number, salaryPaymentId: Number };
  static targets = [ 'month', 'year', 'currencyInput', 'nonEmpty', 'discountHoursInput', 'warningWeekHours', 'fullTimeEmployee' ];

  connect() {
    // Needed to prevent "Changes you made may not be saved" pop-up window
    $(window).off('beforeunload');
    this.showModalHoursWarning();
    this.showModalPensionerWarning();
    this.showModalCesantiaWarning();
  }

  currencyInputTargetConnected(element) {
    new AutoNumeric(element, AUTONUMERIC_FORMAT)
  }

  discountHoursInputTargetConnected(element) {
    new AutoNumeric(element, AUTONUMERIC_FORMAT_DISCOUNT_HOURS)
    element.addEventListener('keyup', () => {
      let value = AutoNumeric.getAutoNumericElement(element)
      if (value.getNumber() > MAX_DISCOUNT_HOURS) {
        element.value = MAX_DISCOUNT_HOURS
        value.set(MAX_DISCOUNT_HOURS)
        this.showDiscountHoursModal()
      }
    })
  }

  showDiscountHoursModal() {
    document.getElementById('modal-title').innerHTML = I18n.t('views.remunerations.salary_payments.modals.discount_hours.title')
    document.getElementById('modal-body').innerHTML = I18n.t('views.remunerations.salary_payments.modals.discount_hours.body')
    $('#warning-modal').modal('show')
  }

  showModalPensionerWarning() {
    let month = parseInt(this.monthTarget.value);
    let year = parseInt(this.yearTarget.value);
    let pensioner_validation = document.getElementById('tipo_empleado').value;
    let birthdayMonth = parseInt(document.getElementById('birthday_month').value);
    let birthdayYear = parseInt(document.getElementById('birthday_year').value);
    let age = (year - birthdayYear - (month == birthdayMonth || month > birthdayMonth ? 0 : 1))

    if (age >= PENSIONER_AGE && pensioner_validation == '0'){
      $('#warning-pensioner-modal').modal('show');
    }
  }

  showModalCesantiaWarning() {
    let active_cesantia_with_invalid_afp = document.getElementById('active_cesantia_with_invalid_afp').value;

    if (active_cesantia_with_invalid_afp == 'true'){
      $('#warning-cesantia-modal').modal('show');
    }
  }

  nonEmptyTargetConnected(element) {
    this.nonEmpty(element);
  }

  updateSalaryInfo() {
    const month = this.monthTarget.value;
    const year = this.yearTarget.value;

    const path = Routes.get_indicators_remuneration_salary_payments_path({ employee_id: this.employeeIdValue});

    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    fetch(path, {
      method: 'POST',
      body: JSON.stringify({month: month, year: year}),
      headers: headers
    }).then(response => response.json())
      .then(result => {
        const { advance, ccaf } = result;

        AutoNumeric.getAutoNumericElement('#salary_payment_advance').set(advance);
        AutoNumeric.getAutoNumericElement('#credito_social').set(ccaf);
      })
  }

  reloadSalaryPayment() {
    const month = this.monthTarget.value;
    const year = this.yearTarget.value;

    const path = Routes.new_remuneration_salary_payment_path({salary_id: this.salaryIdValue, month, year});
    Turbo.visit(path)
  }

  toggleCreateServiceBilling(event) {
    const element = document.getElementById('payment_period_field');
    if(event.target.checked) {
      this.showElement(element);
    }
    else{
      this.hideElement(element);
    }
  }

  showModalHoursWarning() {
    const month = this.monthTarget.value;
    const year = this.yearTarget.value;
    if (month >= MONTH_START_NEW_WEEK_HOURS && year >= YEAR_START_NEW_WEEK_HOURS && this.warningWeekHoursTarget.value === 'true' && this.fullTimeEmployeeTarget.value === 'true') {
      $('#reduction-hours-warning').modal('show');
    }
  }

  toggleLastSalaryPayment(event) {
    const element = document.getElementById('ultimo_total_imponible_sin_licencia');
    if(event.target.value > 0) {
      this.showElement(element);
    } else {
      this.hideElement(element);
    }
  }

  togglePartnerData(event) {
    const option = event.target.value;

    for (const element of document.querySelectorAll('.spouse')) {
      if (option == 'true') {
        this.showElement(element);
      } else {
        this.hideElement(element);
      }
    }
  }

  toggleEmployeeProtectionLaw(event) {
    const option = event.target.value;

    if (option == 'true') {
      for (const element of document.querySelectorAll('.protection_law_inputs')) {
        this.showElement(element);
      }

      const lawCode = document.getElementById('salary_payment_protection_law_code').value;

      this.handleSelectedProtectionLawCode(lawCode);
    } else {
      for (const element of document.querySelectorAll('.protection_law_inputs')) {
        this.hideElement(element);
      }

      const reductionPercentageInput = document.querySelector('.reduction_percentage_input');
      this.hideElement(reductionPercentageInput);
    }
  }

  changeEmployeeProtectionLawCode(event) {
    this.handleSelectedProtectionLawCode(event.target.value);
  }

  checkNonEmpty(event) {
    this.nonEmpty(event.target);
  }

  hideElement(element) {
    element.style.display = 'none'
  }

  showElement(element) {
    element.style.display = 'block'
  }

  handleSelectedProtectionLawCode(lawCode) {
    if (lawCode == '') return;

    const reductionPercentageInput = document.querySelector('.reduction_percentage_input');
    const afcInformedRent = document.querySelector('.afc_informed_rent');
    const lastSalaryPayment = document.getElementById('ultimo_total_imponible_sin_licencia');
    const licenseDays = document.getElementById('salary_payment_dias_licencia');

    if (lawCode == 'reduccion_jornada_laboral') {
      this.showElement(lastSalaryPayment);
      this.showElement(reductionPercentageInput);
      this.hideElement(afcInformedRent);
    } else {
      this.hideElement(reductionPercentageInput);
      this.showElement(afcInformedRent);

      if (parseInt(licenseDays.value) == 0) {
        this.hideElement(lastSalaryPayment);
      }
    }
  }

  nonEmpty(element) {
    if (isNaN(parseInt(element.value))) {
      element.value = 0;
    }
  }

  preview() {
    const form_id = document.querySelector('.salary_payment_form').id;

    for ( const input of this.currencyInputTargets) {
      let autonumericElement = AutoNumeric.getAutoNumericElement(input);
      input.value = autonumericElement.getNumber();
    }

    let autonumericElement = AutoNumeric.getAutoNumericElement(this.discountHoursInputTarget);
    this.discountHoursInputTarget.value = autonumericElement.getNumber();

    document.getElementById('salary_payment_salary_attributes_payment_message').value = tinymce.activeEditor.getContent();

    let form_value = jQuery(`#${form_id}`).serialize() + `&employee_id=${this.employeeIdValue}`;

    if (this.salaryPaymentIdValue) {
      form_value += `&salary_payment_id=${this.salaryPaymentIdValue}`;
    }

    $.ajax({
      type: "GET",
      url: Routes.preview_modal_remuneration_salary_payments_path(),
      data: form_value,
      beforeSend: function() {
        const previewBtn = document.getElementById('preview_btn');
        previewBtn.innerHTML = "<span class='fa fa-spinner fa-pulse'>";
        previewBtn.setAttribute('disabled', true);
      },
      error: function(jqXHR, textStatus, errorThrown){
        $.notify({
          message: I18n.t('views.commons.errors.try_again'),
          icon: 'fa fa-times'
        }, {
          type: 'danger'
        });
      },
      complete: function() {
        const previewBtn = document.getElementById('preview_btn');
        previewBtn.innerHTML = I18n.t('views.commons.button.continue');
        previewBtn.removeAttribute('disabled');
      },
    })
  }
}
