import React, { useState, forwardRef, useImperativeHandle } from "react";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.text.primary
    },
}));

const BackdropCF = forwardRef((props, ref) => {
    const classes = useStyles();
    const [activate, setActivate] = useState(false);

    useImperativeHandle(ref, () => ({
        activate() {
            setActivate(true);
        },
        deactivate(){
            setActivate(false);
        }
    }));

    return (
        <Backdrop className={classes.backdrop} open={activate} >
            <CircularProgress className={classes.circularLoader} />
        </Backdrop>
    );
});

export default BackdropCF;
