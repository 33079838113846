import { Controller } from "stimulus";

export default class extends Controller {
  static values = { checkboxType: String,
                    divBtnDestroySelected: String,
                    pathDestroyAction: String,
                    btnDestroyAction: String,
                    checkboxIdAttribute: String }

  selectAll(event) {
    let checked = event.target.checked
    let checkboxes = document.querySelectorAll(`[check_box_type="${this.checkboxTypeValue}"]`)

    if (checked && checkboxes.length > 0){
      checkboxes.forEach(function (checkbox){ checkbox.checked = true })
      this.enableDivBtnDestroySelected()
    } else {
      checkboxes.forEach(function (checkbox){ checkbox.checked = false })
      this.disableDivBtnDestroySelected()
    }
  }

  singleSelect() {
    let checkboxes = document.querySelectorAll(`[check_box_type="${this.checkboxTypeValue}"]:checked`)
    if (checkboxes.length > 0) {
      this.enableDivBtnDestroySelected()
    } else {
      this.disableDivBtnDestroySelected()
    }
  }


  prepareDestroyActionUrl() {
    let checkbox_id_attribute_name = this.checkboxIdAttributeValue
    let checkboxes = document.querySelectorAll(`[check_box_type="${this.checkboxTypeValue}"]:checked`)
    let ids_to_destroy = Array.from(checkboxes).map(function(checkbox){ return checkbox.getAttribute(checkbox_id_attribute_name) })
    let data = new URLSearchParams({ 'ids_to_destroy': ids_to_destroy })
    let path = this.pathDestroyActionValue + '?' + data

    document.getElementById(this.btnDestroyActionValue).href = path
  }

  enableDivBtnDestroySelected() {
    let div = document.getElementById(this.divBtnDestroySelectedValue)
    div.removeAttribute('style')
  }

  disableDivBtnDestroySelected() {
    let div = document.getElementById(this.divBtnDestroySelectedValue)
    div.style.display = 'none'
  }
}
