import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "propertyUsersCanAddPropertyUsers",
    "maxPropertyUsersPerPropertyUser",
  ];

  connect() {
    const selectPropertyUsersCanAddPropertyUsers =
      this.propertyUsersCanAddPropertyUsersTarget.querySelector("select");

    selectPropertyUsersCanAddPropertyUsers.addEventListener("change", (e) => {
      if (e.currentTarget.value == 1) {
        this.maxPropertyUsersPerPropertyUserTarget.querySelector("select").removeAttribute("disabled");
      } else if (e.currentTarget.value == 0) {
        this.maxPropertyUsersPerPropertyUserTarget.querySelector("select").disabled = true;
      }
    });
  }
}
