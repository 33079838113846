import { Controller } from "stimulus"
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ['input', 'imageThumbnail', 'deleteButton', 'defaultImageThumbnail', 'imageThumbnailDiv', 'editButton', 'formatText', 'imageField']

  admitedFileType(file, errors) {
    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    let response = file && acceptedImageTypes.includes(file.type);
    if (!response) errors.push(I18n.t('views.user.form.avatar_filetype_error'));
    return response
  }

  admitedFileSize(file, errors) {
    let response = file.size * 1E-6 <= 4;
    if (!response) errors.push(I18n.t('views.user.form.avatar_size_error'));
    return response
  }

  constructAlert(errors) {
    let paragraph = document.createElement("p");
    paragraph.innerText = I18n.t('views.user.form.avatar_errors_prompt');
    let containerDiv = document.createElement("div");
    containerDiv.append(paragraph);
    let list = document.createElement("ul");
    errors.forEach(function (msg, i) {
      let li = document.createElement("li");
      li.innerText = msg;
      list.append(li);
    });
    containerDiv.insertAdjacentElement('beforeend', list);
    return containerDiv
  }

  validate(event) {
    const self = this
    let files = event.target.files;
    let image = files[0];
    let errors = [];
    let fileTypeOk = this.admitedFileType(image, errors);
    let fileSizeOk = this.admitedFileSize(image, errors);
    let alert = document.querySelector('.alert.error')
    if (fileTypeOk && fileSizeOk) {
      $('.flash_alert').html("");
      hide_notifications();
      let reader = new FileReader();
      reader.onload = function(file) {
        let img = new Image();
        img.src = file.target.result;
        img.style.height = 'inherit'
        if (self.hasImageThumbnailDivTarget) {
          self.imageThumbnailDivTarget.innerHTML = ''
          self.imageThumbnailDivTarget.appendChild(img)
          self.imageThumbnailDivTarget.removeAttribute('style')
          self.defaultImageThumbnailTarget.setAttribute('style', 'display: none')
          self.imageFieldTarget.classList.remove('error-border');
        } else {
          $('#picture').html(img);
        }
        self.hasDeleteButtonTarget && self.deleteButtonTarget.classList.remove('hidden');
        if (self.hasFormatTextTarget){
          self.formatTextTarget.innerHTML = I18n.t('views.community.edit.signature_valid_formats');
          self.formatTextTarget.classList.remove('error-format');
        }
      }
      reader.readAsDataURL(image);
    } else {
      if (alert) {
        this.inputTarget.value = null;
        if (self.hasImageThumbnailDivTarget) {
          this.inputTarget.style.display = 'none';
          this.defaultImageThumbnailTarget.removeAttribute('style');
          this.imageThumbnailDivTarget.style.display = 'none';
          this.deleteButtonTarget.classList.add('hidden');
          self.imageFieldTarget.classList.add('error-border');
        } else {
          $('#picture').html(new Image());
        }
        alert.querySelector('.flash_alert').innerHTML = this.constructAlert(errors).outerHTML;
        alert.style.display = 'block';
        if (self.hasFormatTextTarget){
          self.formatTextTarget.innerHTML = I18n.t('views.user.form.avatar_filetype_error');
          self.formatTextTarget.classList.add('error-format');
        }
      }
    };
  }

  removeImage() {
    this.inputTarget.value = null
    this.inputTarget.style.display = 'none'
    this.defaultImageThumbnailTarget.removeAttribute('style')
    this.imageThumbnailDivTarget.style.display = 'none'
    this.deleteButtonTarget.classList.add('hidden')
  }

  selectFile() {
    this.inputTarget.click();
  }
}
