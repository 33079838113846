import { Controller } from 'stimulus';

const INSTALLMENT_SLICE_INDEX = 2;

export default class extends Controller {
  static targets = [
    'invoice',
    'account',
    'totalPrice',
    'invoiceDescriptionText',
    'billingInvoicesIds',
    'submit',
    'billableIds',
    'billableInstallmentsIds',
    'gateway',
    'accountTable',
    'paymentSection',
    'totalSelectedText',
    'invoicePaymentPrice'
  ];

  static values = {
    transferenceUrl: String,
    onlinePaymentUrl: String,
    onlyOneAccountAvailable: Boolean
  }

  connect() {
    const dataset = this.totalPriceTarget.dataset
    const autonumericProps = {
      currencySymbol: dataset.autonumericAsign,
      currencySymbolPlacement: dataset.autonumericPsign,
      decimalPlaces: dataset.autonumericMdec,
      decimalCharacter: dataset.autonumericAdec,
      digitGroupSeparator: dataset.autonumericAsep
    }
    new AutoNumeric(this.invoicePaymentPriceTarget, { ...autonumericProps, unformatOnSubmit: true });
    new AutoNumeric(this.totalPriceTarget, autonumericProps);

    if (this.onlyOneAccountAvailableValue) {
      this.accountTargets.forEach(account => account.click())
    } else {
      this.setInitialState();
    }
  }

  setInitialState() {
    this.total = 0;
    this.billingInvoicesIds = new Set();
    this.billingInstallmentsIds = new Set();
    this.irsBillIds = new Set();
    this.selectedAccountId = null;
    this.resetCheckedOptions();
    this.updateAmountToPay();
    this.updateTransferDescriptionElements();
  }

  showInvoices(event) {
    this.totalSelectedTextTarget.classList.remove('hidden');

    this.setInitialState();

    const { target } = event;
    const { elementId } = this.getResourceId(target);
    this.accountTableTargets.forEach((table) => {
      if (table.getAttribute('id') == elementId) {
        table.classList.remove('hidden');
        this.checkAllInvoicesByDefault(elementId);
      } else {
        table.classList.add('hidden');
      }
    });
  }

  updateDescriptionText(event) {
    if (!this.hasBillingInvoicesIdsTarget) return;

    const { target } = event;

    if (target.type === 'radio') this.billingInvoicesIds.clear()

    const resource = this.getResourceId(target);
    const resourceType = resource.elementType;
    const resourceId = resource.elementId;

    if (!resourceId) return;

    if (resourceType === 'installment') {
      this.checkRootInvoice(target);
    } else {
      this.checkInvoiceInstallments(target, resourceId);
    }

    this.updateAmountToPay();
    this.updateTransferDescriptionElements();
    this.disableSubmitIfNecessary();
  }

  invoiceComment() {
    return 'Factura: ' + Array.from(this.irsBillIds).join(', ') + ' - ComunidadFeliz';
  }

  getResourceId(invoice) {
    const invoiceAlphaId = invoice.getAttribute('id');
    if (invoiceAlphaId) {
      const [ _, elementType, elementId ] = invoiceAlphaId.match(/^([a-zA-Z]+)(\d+)$/);
      return { elementType, elementId }

    } else {
      return null;
    }
  }

  updateTransferDescriptionElements() {
    const selectedInvoicesArray = Array.from(this.billingInvoicesIds)
    const selectedInstallmentsArray = Array.from(this.billingInstallmentsIds);
    const selectedIrsdArray = Array.from(this.irsBillIds);
    this.invoiceDescriptionTextTarget.value = this.invoiceComment();
    this.billingInvoicesIdsTarget.innerText = selectedIrsdArray.join(', ');
    this.billableIdsTarget.value = selectedInvoicesArray;
    this.billableInstallmentsIdsTarget.value = selectedInstallmentsArray;
  }

  updatePaymentMethodSubmitUrl(event) {
    const { target: { value } } = event;
    const { url, method } = this.paymentEndpoint(value) ;
    this.submitTarget.setAttribute('formaction', url);
    this.submitTarget.setAttribute('formmethod', method);

    this.disableSubmitIfNecessary();
  }

  private

  checkRootInvoice(installment) {
    // If one of the child installments is selected then check the root invoice
    const selectedChildrenInstallments = this.invoiceTargets.filter((invoice) => {
      let { elementType, elementId } = this.getResourceId(invoice);
      const isChildren =  invoice.getAttribute('invoice_id') === installment.getAttribute('invoice_id');
      return invoice.checked && elementType === 'installment' && isChildren;
    });

    const rootInvoice = this.invoiceTargets
                              .find(invoice => invoice.getAttribute('id') === `invoice${installment.getAttribute('invoice_id')}`);

    if (selectedChildrenInstallments.length > 0) {
      rootInvoice.checked = true;
    } else {
      rootInvoice.checked = false;
    }
  }
  checkInvoiceInstallments(invoice, invoiceId)  {
    const allInvoiceInstallments = this.invoiceTargets
                                        .filter(invoiceElement => invoiceElement.getAttribute('invoice_id') === invoiceId);
    let allSelectableInstallments = allInvoiceInstallments.filter(installment => installment.getAttribute('invoice_installment') == 'true')

    if (invoice.checked) {
      // Select the first available installment
      allSelectableInstallments
        .forEach((invoiceElement) => {
          invoiceElement.checked = invoice.checked;
        });
    } else {
      // Unselect all the invoice's installments
      allSelectableInstallments
        .forEach((invoiceElement) => {
          invoiceElement.checked = invoice.checked;
        })
    }
  }

  checkAllInvoicesByDefault(accountId) {
    this.invoiceTargets.filter((invoice) => {
                        const { elementType }  = this.getResourceId(invoice);
                        return invoice.getAttribute('account_id') === accountId && elementType === 'invoice';
                      }).forEach(invoice => invoice.click())
  }

  paymentEndpoint(gatewayName) {
    const url = gatewayName === 'transference' ?  this.transferenceUrlValue : this.onlinePaymentUrlValue;
    const method = gatewayName === 'transference' ? 'POST' : 'GET'
    return { url, method }
  }

  disableSubmitIfNecessary() {
    const selectedInvoices = this.invoiceTargets.filter(invoice => invoice.checked).length;
    const selectedGateways = this.gatewayTargets.filter(gateway => gateway.checked).length;

    if (selectedInvoices > 0) {
      this.paymentSectionTarget.classList.remove('hidden');
    } else {

      this.paymentSectionTarget.classList.add('hidden');
    }

    if (selectedInvoices === 0 || selectedGateways === 0) {
      this.submitTarget.setAttribute('disabled', true);
    } else {
      this.submitTarget.removeAttribute('disabled');
    }
  }

  updateAmountToPay() {
    this.total = 0;

    this.invoiceTargets.forEach((invoice) => {
      const { elementId, elementType } = this.getResourceId(invoice);


      const invoiceWithoutInstallments = elementType === 'invoice' && !(invoice.getAttribute('has_installments') === "true");

      if (invoice.checked) {
        if (invoiceWithoutInstallments || elementType === 'installment') {
          this.total += parseFloat(invoice.getAttribute('invoice-price'));
        }
      }

      this.updateResourceIds(invoice, invoice.checked);
      this.updateIrsBillIds(invoice, invoice.checked);
    })

    this.total = Math.round(this.total * 100) / 100;

    AutoNumeric.getAutoNumericElement(this.invoicePaymentPriceTarget).set(this.total);
    AutoNumeric.getAutoNumericElement(this.totalPriceTarget).set(this.total);
  }

  updateResourceIds(resource, checked) {
    const resourceIdsList = {
      invoice: this.billingInvoicesIds,
      installment: this.billingInstallmentsIds
    }
    const { elementId, elementType } = this.getResourceId(resource);

    // Per definition, the last installment is always the invoice with the remaining amount after installments's amount
    // The attribute 'invoice_as_installment' helps to identify all this cases
    const invoiceAsInstallment = resource.getAttribute('invoice_as_installment') === 'true';

    const resourceType = invoiceAsInstallment ? 'invoice' : elementType;
    if (checked) {
      resourceIdsList[resourceType].add(elementId);
    } else {
      resourceIdsList[resourceType].delete(elementId);
    }
  }

  updateIrsBillIds(resource, checked) {
    const { elementType } = this.getResourceId(resource);
    if (elementType !== 'invoice') return;

    const elementId = resource.getAttribute('irs_bill_id');
    if (checked) {
      this.irsBillIds.add(elementId)
    } else {
      this.irsBillIds.delete(elementId)
    }
  }

  resetCheckedOptions() {
    this.invoiceTargets.forEach(invoice => invoice.checked = false);
    this.gatewayTargets.forEach(gateway => gateway.checked = false);
  }
}
