import { Controller } from 'stimulus'

const placeholderCoordinates = { lat: -33.4171203, lng: -70.5991394 } // We use ComunidadFeliz legal address as fallback

export default class extends Controller {
  static values = {
    communityCountryCode: String,
    mapId: String
  }

  static targets = ['address', 'administrativeAreaLevel1', 'country', 'countryCode',
                    'locality','latitude', 'longitude', 'map', 'submitButton']

  async connect() {
    if (window.GoogleMapsLoader) {
      await this.initializeAll()
    }
  }

  verifyValidAddress() {
    const valid = this.addressTarget.value && this.administrativeAreaLevel1Target.value && this.localityTarget.value &&
                  this.latitudeTarget.value && this.longitudeTarget.value && this.countryTarget.value && this.countryCodeTarget.value &&
                  this.countryCodeTarget.value === this.communityCountryCodeValue

    valid ? this.submitButtonTarget.removeAttribute('disabled') : this.submitButtonTarget.setAttribute('disabled', true)
  }

  async initializeAll() {
    if (this.alreadyLoading) return

    this.alreadyLoading = true
    await this.importMapsLibraries()
    this.verifyValidAddress()
    const coordinates = this.initializeCoordinates()
    this.initializeAutocomplete()
    this.initializeGeocoder()
    this.initializeMap(coordinates)
    this.initializeMarker(coordinates)
  }

  // Initializers

  async importMapsLibraries() {
    const loader = window.GoogleMapsLoader
    const { AdvancedMarkerElement } = await loader.importLibrary('marker')
    const { Autocomplete } = await loader.importLibrary('places')
    const { Geocoder } = await loader.importLibrary('geocoding')
    const { Map } = await loader.importLibrary('maps')

    this.AdvancedMarkerElement = AdvancedMarkerElement
    this.Autocomplete = Autocomplete
    this.Geocoder = Geocoder
    this.Map = Map
  }

  initializeCoordinates() {
    const defined = this.coordinatesDefined()
    if (!defined) this.addressTarget.value = '';
    return defined ? { lat: parseFloat(this.latitudeTarget.value), lng: parseFloat(this.longitudeTarget.value) } : placeholderCoordinates
  }

  initializeAutocomplete() {
    this.autocomplete = new this.Autocomplete(this.addressTarget,  { types: ['geocode'], componentRestrictions: { country: this.communityCountryCodeValue } })
    this.autocomplete.setFields(['address_component', 'geometry'])
    this.autocomplete.addListener('place_changed', this.syncMarkerWithForm.bind(this))
  }

  initializeGeocoder() {
    this.geocoder = new this.Geocoder();
  }

  initializeMap(coordinates) {
    const mapOptions = {
      center: coordinates,
      zoom: 16,
      streetViewControl: false,
      fullscreenControl: false,
      mapId: this.mapIdValue
    }
    this.map = new this.Map(this.mapTarget, mapOptions)
  }

  initializeMarker(coordinates) {
    this.draggableMarker = new this.AdvancedMarkerElement({
      position: coordinates,
      map: this.coordinatesDefined() ? this.map : null,
      gmpDraggable: true
    })

    this.draggableMarker.addListener('dragend', this.syncFormWithMarker.bind(this))
  }

  // Event handlers

  syncMarkerWithForm() {
    const place = this.autocomplete.getPlace()
    this.resetAddressForm()
    if (typeof place.address_components === 'undefined') {
      this.addressTarget.value = ''
      this.verifyValidAddress()
      this.draggableMarker.setMap(null)
      return
    }
    this.updateAddressForm(place)
    this.draggableMarker.position = place.geometry.location
    if (this.draggableMarker.map === null) this.draggableMarker.setMap(this.map)
    this.map.setCenter(place.geometry.location)
    this.verifyValidAddress()
  }
  
  async syncFormWithMarker() {
    const position = this.draggableMarker.position;
    const geocoderResponse = await this.geocoder.geocode({ location: position })
    this.resetAddressForm()

    if (geocoderResponse.results[0] !== null) {
      this.latitudeTarget.value = position.lat
      this.longitudeTarget.value = position.lng
      const address = geocoderResponse.results[0].formatted_address
      this.addressTarget.value = address
      this.updateAddressForm(geocoderResponse.results[0], false)
    }

    this.verifyValidAddress()
  }

  // Updaters

  resetAddressForm() {
    this.administrativeAreaLevel1Target.value = ''
    this.countryTarget.value = ''
    this.countryCodeTarget.value = ''
    this.localityTarget.value = ''
    this.latitudeTarget.value = ''
    this.longitudeTarget.value = ''
  }

  updateAddressForm(place, updateLatLng = true) {
    place.address_components.forEach((component) => { this.updateAddressFormFieldWithComponent(component) })
    if (updateLatLng) {
      this.latitudeTarget.value = place.geometry.location.lat()
      this.longitudeTarget.value = place.geometry.location.lng()
    }
  }

  updateAddressFormFieldWithComponent(component) {
    switch (component.types[0]) { 
      case this.countryTarget.dataset.addressComponent:
        this.countryTarget.value = component['long_name']
        this.countryCodeTarget.value = component['short_name']
        break
      case this.administrativeAreaLevel1Target.dataset.addressComponent:
        this.administrativeAreaLevel1Target.value = component['long_name']
        break
      case this.localityTarget.dataset.addressComponent:
        this.localityTarget.value = component['long_name']
        break
      default:
        return
    }
  }

  // Auxiliary functions

  coordinatesDefined() {
    return this.latitudeTarget.value && this.longitudeTarget.value
  }
}
