import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "warning", "submitButton"]

  initialize() {
    this.initialized = false
    this.initialValidation()
  }

  initialValidation() {
    this.checkButtonState()
  }

  activateValidation() {
    if (!this.initialized) {
      this.initialized = true
      this.checkFields()
    }
  }

  checkFields() {
    let allFilled = true

    this.inputTargets.forEach(input => {
      if (input.value.trim() === '') {
        this.markInvalid(input)
        allFilled = false
      } else {
        this.markValid(input)
      }
    })

    if (allFilled) {
      this.hideWarning()
    } else {
      this.showWarning()
    }

    this.checkButtonState()
  }

  checkButtonState() {
    let anyEmpty = this.inputTargets.some(input => input.value.trim() === '')

    if (anyEmpty) {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
    } else {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
    }
  }

  markInvalid(input) {
    if (this.initialized) {
      input.classList.add('invalid_input')
    }
  }

  markValid(input) {
    if (this.initialized) {
      input.classList.remove('invalid_input')
    }
  }

  showWarning() {
    if (this.initialized) {
      this.warningTarget.style.display = 'block'
    }
  }

  hideWarning() {
    if (this.initialized) {
      this.warningTarget.style.display = 'none'
    }
  }

  input(event) {
    this.activateValidation()
    this.checkFields()
  }
}
