import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        width: "50em",
        justifyContent:"center",
        padding: "3% 2em",
        margin: "auto",
        [theme.breakpoints.down("md")]: {
            width: "35em",
            padding: "2% 3em",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "4em 3em",
        },
    },
    formContainer: {
        maxWidth: "30em !important",
    },
    MediaLogo: {
        width: "60%",
        marginBottom: "4em"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },
    recoveryPassword: {
        display: "block",
        margin: "1.8em 0 2.3em 0 !important",
        textAlign: "right"
    },
    public_btn: {
        height: "3em",
        display: "flex",
        justifyContent: "center",
        color: `${theme.palette.public.public_btn_color} !important`,
        width: "100%",
        fontStyle: "normal",
        padding: 0,
        marginBottom: "2em !important",
        fontStretch: "normal",
        letterSpacing: "normal",
        background: `${theme.palette.public.public_btn_bg} !important`,
        borderRadius: "5px",
        textTransform: "none !important",
        "&:hover": {
            background: `${theme.palette.public.public_btn_bg} !important`,
            boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
        },
        "&:disabled": {
            color: theme.palette.public.public_btn_disabled,
            opacity: 0.4,
        },
    },
    linkSignUp: {
        paddingTop: "3.5em",
        textAlign: "center"
    },
    iframe_container: {
        flexGrow: 1
      },
      iframe: {
        overflow: "hidden",
        width: "100%",
        height: "99vh",
        border: "none",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
        "-webkit-scrollbar": {
          "-webkit-appearance": "none",
        },
      },
}));

export default useStyles;