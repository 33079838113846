import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  activateTab(event) {
    this.menuTarget.querySelector('.active').classList.remove('active')
    event.currentTarget.classList.add('active')
  }
}
