import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    enabled: Number,
    propertyId: Number,
    inChargeId: Number,
    profileId: Number,
    suggestedAmount: Number
  }

  connect() {
    const formContainerId = `#form_container_edit_${this.propertyIdValue}`
    document.querySelector(`${formContainerId} #payment_property_id`).value = this.propertyIdValue
    document.querySelector(`${formContainerId} .date-selector`).setAttribute('target',`.pb-${this.propertyIdValue}`)
    document.querySelector(`${formContainerId} .date-selector`).setAttribute('property_id', this.propertyIdValue)
    document.querySelector(`${formContainerId} .cancel_button_inline_payment`).setAttribute('hide',`.manual_assignation${this.propertyIdValue}`)

    if (this.enabledValue > 0) {
      document.querySelector(`${formContainerId} .manual-assignation-btn`).setAttribute('id',`manual_assignation_btn${this.propertyIdValue}`)
      document.querySelector(`${formContainerId} .manual-assignation-btn`).setAttribute('target',`.manual_assignation${this.propertyIdValue}`)
    }

    document.querySelector(`${formContainerId}  #payment_price`).classList.add(`prop${this.propertyIdValue}`)
    document.querySelector(`${formContainerId} .submit-button`).setAttribute('data-missing-configurations', $(formContainerId).data(`missingConfigurations`))
    document.querySelector(`${formContainerId} .submit-button`).setAttribute('data-user-id', this.inChargeIdValue)
    if (this.profileIdValue > 0) {
      document.querySelector(`${formContainerId} .submit-button`).setAttribute('data-profile-id', this.profileIdValue)
    }
    document.querySelector(`${formContainerId} .submit-button`).setAttribute('id', `submit_button_${this.propertyIdValue}`)
    document.querySelector(`${formContainerId} #payment_general_public`).setAttribute('id', `payment_${this.propertyIdValue}_general_public`)
 }

  hide() {
    $(`.manual_assignation${this.propertyIdValue}`).hide()
  }
}
