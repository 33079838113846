import { useState, useRef } from "react";
import I18n from "i18n-js";
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PublicBtn from "../../components/PublicBtn";
import RouterLink from "../../components/RouterLink";
import { withRouter } from "react-router";
import EmailTextField from "../../components/EmailTextField";
import PasswordField from "../../components/PasswordField";
import { isEmailValid } from "../../utilities/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  divider: {
    margin: theme.spacing(2, 0),
    height: "0px",
  },
}));

function LoginHorizontalPage() {
  const classes = useStyles();
  const [currentEmail, setCurrentEmail] = useState("");
  const emailTextFieldRef = useRef();
  const passTextFieldRef = useRef();

  function onSubmitForm(event) {
    event.preventDefault();
    backdropRef.current.activate();

    let flagError = false;

    if (event.target.email.value === "") {
      emailTextFieldRef.current.validate(false, true);
      flagError = true;
    } else {
      emailTextFieldRef.current.validate(false, false);
    }

    if (flagError === false) {
      if (isEmailValid(event.target.email.value)) {
        emailTextFieldRef.current.validate(false, false);
      } else {
        emailTextFieldRef.current.validate(true, false);
        flagError = true;
      }
    }

    if (event.target.password.value === "" && event.target.email.value !== "") {
      flagError = true;
    }

    if (flagError) {
      backdropRef.current.deactivate();
      return false;
    }

    document.getElementById("login").submit();
  }

  function handlechange(e) {
    setCurrentEmail(e.target.value);
    emailTextFieldRef.current.validate(false, false);
  }

  return (
    <Container component="main">
      <form
        name="login"
        id="login"
        className={classes.form}
        method="post"
        action="/sessions"
        onSubmit={onSubmitForm}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <EmailTextField onChange={handlechange} ref={emailTextFieldRef} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordField ref={passTextFieldRef} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="space-between"
          spacing={2}
        >
          <Grid item container xs={12} md={6}>
            <Typography variant="body1">
              <RouterLink
                to={{
                  pathname: "/v2/password_recovery",
                  state: { email: currentEmail },
                }}
                label={I18n.t("modules.login.forgot_pass")}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <PublicBtn
              type="submit"
              label={I18n.t("modules.login.login_button")}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </form>
    </Container>
  );
}

export default withRouter(LoginHorizontalPage);
