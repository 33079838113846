import { Controller } from 'stimulus'
import I18n from 'i18n-js'
import { dateFormat } from 'highcharts'

export default class extends Controller {
  connect() {
    this.addLocale()
    $('.datetimepicker').datetimepicker('destroy').datetimepicker({format: this.formatDatePicker(), locale: I18n.locale})
  }

  formatDatePicker() {
    return I18n.t('date.formats.daterangepicker_long')
  }

  locale() {
    return I18n.locale
  }

  addLocale() {
    const community_language = document.querySelector('#community_language').value;
    if (community_language) {
      I18n.locale = community_language;
    }
  }
}
