import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
    this.checkboxTarget.classList.toggle('disabled');
  }

  filterPendings(e) {
    this.checkboxTarget.classList.toggle('disabled');
    const url = new URL(window.location.href);

    if (e.currentTarget.checked) {
      url.searchParams.set('show_pending', '1');
    } else {
      url.searchParams.delete('show_pending');
    }

    window.location.href = url.toString();
  }
}
