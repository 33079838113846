import { Controller } from 'stimulus';

// Connects to data-controller="toggle-button"
export default class extends Controller {
  static values = {
    toggleState: { type: Boolean, default: true },
    onIcon: { type: String, default: 'fa-toggle-on' },
    offIcon: { type: String, default: 'fa-toggle-off' },
    tooltipActivate: { type: String, default: null },
    tooltipDeactivate: { type: String, default: null },
    urlAction: { type: String, default: null },
    fetchOrReuseElement: { type: Boolean, default: false },
    updateElementsOnToggle: { type: Array, default: [] }
  }

  static targets = ['icon']

  connect() {
    this.updateButton()
  }

  toggle() {
    this.setAction()
  }

  updateButton() {
    const iconClass = this.toggleStateValue ? this.onIconValue : this.offIconValue
    const toolTipText = this.toggleStateValue ? this.tooltipDeactivateValue : this.tooltipActivateValue
    this.iconTarget.className = `fa ${iconClass}`

    this.element.removeAttribute('title');
    this.element.removeAttribute('data-original-title')
    if (toolTipText) {
      this.element.setAttribute('title', toolTipText);
    }
    $(this.element).tooltip('fixTitle');
  }

  setAction() {
    this.element.disabled = true
    const url = this.buildUrl();
    const newToggleState = !this.toggleStateValue
    const fetchOrReuseElementValue = this.fetchOrReuseElementValue
    if (fetchOrReuseElementValue) {
      const primaryElement = document.querySelector('#toggle-primary-element')
      const secondaryElement = document.querySelector('#toggle-secondary-element')
      if (newToggleState) {
        if (!secondaryElement) {
          this.doTurboRequest(url, this.deactivatePrimaryElement)
          return
        } else {
          secondaryElement.style.display = 'block'
          this.deactivatePrimaryElement()
        }
      } else{
        if (!primaryElement) {
          this.doTurboRequest(url, this.deactivateSecondaryElement)
          return
        } else {
          primaryElement.style.display = 'block'
          this.deactivateSecondaryElement()
        }
      }
      this.toggleStateValue = !this.toggleStateValue
      this.updateButton()
      this.element.disabled = false
      this.updateSpecifiedElements();
    } else {
      this.doTurboRequest(url)
    }    
  }

  buildUrl() {
    const url = new URL(this.urlActionValue, window.location.origin);
    url.searchParams.set('toggle_state', !this.toggleStateValue);
    url.searchParams.set('fetch_or_reuse_element', this.fetchOrReuseElementValue);
    return url;
  }

  deactivatePrimaryElement() {
    document.querySelector('#toggle-primary-element').style.display = 'none'
  }

  deactivateSecondaryElement() {
    document.querySelector('#toggle-secondary-element').style.display = 'none'
  }

  doTurboRequest(url, callback) {
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then((html) => {
      Turbo.renderStreamMessage(html)
      this.toggleStateValue = !this.toggleStateValue
      this.updateButton()
      this.element.disabled = false
      this.updateSpecifiedElements();
      if (typeof callback === 'function') {
        callback()
      }
    }).catch((error) => {
      this.element.disabled = false
    })
  }

  updateSpecifiedElements() {
    const toggleState = this.toggleStateValue;
    this.updateElementsOnToggleValue.forEach(elementInfo => {
      const element = document.getElementById(elementInfo.id);
      if (element) {
        if (elementInfo.element === 'href') {
          if (elementInfo.type === 'replace') {
            const url = new URL(element.href);
            url.searchParams.set(elementInfo.param, toggleState);
            element.href = url.toString();
          }
        }
      }
    });
  }
}
