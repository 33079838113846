import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = {
  decimalCharacter: '.',
  decimalPlaces: 5,
  minimumValue: 0,
  decimalCharacterAlternative: ',',
  allowDecimalPadding: false,
  emptyInputBehavior: 'zero',
  unformatOnSubmit: true
}

export default class extends Controller {
  static targets = ['hoursInput']

  hoursInputTargetConnected(element) {
    new AutoNumeric(element, AUTONUMERIC_FORMAT)
  }
}
