import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "field", "actionsContent", "buttonsContent" ]

  active() {
    this._showButtons()
  }

  submit() {
    this._showActions()
    this._updateTargetsDefaultValues()
  }

  reset() {
    this._showActions()
    this._resetTargetsDefaultValues()
  }

  _resetTargetsDefaultValues() {
    this.formTarget.reset()
  }

  _updateTargetsDefaultValues() {
    this.fieldTargets.forEach((field) => {
      switch(field.tagName) {
        case "SELECT":
          field.options[field.options.selectedIndex].defaultSelected = true
          break
        case "INPUT", "TEXTAREA":
          field.defaultValue = field.value
        default:
          break
      }
    })
  }

  _showButtons() {
    this.actionsContentTarget.classList.remove("show")
    this.actionsContentTarget.classList.add("hide")
    this.buttonsContentTarget.classList.remove("hide")
    this.buttonsContentTarget.classList.add("show")
  }

  _showActions() {
    this.actionsContentTarget.classList.remove("hide")
    this.actionsContentTarget.classList.add("show")
    this.buttonsContentTarget.classList.remove("show")
    this.buttonsContentTarget.classList.add("hide")
  }
}
