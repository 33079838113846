import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['blockerOverlay', 'closeAlert'];

  async close(event) {
    event.preventDefault();
    this.blockerOverlayTarget.style.display = 'none';
    this.closeAlertTarget.style.display = 'none';

    await fetch('/hide_block_alert', { method: 'POST'});
  }
}
