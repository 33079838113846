import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    action: String,
    body: String,
    path: String,
    mixPanel: Object
  }

  showModal() {
    trackEvent(
      'PendingTasks',
      { action: this.mixPanelValue.modal }
    )
  }

  async submitButton() {
    trackEvent(
      'PendingTasks',
      { action: this.mixPanelValue.action }
    ).then(async () => {
      switch(this.actionValue) {
        case 'redirect':
          window.location = this.pathValue;
          break;
        case 'post':
          await fetch(this.pathValue, {
            method: 'POST',
            headers:  {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            body: this.bodyValue
          }).then(response => {
            if (response.redirected) {
              window.location.href = response.url
            }
          })
          break;
      }
    })
  }
}
