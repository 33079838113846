import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['name', 'basePrice', 'packageType', 'countryCode',
                    'updateCurrency', 'deleteModal', 'deleteButton']

  setCountryCurrency() {
    const path = Routes.search_form_currencies_admin_base_packages_path({
      name: this.nameTarget.value,
      base_price: this.basePriceTarget.value,
      package_type: this.packageTypeTarget.value,
      country_code: this.countryCodeTarget.value
    })

    this.updateCurrencyTarget.href = path
    this.updateCurrencyTarget.click()
  }

  showConfirmDeleteModal({ params: { id }}) {
    $(this.deleteModalTarget).modal()
    this.deleteButtonTarget.href = `${Routes.admin_base_packages_path()}/${id}`
  }

  hideConfirmDeleteModal() {
    $(this.deleteModalTarget).modal('hide')
  }
}
