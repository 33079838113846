import { Controller } from "stimulus"

export default class extends Controller {
  ValidBusinessName(event) {
    let input = event.target;
    let trim_business_name = input.value.trim();

    input.value = trim_business_name.toUpperCase();
  }
}
