import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element" ]

  call(event) {
    // Add data-cases = [truthy cases for hide] to dom element with 'action'
    // example: data: { action: 'change->hide#call', cases: [0, 1] }
    // means if value is 0 or 1, the target 'element' will hide, will show if not.

    if (event.currentTarget.dataset.cases.includes(event.currentTarget.value)) {
      this.elementTargets.forEach(target => this.toggleCollapse(target))
    } else {
      this.elementTargets.forEach(target => target.classList.remove('collapse'))
    }
  }

  toggleCollapse(target) {
    if (!target.classList.contains('collapse')) { target.classList.add('collapse') }
  }

  targetId(event) {
    // We only need to add into the dataset of the trigger a target_id
    // which will be then looked for and toggle the 'collapse' class in it
    // example: data: { action: 'change->hide#targetId', target_id: "id of the element we want to hide/show" }

    let targetElement = document.getElementById(event.currentTarget.dataset.targetId)
    if(targetElement){
      if(event.currentTarget.dataset.cases){
        this.targetIdWithCases(event, targetElement)
      } else{
        targetElement.classList.toggle("collapse")
      }
    }
  }

  targetIdWithCases(event, target) {
    if(event.currentTarget.dataset.cases.includes(event.currentTarget.value)) {
      if (!target.classList.contains('collapse')) { target.classList.add('collapse') }
    } else {
      target.classList.remove('collapse')
    }
  }
}
