// app/javascript/controllers/auth_controller.js
import { Controller } from "stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["form", "codeField"]
  static values = { automatic: Boolean }
  connect(event){
    if (this.automaticValue === "true" ) {this.authUser(event) }
  }

  authUser(event) {
    event.preventDefault();
    
    fetch(event.currentTarget.dataset.path, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      const url = data.url;
      const path = url.split("?")[0];
      const code = url.split("=")[1];

      this.formTarget.action = path;
      this.codeFieldTarget.value = code;

      this.formTarget.submit();
    })
    .catch(error => {
      triggerAlert(I18n.t('errors.commons.request_error'));
      allow_multiple_alerts();
    });
  }
}
