import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textArea'];

  toggleTextArea(event) {
    if (event.target.value == 'custom')
      this.textAreaTarget.disabled = false;
    else
      this.textAreaTarget.disabled = true;
  }
};
