import { Controller } from "stimulus"
import AutoNumeric from "autonumeric"
import I18n from "i18n-js";
import { validateDate } from '../date_validations_controller';

export default class extends Controller {
  static values = { lastSalaryPayment: Number, workedDays: Number, discountedDays: Number }
  static targets = [ 'form', 'daysInput', 'startDateInput',
                     'endDateInput', 'lastSalaryInput', 'addNewTooltip' ]

  initialize() {
    this.submittingForm = false;
  }

  formTargetConnected() {
    this.submittingForm = false;
  }

  submit(){
    if (this.submittingForm) return false

    let lastSalaryValue = AutoNumeric.getAutoNumericElement(this.lastSalaryInputTarget).getNumber()
    this.validnewDate();

    if (this.daysInputTarget.value != '' && lastSalaryValue > 0 && this.validateLicenseDays()
        && this.startDateInputTarget.value != '' && this.endDateInputTarget.value != '' && validateDate(event, this)) {

      this.submittingForm = true
      this.formTarget.requestSubmit()
    }
  }

  validnewDate() {
    if (this.startDateInputTarget.value != '' && this.endDateInputTarget.value != '') {
      validateDate(event, this)
    }
  }

  setLastSalary() {
    if (this.lastSalaryInputTarget.value == '' && this.lastSalaryPaymentValue > 0) {
      AutoNumeric.getAutoNumericElement(this.lastSalaryInputTarget).set(this.lastSalaryPaymentValue)
      this.enableSubmit()
    }
  }

  validateLicenseDays() {
    if (parseInt(this.daysInputTarget.value) + this.discountedDaysValue > this.workedDaysValue) {
      this.daysInputTarget.classList.add('invalid_input')
      this.daysInputTarget.setAttribute('data-toggle', 'tooltip')
      this.daysInputTarget.setAttribute('data-title', I18n.t('views.remunerations.salary_payment_drafts.worked_days_validation_warning'))
      $('[data-toggle="tooltip"]').tooltip();

      return false
    }

    this.daysInputTarget.classList.remove('invalid_input')
    this.daysInputTarget.setAttribute('data-title', '')
    $('[data-toggle="tooltip"]').tooltip();
    return true
  }

  enableSubmit() {
    if (this.daysInputTarget.value != '' && this.lastSalaryInputTarget.value != ''
        && this.startDateInputTarget.value != '' && this.endDateInputTarget.value != ''
        && this.validateLicenseDays() && validateDate(event, this) ) {

      let disabledButton = document.getElementById('salary-payment-disabled-button')
      let enabledButton = document.getElementById('salary-payment-enabled-button')

      enabledButton.classList.remove('hidden')
      disabledButton.classList.add('hidden')
    }
  }
}
