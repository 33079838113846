import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { fiscalIdentificationOptions: Object }

  static targets = [ 'selector', 'tooltip' ]

  call(event) {
    let fiscalRegime = event.target.value
    if ( fiscalRegime != '' ) {
      this.enableInput()
      let options = this.buildSelectorOptions( fiscalRegime )
      this.setSelectorOptions( options )
      if (this.hasTooltipTarget) { this.disableTooltip() }
      this.setSelectorRequired()
    } else {
      if (this.hasTooltipTarget) { this.enableTooltip() }
      this.disableInput()
      this.clearSelectorOptions(true)
      this.removeSelectorRequired()
    }
  }

  buildSelectorOptions(value) {
    const fiscal_identification_options = this.fiscalIdentificationOptionsValue
    const options = fiscal_identification_options.cfdi_values[value]
    const optionTexts = fiscal_identification_options.cfdi_labels

    return options.map((option) => { return `<option value="${option}">${option} - ${optionTexts[option]}</option>` })
  }

  disableTooltip(){
    this.tooltipTarget.style="display:none !important;"
  }

  enableTooltip(){
    this.tooltipTarget.style="display: inline-block !important;"
  }

  enableInput(){
    this.selectorTarget.removeAttribute('disabled')
    this.selectorTarget.removeAttribute('readOnly')
  }

  disableInput(){
    this.selectorTarget.setAttribute('disabled', true)
  }

  setSelectorOptions(options){
    let selector = this.selectorTarget
    this.clearSelectorOptions()
    options.forEach((option) => {
      selector.innerHTML += option
    })
  }

  clearSelectorOptions(disabled = false){
    const fiscal_identification_options = this.fiscalIdentificationOptionsValue
    const default_option = fiscal_identification_options.default_option
    const disabled_option = fiscal_identification_options.disabled_option

    let defaultOptionText = disabled ? disabled_option : default_option
    this.selectorTarget.innerHTML = default_option ? `<option value=''>${defaultOptionText}</option>` : ''
  }

  setSelectorRequired() {
    this.selectorTarget.setAttribute('required', true)
  }

  removeSelectorRequired() {
    this.selectorTarget.removeAttribute('required')
  }
}