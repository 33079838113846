import { Controller } from "stimulus"
import AutoNumeric from "autonumeric"

export default class extends Controller {
  static targets = [ 'form', 'price', 'comment', 'paidAt', 'recurrent',
                    'addNewButton', 'editButton', 'advanceTitle', 'advanceFormButtons',
                    'row', 'chevron', 'advanceStartDate', 'resetButton' ]

  static values = { massiveCreationAdvancesInProcess: Boolean, editableAdvance: Boolean,
                    draftCount: Number, advanceCount: Number, newDraft: Boolean }

  initialize() {
    this.submittingForm = false
  }

  submit() {
    if (this.submittingForm) return false

    let priceValue = AutoNumeric.getAutoNumericElement(this.priceTarget).getNumber()
    if (this.priceTarget.value != ''  && priceValue > 0 ) {
      if (this.newDraftValue) this.addDraftCount()
      this.enableSubmit()
      this.submittingForm = true
      this.formTarget.requestSubmit()
    }
  }

  addDraftCount() {
    let draftCount = document.getElementById('draft-count-value')

    if (this.priceTarget.defaultValue == '' && this.priceTarget.value != '') {
      draftCount.innerHTML = parseInt(draftCount.innerHTML) + 1
    }

    this.enableBulkResetButton()
  }

  enableSubmit() {
    let draftCount = parseInt(document.getElementById('draft-count-value').innerHTML)
    let button = document.getElementById('submit-button')

    if (draftCount > 0 && !this.massiveCreationAdvancesInProcessValue) button.classList.remove('disabled')
  }

  showAddNewButton() {
    this.addNewButtonTarget.classList.add('hidden')
    let id = this.formTarget.id.split('_').pop()
    document.getElementById(`new_advance_draft_${id}`).classList.remove('hidden')
  }

  showAdvanceForm() {
    this.advanceTitleTarget.classList.remove('hidden')
    this.rowTarget.classList.add('draft-inline-form')
    this.editButtonTarget.classList.add('hidden')
    this.advanceFormButtonsTarget.classList.remove('hidden')
    if (this.chevronTargets.length > 0) this.chevronTarget.classList.add('hidden')
    if (this.resetButtonTargets.length > 0) this.resetButtonTarget.classList.add('hidden')
    if (this.addNewButtonTargets.length > 0) this.addNewButtonTarget.classList.add('hidden')

    this.enableFields()
  }

  enableFields() {
    if (this.editableAdvanceValue) {
      this.priceTarget.disabled = false
    } else {
      this.priceTarget.setAttribute('data-toggle', 'tooltip')
      $("[data-toggle='tooltip']").tooltip();
    }
    this.commentTarget.disabled = false
    this.paidAtTarget.parentElement.classList.add('hidden')
    this.advanceStartDateTarget.classList.remove('hidden')
    this.recurrentTarget.disabled = false
    this.recurrentTarget.parentElement.querySelector('input[type=hidden]').removeAttribute('disabled')
  }

  closeResetModal() {
    document.body.classList.remove('modal-open')
    document.querySelector('.modal-backdrop').remove()
    let modalDraftCount = document.getElementById('draft-count-value')

    modalDraftCount.innerHTML = parseInt(modalDraftCount.innerHTML) - 1
    if ( modalDraftCount.innerHTML == '0') document.getElementById('submit-button').classList.add('disabled')

    let resettableAdvancesCount = document.getElementById('resettable-advances-count')
    resettableAdvancesCount.value = parseInt(resettableAdvancesCount.value) - this.advanceCountValue

    this.enableBulkResetButton()
  }

  removeDraftCount(event) {
    let draftCount = document.getElementById('draft-count-value')

    if (event.currentTarget.dataset.lastDraft == 'true') draftCount.innerHTML = parseInt(draftCount.innerHTML) - 1
    if (draftCount.innerHTML == '0') document.getElementById('submit-button').classList.add('disabled')
    this.enableBulkResetButton()
  }

  enableBulkResetButton() {
    let draftCount = parseInt(document.getElementById('draft-count-value').innerHTML)
    let resettableAdvancesCount = parseInt(document.getElementById('resettable-advances-count').value)

    if (draftCount + resettableAdvancesCount > 0) document.getElementById('bulk-reset-button').classList.remove('disabled')
    else document.getElementById('bulk-reset-button').classList.add('disabled')

  }
}
