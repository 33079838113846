import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal', 'template', 'linkTemplate']

  connect() {
  }

  toggleModal(event) {
    event.preventDefault();
    const button = event.currentTarget
    let formId = button.dataset.formId
    if (this.hasTemplateTarget) {
      this.buildModalFromTemplate(button)
    }
    this.execConfirm(button, formId)
  }

  execConfirm(target, formId) {
    if (!target.dataset.modalConfirmlink) {
      let btn = this.modalTarget.querySelector('.confirm-btn')
      btn.dataset.form = `#index-inline-form-payment-${formId}`
    }
    $(this.modalTarget).modal()
  }

  submitForm(event) {
    $(event.currentTarget.dataset.form).submit();
    $(event.currentTarget).closest('.modal-dialog').remove();
    $(this.modalTarget).modal('hide');
  }

  buildModalFromTemplate(button) {
    let modal = this.templateTarget.innerHTML
    let link = null

    if (button.dataset.modalConfirmlink) {
      link = this.replaceRegex(this.linkTemplateTarget.innerHTML, {
        CONFIRM_URL: button.dataset.modalConfirmlink,
        CONFIRM: button.dataset.modalConfirm
      })
    } else {
      link = document.createElement('div')
      link.classList.add('btn', 'btn-success', 'confirm-btn')
      link.dataset.action = 'click->confirm-modal#submitForm'
      const text = document.createTextNode(button.dataset.modalConfirm);
      link.appendChild(text);
      link = link.outerHTML
    }
    let replacements = {
      TITLE: button.dataset.modalTitle,
      MESSAGE: button.dataset.modalMessage,
      CONFIRM: link,
      CANCEL: button.dataset.modalCancel
    }
    modal = this.replaceRegex(modal, replacements)
    this.modalTarget.insertAdjacentHTML('afterbegin', modal)
  }

  replaceRegex(modal, replacements) {
    let regex = new RegExp(Object.keys(replacements).join('|'), 'g')
    modal = modal.replace(regex, function(key){
      return replacements[key];
    });
    return modal
  }

  cancelModal(event) {
    $(event.currentTarget).closest('.modal-dialog').remove()
  }

  disableConfirmBtn(event){
    event.currentTarget.classList.add('disabled')
  }
}
