import { Controller } from "stimulus"
import moment from 'moment';

export default class extends Controller {

  //  MUST:
  //    - Add controller that engulfs needed datetimepicker inputs
  //
  //    - Add targets = datetimepicker
  //
  //    - Specify options applied to all datepickers after data-controller
  //      Specify options applied to specific datetimepickers after each target.
  //        Example: data: { controller: 'datetimepicker', options: {
  //                                                                   locale: 'es',
  //                                                                   format: I18n.t('date.formats.daterangepicker'),
  //                                                                   allowInputToggle: true
  //                                                                 }.to_json }
  //
  //  OPTIONAL:
  //    - If you want a second calendar to reflect a first calendar choice you must:
  //        - Add minDate target with data-bind-id set to a number, Ej: 1.
  //        - Add maxDate target with data-bind-with-id set to 1.
  //        maxDate target will reflect the choice from minDate target.

  static targets = [ 'datetimepicker', 'minDate', 'maxDate' ]

  connect() {
    this.init_calendars();
  }

  init_calendars() {

    const dateTimePickers = this.datetimepickerTargets
    let globalOptions = JSON.parse(this.element.dataset.options)

    if (this.element.dataset.parsedate){
      if (globalOptions.maxDate) {
        globalOptions.maxDate = this.parseRubyDateToJS(globalOptions.maxDate, globalOptions.format);
      }
      if (globalOptions.minDate) {
        globalOptions.minDate = this.parseRubyDateToJS(globalOptions.minDate, globalOptions.format);
      }
    }

    dateTimePickers.forEach((calendar) => {
      let localOptions = { ... globalOptions }
      if (calendar.dataset.options) {
        Object.assign(localOptions, JSON.parse(calendar.dataset.options))
      }

      $(calendar).datetimepicker(localOptions).on('dp.show dp.update', () => {
        let tooltip = this.element.dataset.tooltip;
        if (tooltip){
          this.addToolTip(tooltip);
        }
      });
    });

    this.maxDateTargets.forEach(maxDate => {
      let minDate = this.minDateTargets.find((minDate) =>
        minDate.dataset.bindId == maxDate.dataset.bindWithId
      )

      if (this.element.dataset.setmindate) {
        $(maxDate).data("DateTimePicker").minDate(globalOptions.minDate);
      }

      this.bindDates(minDate, maxDate)
    });
  }

  bindDates(minDate, maxDate) {
    $(minDate).on("dp.change", function (e) {
      $(maxDate).data("DateTimePicker").minDate(e.date);
      $(maxDate).data("DateTimePicker").date(null);
    });
  }

  addToolTip(tooltip) {
    document.querySelectorAll('td.day.disabled').forEach((element)  => {
      element.setAttribute('title', tooltip)

      element.dataset.container = 'body';
      $(element).tooltip()
    });
  }

  parseRubyDateToJS(rubyDate, format) {
    if (format) {
      return moment(rubyDate, format);
    }

    return moment(rubyDate);
  }
}
