import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Grid } from "@material-ui/core";
import I18n from "i18n-js";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    width: "100%",
  },
  mediaButton: {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: "20% 40% 20%",
    fontWeight: "400",
    alignItems: "center",
    color: '#000',
    width: "100%",
    height: "3em",
    background: "#fff",
    textTransform: "none",
    padding: 0,
    boxShadow: "0px 2px 6px rgba(91, 110, 130, 0.22)",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "0px 2px 10px rgba(91, 110, 130, 0.22)",
    },

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "10% 70% 0%",
    },

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "10% 70% 0%",
    },
  },
  MediaLogo: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: "0px",
  },
}));

function SocialSignupButtons() {
  const classes= useStyles();

  return (
    <Grid className={classes.buttonsContainer}>
      {/*<div
        className={classes.mediaButton}
        onClick={() => {
          window.top.location.href = "/oauth/facebook/init";
        }}
      >
        <Grid container justifyContent="center">
          <Link href="/oauth/facebook/init" className={classes.MediaLogo }>
              <img alt="Facebook" height="25" width="25" src="fb_icon.svg" ></img>
          </Link>
        </Grid>
        <Grid container justifyContent="center">
            {I18n.t("modules.login.facebook_login")}
        </Grid>
      </div>
      <Divider className={classes.divider} /> */}
      <div
        className={classes.mediaButton}
        onClick={() => {
          window.location.href = "/oauth/google/init";
        }}
      >
        <Grid  container justifyContent="center">
          <Link href="/oauth/google/init" className={classes.MediaLogo }>
            <img alt="Google" height="21" width="21" src="google_icon.svg" ></img>
          </Link>
        </Grid>
        <Grid container justifyContent="center">
          {I18n.t("modules.login.google_login")}
        </Grid>
      </div>
    </Grid>
  );
}

export default SocialSignupButtons;
