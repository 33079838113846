import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = {
  currencySymbol: '$',
  currencySymbolPlacement: 'p',
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalPlaces: 0,
  minimumValue: 1,
  maximumValue: 2147483647,
  overrideMinMaxLimits: 'invalid',
  unformatOnSubmit: true
}

export default class extends Controller {
  static targets = ['currencyInput']
  static values = {
    minMoney: Number
  }

  currencyInputTargetConnected(element) {
    if(this.hasMinMoneyValue) {
      AUTONUMERIC_FORMAT['minimumValue'] = this.minMoneyValue
    }
    new AutoNumeric(element, AUTONUMERIC_FORMAT)
  }
}
