import { Controller } from 'stimulus';
import AutoNumeric from 'autonumeric'
import I18n from 'i18n-js'

const AUTONUMERIC_FORMAT = { currencySymbol: '$', currencySymbolPlacement: 'p', digitGroupSeparator: '.', decimalCharacter: ',', decimalPlaces: 0, emptyInputBehavior: 'focus' }
const MIN_YEAR = 2024;
const MIN_MONTH = 6;


export default class extends Controller {
  static targets = ['debit', 'credit'];
  static values = {
    formType: { type: String, default: 'new_openings' },
    limitDate: String
  }

  connect() {
    this.limitDate = this.getDateAsUTC(this.limitDateValue)
  }

  disableOther(event) {
    let value = AutoNumeric.getAutoNumericElement(event.target).getNumber();
    if (value == 0) {
      this.debitTarget.value = ''
      this.debitTarget.disabled = false
      this.creditTarget.value = ''
      this.creditTarget.disabled = false
    }
    else {
      if (event.target == this.debitTarget) {
        AutoNumeric.getAutoNumericElement(this.creditTarget).set(0);
        this.creditTarget.value = ''
        this.creditTarget.disabled = true
      } else {
        AutoNumeric.getAutoNumericElement(this.debitTarget).set(0);
        this.debitTarget.value = ''
        this.debitTarget.disabled = true
      }
    }
    this.sumTotals()
  }

  sumTotals() {
    let allDebits = document.querySelectorAll('[id^="accounting_subaccounts_"][id$="_debit"]');
    let allCredits = document.querySelectorAll('[id^="accounting_subaccounts_"][id$="_credit"]');
    let numericTotalDebit = AutoNumeric.getAutoNumericElement(document.getElementById('totals-debit')) || new AutoNumeric(document.getElementById('totals-debit'), AUTONUMERIC_FORMAT);
    let numericTotalCredit = AutoNumeric.getAutoNumericElement(document.getElementById('totals-credit')) || new AutoNumeric(document.getElementById('totals-credit'), AUTONUMERIC_FORMAT);

    let saveButton = document.getElementById('save-new-opening-entries')
    let totalDebit = this.sumInputs(allDebits)
    let totalCredit = this.sumInputs(allCredits)

    numericTotalDebit.set(totalDebit);
    numericTotalCredit.set(totalCredit);

    if (this.checkSelectors() && (totalDebit == totalCredit) && (totalDebit > 0 && totalCredit > 0)) {
      saveButton.classList.remove('disabled')
      $('#save-container').tooltip('destroy')

    } else {
      saveButton.classList.add('disabled')
      if (this.formTypeValue == 'edit_openings') {
        $('#save-container').tooltip().attr('data-original-title', I18n.t('views.accounting_entry.new_openings.error_tooltip'))
      } else {
        $('#save-container').tooltip()
      }
    }
  }

  sumInputs(inputs) {
    let totals = 0;
    inputs.forEach(newEntryInput => {
      if (this.formType == 'new_openings') {
        totals += AutoNumeric.getAutoNumericElement(newEntryInput).getNumber();
      } else {
        let autoNumericElement = AutoNumeric.getAutoNumericElement(newEntryInput);
        let value = autoNumericElement ? autoNumericElement.getNumber() : 0;
        totals += value
      }
    })
    return totals
  }

  checkSelectors() {
    if (this.formTypeValue == 'edit_openings') return true

    let selectedDate = document.getElementById('opening-entries-transaction-date').value;

    if (this.getDateAsUTC(selectedDate) >= this.limitDate) {
      document.getElementById('warning-selector').style.display = 'none'
      return true
    } else {
      document.getElementById('warning-selector').style.display = 'block'
      return false
    }
  }

  getDateAsUTC(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    return Date.UTC(year, month - 1, day);
  }
}
