import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "error", "submitButton"];

  connect() {
    this.validate();
  }

  validate() {
    const fieldIsEmpty = this.fieldTarget.value === "";

    if (fieldIsEmpty) {
      this.showError();
      this.submitButtonTarget.disabled = true;
    } else {
      this.hideError();
      this.submitButtonTarget.disabled = false;
    }
  }

  showError() {
    this.errorTarget.style.display = 'inline';
  }

  hideError() {
    this.errorTarget.style.display = 'none';
  }

  captureBornAtDate(event) {
    let input = document.getElementById('employee_born_at');
    let eventInput = new Event('input',{
      'bubbles': true,
      'cancelable': true
    });

    input.dispatchEvent(eventInput);
  }
}
