import React, { Fragment, useEffect, useState } from "react";
import StepLayout from "../StepLayout";
import Grid from "@material-ui/core/Grid";
import I18n from "i18n-js";
import Paper from "@material-ui/core/Paper";
import PublicBtn from "../../../components/PublicBtn";
import TextField from "@material-ui/core/TextField";
import { ButtonBase, Link, Typography } from "@material-ui/core/";
import { Divider } from "semantic-ui-react";
import { setGlobalState, GLOBAL_STATE } from "../../../state";
import { useLazyQuery } from "@apollo/react-hooks";
import { ALL_COMMUNITIES, ALL_PROPERTIES } from "../../../api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WizardTitle from "../WizardTitle";
import useStyles from "./Steps.styles";
import SetImageQr from '../../../utilities/actions/setImageQr';

function PropertySignUpStepContent({ previous, next, backdrop }) {
  const [communityId, setCommunityId] = useState(null);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [loadingCommunityText, setLoadingCommunityText] = useState("");
  const [loadingPropertyText, setLoadingPropertyText] = useState("");
  const [openCommunity, setOpenCommunity] = React.useState(false);
  const [openProperty, setOpenProperty] = React.useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyOptions, setPropertyOptions] = React.useState([]);
  const classes = useStyles();
  const loadingCommunities = openCommunity && communityOptions.length === 0;
  const loadingProperties = openProperty && propertyOptions.length === 0;
  const [sendCommunityRequest, resultCommunity] = useLazyQuery(
    ALL_COMMUNITIES,
    {
      onCompleted: (data) => {
        if (data && data.communities && data.communities.length > 0) {
          setOpenCommunity(true);
          setCommunityOptions(
            Object.keys(data.communities).map((key) => data.communities[key])
          );
        } else {
          setLoadingCommunityText(I18n.t("modules.register.no_results"));
        }
      },
    }
  );
  const [sendPropertyRequest, resultProperty] = useLazyQuery(ALL_PROPERTIES, {
    onCompleted: (data) => {
      if (
        data &&
        data.communityProperties &&
        data.communityProperties.length > 0
      ) {
        setOpenProperty(true);
        setPropertyOptions(
          Object.keys(data.communityProperties).map(
            (key) => data.communityProperties[key]
          )
        );
      } else {
        setLoadingPropertyText(I18n.t("modules.register.no_results"));
        setOpenProperty(false);
      }
    },
  });

  function registerManualPage() {
    setGlobalState({
      communityId: communityId,
      propertyId: propertyId,
    });
    next({ choice: "manual" });
  }

  function registerQrPage() {
    next({ choice: "qr" });
  }

  useEffect(() => {
    if (!openCommunity) {
      setCommunityOptions([]);
    }
  }, [openCommunity]);

  useEffect(() => {
    if (!openProperty) {
      setPropertyOptions([]);
    }
  }, [openProperty]);

  function loadCommunityList(community_name) {
    if (community_name.length >= 3) {
      setLoadingCommunityText(I18n.t("modules.register.searching_message"));
      sendCommunityRequest({ variables: { input: { name: community_name } } });
    } else {
      setOpenCommunity(false);
    }
  }

  function loadPropertyList(property_name) {
    if (property_name.length >= 1) {
      setLoadingPropertyText(I18n.t("modules.register.searching_message"));
      sendPropertyRequest({
        variables: {
          communityId: communityId,
          propertyInput: { name: property_name.toLowerCase() },
        },
      });
    } else {
      setOpenProperty(false);
    }
  }

  function onChangeCommunity(option, value) {
    setGlobalState({
      communityId: value.id,
    });
    setCommunityId(value.id);
  }

  function onChangeProperty(option, value) {
    setGlobalState({
      propertyId: value.propertyId,
    });
    setPropertyId(value.propertyId);
    setDisableButton(false);
  }

  return (
    <Grid spacing={2}>
      <WizardTitle number={2} />
      <Grid>
        <Autocomplete
          id="community-autocomplete"
          className={(classes.textfield, classes.textfieldMargin)}
          open={openCommunity}
          onOpen={() => {
            setOpenCommunity(true);
          }}
          onClose={() => {
            setOpenCommunity(false);
          }}
          onChange={(option, value) => onChangeCommunity(option, value)} // prints the selected value
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) =>
            option.name.concat(" - ").concat(option.address)
          }
          options={communityOptions}
          loading={loadingCommunities}
          noOptionsText={I18n.t("modules.register.no_results")}
          loadingText={loadingCommunityText}
          renderOption={(option) => (
            <Fragment>
              <span>
                <AddLocationOutlinedIcon />
              </span>
              {"\u00A0"}
              {"\u00A0"}
              {option.name.concat(" - ").concat(option.address)}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={I18n.t("modules.register.community_name")}
              variant="outlined"
              onChange={(event) => {
                loadCommunityList(event.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: resultCommunity.loading && (
                  <CircularProgress color="inherit" size={20} />
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid>
        <Autocomplete
          id="property-autocomplete"
          className={(classes.textfield, classes.textfieldMargin)}
          open={openProperty}
          onOpen={() => {
            setOpenProperty(true);
          }}
          onClose={() => {
            setOpenProperty(false);
          }}
          onChange={(option, value) => onChangeProperty(option, value)}
          getOptionSelected={(option, value) =>
            option.name === value.PropertyName
          }
          getOptionLabel={(option) => option.propertyName}
          options={propertyOptions}
          loading={loadingProperties}
          noOptionsText={I18n.t("modules.register.no_results")}
          loadingText={loadingPropertyText}
          renderOption={(option) => (
            <Fragment>
              <span>
                <HomeOutlinedIcon />
              </span>
              {"\u00A0"}
              {"\u00A0"}
              {option.propertyName}
            </Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={I18n.t("modules.register.external_number")}
              onChange={(event) => {
                loadPropertyList(event.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: resultProperty.loading && (
                  <CircularProgress color="inherit" size={20} />
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid>
        <Divider />
      </Grid>
      <Grid>
        <PublicBtn
          disabled={disableButton}
          label={I18n.t("modules.register.next")}
          onClick={registerManualPage}
        />
      </Grid>
      <Grid>
        <Divider className={classes.horizontalDivisor} />
        <ButtonBase focusRipple={true} className={classes.buttonBase} onClick={registerQrPage}>
          <Paper className={classes.qrPaperButton} elevation={2}>
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              container
              direction="row"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <label>
                    {I18n.t("modules.register.qr_registry_message")}
                  </label>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img
                  className={classes.MediaLogo}
                  src={SetImageQr()}
                />
              </Grid>
            </Grid>
          </Paper>
        </ButtonBase>
      </Grid>
    </Grid>
  );
}

function PropertySignUpStep({ previous, next, backdrop }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={2}
      numberStep={2}
      StepContent={PropertySignUpStepContent}
    />
  );
}

export default PropertySignUpStep;
