import { Controller } from 'stimulus';

export default class extends Controller {

  static values = {
    dayAfternoonNightBlock: String
  }

  connect() {
    this.handleSchedulingFormatChange = this.handleSchedulingFormatChange.bind(this);
    this.handleDayForCheckChange = this.handleDayForCheckChange.bind(this);

    $(this.element).on('change', '#common_space_scheduling_format', this.handleSchedulingFormatChange);
    $(document).on('change', '.day_for_check', this.handleDayForCheckChange);
  }

  disconnect() {
    $(this.element).off('change', '#common_space_scheduling_format', this.handleSchedulingFormatChange);
    $(document).off('change', '.day_for_check', this.handleDayForCheckChange);
  }


  handleSchedulingFormatChange() {
    let scheduling_format = $('#common_space_scheduling_format').val();
    if (scheduling_format === this.dayAfternoonNightBlockValue) {
      $('.single-slot-container').hide().removeClass('show').find('input').prop('disabled', true);
      $('.multiple-slots-container').show().find('input').prop('disabled', false);
    } else {
      $('.single-slot-container').show().find('input').prop('disabled', false);
      $('.multiple-slots-container').hide().removeClass('show').find('input').prop('disabled', true);
    }
    $('.day_for_check').trigger('change');
  }

  handleDayForCheckChange(event) {
    let scheduling_format = $('#common_space_scheduling_format').val();
    let container_class = '.single-slot-container';

    if (scheduling_format === this.dayAfternoonNightBlockValue) {
      container_class = '.multiple-slots-container';
    }
    let slot_container = $(event.currentTarget).closest('div').find(container_class);
    if ($(event.currentTarget).is(':checked')) {
      slot_container.show();
      slot_container.removeClass('invisible');
      slot_container.addClass('show');
      slot_container.parent().removeClass('invisible');
      slot_container.find('input').prop('disabled', false);
    } else {
      slot_container.hide();
      slot_container.parent().addClass('invisible');
      slot_container.parent().removeClass('show');
      slot_container.addClass('invisible');
      slot_container.find('input').prop('disabled', true);
    }
  }
}
