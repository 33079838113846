import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['paymentType', 'paymentNumber', 'checkbook', 'verifyUniquenessInput', 'form']

  static values = {
    showDupModal: { type: Boolean, default: false }
  }

  connect() {
    const checkbookCondition = this.paymentTypeTarget.dataset.checkbookCondition === 'true';
    this.paymentTypeTarget.addEventListener('change', () => {
      if (this.paymentTypeTarget.value == 0 && checkbookCondition) {
        this.showCheckbook()
      } else {
        this.showPaymentNumber()
      }
    })
    if(this.showDupModalValue) {
      this.showWarningDocumentNumber()
    }

  }

  showCheckbook() {
    this.paymentNumberTarget.setAttribute('style', 'display: none;')
    if (this.hasCheckbookTarget) {
      this.checkbookTarget.removeAttribute('style')
    }
  }

  showPaymentNumber() {
    this.paymentNumberTarget.removeAttribute('style')
    if (this.hasCheckbookTarget) {
      this.checkbookTarget.setAttribute('style', 'display: none;')
    }
  }

  saveAnyway() {
    if(this.hasVerifyUniquenessInputTarget) {
      this.verifyUniquenessInputTarget.remove()
    }
    const priceValueInput = document.getElementById('service_billing_price')

    priceValueInput.value = priceValueInput.getAttribute('value')

    this.formTarget.submit()
  }

  showWarningDocumentNumber(){
    $('#sb_warning_document_number').modal();
  }
}
