import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'elementName', 'elementClass', 'table', 'deleteButton', 'elementConfirm']

  showModal(event) {
    let target = event.currentTarget
    this.replaceTexts(target)
    this.replaceHref(target)
    $(this.modalTarget).modal()
  }

  replaceTexts(target) {
    this.elementClassTargets.forEach(element => element.innerHTML = this.tableTarget.dataset.element)
    this.elementNameTarget.innerHTML = target.dataset.elementName
    if (this.tableTarget.dataset.confirm) this.elementConfirmTarget.innerHTML = this.tableTarget.dataset.confirm
  }

  replaceHref(target) {
    this.deleteButtonTarget.setAttribute('href', target.dataset.deletePath)
  }
}
