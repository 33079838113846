import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
  }

  modify_unselected_deductions(checkboxes, decision) {
    checkboxes.forEach(checkbox => checkbox.disabled = decision)
  }

  update_debt_value(new_balance, debt_id) {
    let debt_amount_field = document.querySelector(`#debts_amount_${debt_id}`)
    let debt_amount_autonumeric = AutoNumeric.getAutoNumericElement(debt_amount_field)
    new_balance = new_balance < 0 ? 0 : new_balance

    debt_amount_autonumeric.set(new_balance)

    // Must trigger the 'change' event so that the manual assignation
    // original configuration starts working
    debt_amount_field.dispatchEvent(new Event('change'));

    if(new_balance == 0) {
      debt_amount_field.disabled = true
    } else {
      debt_amount_field.disabled = false
    }
  }

  update_debt_balance(field_id, new_balance, debt_price_field) {
    let new_balance_value = AutoNumeric.getAutoNumericElement(field_id)
    if (!new_balance_value) {
      new_balance_value = new AutoNumeric(field_id, {
        currencySymbol: debt_price_field.dataset.autonumericAsign,
        decimalPlaces: debt_price_field.dataset.autonumericMdec,
        currencySymbolPlacement: debt_price_field.dataset.autonumericPsign,
        decimalCharacter: debt_price_field.dataset.autonumericAdec,
        digitGroupSeparator: debt_price_field.dataset.autonumericAsep
      });
    }
    new_balance_value.set(new_balance);
  }

  update_max_debts_amount(debt_id, new_amount) {
    let debts_amount = document.querySelector(`input[id*="debts_amount_${debt_id}"]`)
    debts_amount.classList.forEach((item) => {
      if (item.startsWith('max_amount_')) {
        debts_amount.classList.remove(item) ;
      }
    })
    debts_amount.classList.add(`max_amount_${new_amount}`)
  }

  update_debts_amount(debt_id, new_amount) {
    let debt_price_field = document.querySelector(`#debt_${debt_id}_price`)
    let debts_amount = document.querySelector(`input[id*="debts_amount_${debt_id}"]`)
    debts_amount.value = new_amount
    this.resetAutonumericValue(debts_amount, debt_price_field, new_amount)
  }

  update_debt_original_price(new_price, new_balance, debt_id, debt_price) {
    let debt_price_field = document.querySelector(`#debt_${debt_id}_price`)
    let old_debt_price_field = document.querySelector(`#debt_${debt_id}_old_price`)

    // Balance and price can't be negative
    new_price = new_price < 0 ? 0 : new_price
    new_balance = new_balance < 0 ? 0 : new_balance

    this.resetAutonumericValue(`#debt_${debt_id}_updated_price`, debt_price_field, new_price)
    document.querySelector(`#debt_${debt_id}_updated_price`).classList.add('pull-right')
    // Showing striked old value if the value has changed
    if(debt_price == new_price) {
      old_debt_price_field.style.display = "none"
    } else {
      old_debt_price_field.style.display = "block"
    }

    this.update_debt_balance(`#debt_${debt_id}_balance`, new_balance, debt_price_field)
    document.querySelector(`#debt_${debt_id}_balance`).classList.add('text-right')
  }

  update_payment_amount(prop_id, debt_id, new_balance) {
    let element_price = document.getElementById('price_new_payment') ? document.getElementById('price_new_payment') : document.querySelector(`.prop${prop_id}`)
    let total_to_pay = 0;
    let debts_amount_data = JSON.parse(localStorage.getItem(`property_id_${prop_id}_debts_amount`) || '{}')
    debts_amount_data[debt_id] = new_balance

    if (Object.keys(debts_amount_data).length > 0) {
      for (let key in debts_amount_data) {
        if (debts_amount_data.hasOwnProperty(key)) {
          let amount_to_pay = debts_amount_data[key]
          total_to_pay += parseFloat(amount_to_pay)
        }
      }
    }

    AutoNumeric.getAutoNumericElement(element_price).set(total_to_pay)
    localStorage.setItem(`property_id_${prop_id}_price`, total_to_pay)
    localStorage.setItem(`property_id_${prop_id}_debts_amount`, JSON.stringify(debts_amount_data))
    document.getElementById(`selected_debts_amount_${prop_id}`).value = JSON.stringify(debts_amount_data)
  }

  resetAutonumericValue(id, configField, newValue) {
    // Creating AutoNumeric object to get the right format of the field
    // Grabbing the autonumeric settings from the element itself
    let autonumericAttr = this.parseAutoNumericAttr(configField)
    let autonumericElement = AutoNumeric.getAutoNumericElement(id)
    if (!autonumericElement) {
      autonumericElement = new AutoNumeric(id, {
        currencySymbol : autonumericAttr.aSign,
        decimalPlaces: autonumericAttr.mDec,
        currencySymbolPlacement: autonumericAttr.pSign,
        decimalCharacter: autonumericAttr.aDec,
        digitGroupSeparator: autonumericAttr.aSep
      });
    }
    autonumericElement.set(newValue);
  }

  parseAutoNumericAttr(configField) {
    let dataset = configField.dataset
    let autonumericAttr = null
    if (configField.nodeName == 'INPUT') {
      autonumericAttr = JSON.parse(dataset.autonumeric)
    } else {
      autonumericAttr = {
        aSign: dataset.autonumericAsign,
        pSign: dataset.autonumericPsign,
        mDec: dataset.autonumericMdec,
        aSep: dataset.autonumericAsep,
        aDec: dataset.autonumericAdec
      }
    }
    return autonumericAttr
  }

  countDeductionsSum(event) {
    let debt_balance = event.currentTarget.dataset.debtBalance
    let debt_price = event.currentTarget.dataset.debtPrice
    let debt_id = event.currentTarget.dataset.debtId
    let prop_id = event.currentTarget.dataset.propId
    let selected_deductions = document.querySelectorAll(`input[name*="${event.currentTarget.dataset.selectedDeductions}"]:checked:not(:disabled)`)
    let applied_deductions = document.querySelectorAll(`input[name*="${event.currentTarget.dataset.selectedDeductions}"]:checked:disabled`)
    let unselected_deductions = document.querySelectorAll(`input[name*="${event.currentTarget.dataset.selectedDeductions}"]:not(:checked):disabled`)
    let discounts_sum = 0

    selected_deductions.forEach((deduction) => {
      discounts_sum += parseFloat(deduction.dataset.price)
    })

    let new_balance = debt_balance - discounts_sum
    if (new_balance < 0) new_balance = 0;

    applied_deductions.forEach((deduction) => {
      discounts_sum += parseFloat(deduction.dataset.price)
    })

    let new_price = debt_price - discounts_sum

    this.modify_unselected_deductions(unselected_deductions, discounts_sum >= debt_balance)
    this.update_debt_value(new_balance, debt_id)
    this.update_debt_original_price(new_price, new_balance, debt_id, debt_price)
    this.update_max_debts_amount(debt_id, new_balance)
    this.update_debts_amount(debt_id, new_balance)
    this.update_payment_amount(prop_id, debt_id, new_balance)
  }
}
