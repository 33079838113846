import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['firstSource', 'secondSource', 'thirdSource', 'toFill'];

  connect() {
    this.inputs = [
      this.firstSourceTarget,
      this.secondSourceTarget,
      this.thirdSourceTarget
    ];

    this.inputs.forEach(input =>
      input.addEventListener('change', this.fillBlankTarget.bind(this))
    );
  }

  fillBlankTarget() {
    const firstSource = this.firstSourceTarget.value;
    const secondSource = this.secondSourceTarget.value.split('-');
    const thirdSource = this.thirdSourceTarget.value.split('-');
    const formatedSecond = secondSource.length === 3 ? `${secondSource[2]}/${secondSource[1]}/${secondSource[0]}` : '';
    const formatedThird = thirdSource.length === 3 ? `${thirdSource[2]}/${thirdSource[1]}/${thirdSource[0]}` : '';
    let result = '';

    if (firstSource) {
      result += `${firstSource}% dcto. `;
    }

    if (secondSource.length === 3) {
      result += `Desde ${formatedSecond} `;
    }

    if (thirdSource.length === 3) {
      result += `hasta ${formatedThird}`;
    }

    this.toFillTarget.value = result;
  }
}
