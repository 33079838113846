import { useState, forwardRef, useImperativeHandle } from "react";
import I18n from "i18n-js";
import { makeStyles, TextField } from "@material-ui/core";
import { isEmailValid } from '../utilities/actions';

const useStyles = makeStyles(() => ({

    textfield: {
        '& input:valid + fieldset': {
            borderWidth: 1,
        },
        '& input:invalid + fieldset': {
            borderWidth: 1,
        },
        '& input:valid:focus + fieldset': {
            borderWidth: 2,
        },
        '& input:invalid:focus + fieldset': {
            borderWidth: 2,
        },
        "& .MuiInputBase-root.Mui-focused": {
            letterSpacing: '0.02em'
        },
        "& .MuiInputBase-input":
        {
          padding: "0 14px",
          height: "56px",
        },
    },
}));

const EmailTextField = forwardRef((props, ref) => {
    let email;
    if(ref && ref.current && typeof(ref.current) === "string" && ref.current !== undefined){
        email = ref.current.trim();
    }

    const classes = useStyles();
    const [showValidationError, setShowValidationError] = useState(false);
    const [showError, setShowError] = useState(props.message);
    const [showRequiredError, setShowRequiredError] = useState(false);

    useImperativeHandle(ref, () => ({
        validate(showValidationError, showRequiredError) {
            if (showValidationError) {
                setShowValidationError(showValidationError);
                setShowError(showValidationError);
                return false;
            }

            if (showRequiredError) {
                setShowError(showRequiredError);
                setShowRequiredError(showRequiredError);
                return false;
            }
            setShowError(false);
            setShowRequiredError(false);
            setShowValidationError(false);
        },
        set_value(value){
            ref.current = value.trim();
        }
    }));

    function onBlurEmail(e) {
        let validateValue = false;
        setShowValidationError(false);
        setShowError(false);
        setShowRequiredError(false);

        if (e.target.value !== '') {
            validateValue = !isEmailValid(e.target.value.trim());
        }

        if (validateValue || props.message) {
            setShowValidationError(true);
            setShowError(true);
        } else {
            setShowValidationError(false);
            setShowError(false);
        }
    }

    function onFocusEmail() {
        setShowValidationError(false);
        setShowError(false);
        setShowRequiredError(false);
    }

    function getErrorMessage(props, showValidationError, showRequiredError) {
        if (props.message) {
            return props.message
        } else
            if (showValidationError) {
                return I18n.t('modules.components.password_text_field.password_required')
            }
        return null;
    }

    return (
        <TextField
            autoComplete='off'
            autoFocus={ true }
            className={ classes.textfield }
            error={props.message ? true : showError}
            fullWidth
            helperText={getErrorMessage(props, showValidationError, showRequiredError)}
            id="email"
            inputProps={{ maxLength: 70 }}
            label={I18n.t('placeholders.user.email')}
            margin="normal"
            name="email"
            ref={node => { email = node }}
            onBlur={onBlurEmail}
            onFocus={onFocusEmail}
            variant="outlined"
            value={ email }
            {...props}
        />
    );
});


export default EmailTextField;
