import BaseController from '../base_controller'
import I18n from 'i18n-js'

export default class extends BaseController {
  static targets = ['inputUser', 'inputPosition', 'createButton', 'committeeTable', 'modal', 'destroyButton', 'noMembersRow']

  validateForm() {
    let selectedUser = this.inputUserTarget.value > 0
    let typedPosition = this.inputPositionTarget.value != ''
    if (selectedUser && typedPosition) {
      this.createButtonTarget.removeAttribute('disabled')
    } else {
      this.createButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  submitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.disabled = true
    let data = {
      committee_member: {
        user_id: this.inputUserTarget.value,
        position: this.inputPositionTarget.value
      }
    }
    fetch('/committee_members', {
      method: 'POST',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => this.handleCreateResponse(data));
  }

  handleCreateResponse(data) {
    if (data.result) {
      this.addMemberRow(data.new_member.committee_member_id)
      this.resetInputs(data.new_member.user_id)
      this.turboMessage('success', data.message);
    }
  }

  addMemberRow(community_user_id) {
    let url = `/committee_members/${community_user_id}`
    let turboFrame = document.createElement('turbo-frame')
    turboFrame.setAttribute('id', `committee_member_${community_user_id}`)
    turboFrame.src = url
    if (this.hasNoMembersRowTarget) this.noMembersRowTarget.remove()
    this.committeeTableTarget.appendChild(turboFrame)
  }

  startDeleting(event) {
    let path = `/committee_members/${event.currentTarget.dataset.committeeMemberId}`
    fetch(path, {
      method: 'DELETE',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => response.json())
    .then(data => this.handeDeleteResponse(data))
    .catch(error => {
      console.log(error);
    });
  }

  handeDeleteResponse(data) {
    let messageType = data.result ? 'success' : 'alert'
    if (data.result) {
      this.committeeTableTarget.querySelector(`#committee_member_${data.id}`).remove()
    }
    if (data.count == 0) {
      this.addNoMembersRow()
    }
    this.turboMessage(messageType, data.message);
    $(this.modalTarget).modal('hide');
  }

  addNoMembersRow() {
    let frame = document.createElement('turbo-frame')
    frame.setAttribute('id', 'no_result')
    frame.src = `/async/no_result?message=${I18n.t('views.committee_members.empty')}`
    this.committeeTableTarget.appendChild(frame)
  }

  showModal(event) {
    $(this.modalTarget).modal()
    this.destroyButtonTarget.dataset.committeeMemberId = event.currentTarget.dataset.committeeMemberId
  }

  resetInputs(option_to_delete) {
    let selector = document.getElementById('committee_member_user_id-selector')
    selector.querySelector('.selected-value').innerHTML = I18n.t('views.committee_members.form.default_owner')
    this.removeOption(selector, option_to_delete)
    this.inputUserTarget.value = -1
    this.inputUserTarget.dispatchEvent(new Event('change'))
    this.inputPositionTarget.value = ''
  }
}
