import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  verifySubmit() {
    if (this.filePresent()) {
      this.submitButtonTarget.removeAttribute('disabled')
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  filePresent() {
    return document.getElementById('file').files.length > 0 || document.getElementById('file').getAttribute('value') == 'true'
  }
}
