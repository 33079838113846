import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import I18n from "i18n-js";

const useStyles = makeStyles((theme) => ({

  image: {
    width: "100%",
    height: "100%"
  }
}));

function setBanner(event) {
  const language = document.querySelector('#language_conf')
  if ((language && language.value === 'en' || I18n.locale == 'en') || window.location.href.includes('happyhoa')) {
    return '/logotipo-hh.png'
  }

  return "/cf-logo.png";
};

const CompanyLogo = (props) => {
  const classes = useStyles();

  return (
    <a href="/">
      <img src={setBanner()} fetchpriority={"high"}  className={classes.image} {...props} tabIndex={-1} />
    </a>
  );
};

export default CompanyLogo;
