import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['documentType', 'documentNameDiv', 'documentDescriptionDiv', 'documentNameSectionDiv', 'submitFileButton', 'documentName']


  checkOtherDocumentType() {
    const documentTypeValue = this.documentTypeTarget.value
    if (documentTypeValue == 'other' ) {
      this.documentNameSectionDivTarget.removeAttribute('style')
    } else {
      this.documentNameSectionDivTarget.setAttribute('style', 'display: none;')
    }
    this.checkFormMandatoryInputs()
  }

  checkFormMandatoryInputs() {
    if (this.typeSelected() && this.filePresent()) {
      this.submitFileButtonTarget.removeAttribute('disabled')
    } else {
      this.submitFileButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  typeSelected() {
    const suggestedTypeSelected = this.documentTypeTarget.value  && this.documentTypeTarget.value != 'other'
    const otherTypeSelected = document.getElementById('employee_file_name').value.length > 0 && this.documentTypeTarget.value == 'other'

    return suggestedTypeSelected || otherTypeSelected
  }

  filePresent() {
    return document.getElementById('file').files.length > 0 || document.getElementById('file').getAttribute('value') == 'true'
  }

  checkLengthAndInputs(event) {
    const element = event.currentTarget
    const target = element.dataset.target
    const maxLength = parseInt(element.getAttribute('maxlength'))
    if (target == 'documentNameDiv') {
      this.documentNameDivTarget.classList.toggle('error', document.getElementById('employee_file_name').value.length >= maxLength)
    } else if (target == 'documentDescriptionDiv') {
      this.documentDescriptionDivTarget.classList.toggle('error', document.getElementById('employee_file_description').value.length >= maxLength)
    }
    this.checkFormMandatoryInputs()
  }

  removeErrorClass(event) {
    const element = event.currentTarget
    const target = element.dataset.target
    if (target == 'documentNameDiv') {
      this.documentNameDivTarget.classList.remove('error')
    } else if (target == 'documentDescriptionDiv') {
      this.documentDescriptionDivTarget.classList.remove('error')
    }
  }
} 
