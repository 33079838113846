import { Controller } from "stimulus";

export default class extends Controller {
  static values = { questionsCount: Number }
  static targets = ['addNested', 'template']

  connect(){
    this.questionsCountValue = 0;
  }

  addAssociation(event){
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, parseInt(Date.now()));
    this.addNestedTarget.insertAdjacentHTML('beforeBegin', content);
    let newQuestion = this.addNestedTarget.previousElementSibling;
    newQuestion.scrollIntoView({ behavior: 'smooth' });
  }

  removeAssociation(event){
    event.preventDefault();
    let item = event.target.closest('.nested-fields');
    item.querySelector("input[name*=_destroy").value = 1;
    item.querySelector("input[name*=name").eliminated = 1;
    item.style.display = 'none';
  }
}
