import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['payment_tooltip', 'destroyButton'];

  connect() {
    this.activateTooltips();
  }

  activateTooltips() {
    $('[data-toggle="tooltip"]', this.element).tooltip();
  }

  turboLoad() {
    this.activateTooltips();
  }

  turboRender() {
    this.activateTooltips();
  }

  update() {
    const iconElement = this.paymentTooltipTarget.querySelector('.fa');
    if (iconElement) {
      const tooltipText = iconElement.classList.contains('fa-check') ? t('views.commons.confirm_payment') : t('views.commons.cancel_payment');
      this.element.setAttribute('title', tooltipText);
    }
  }

  showDestroyModal(event) {
    this.destroyButtonTarget.href = event.currentTarget.dataset.href
    $('#destroy_credit_modal').modal()
  }
}
