import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.preventEnter.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.preventEnter.bind(this));
  }

  preventEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }
}
