import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Index as IndexV2 } from 'v2'
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
  LEVEL_ERROR,
} from '@rollbar/react'
import setupStore from '../store/setup'

enum CountryCode {
  bolivia = 'BO',
  chile = 'CL',
  ecuador = 'EC',
  elSalvador = 'SV',
  guatemala = 'GT',
  honduras = 'HN',
  mexico = 'MX',
  peru = 'PE',
  uruguay = 'UY',
  panama = 'PA',
}

type Props = {
  token: string,
  community_id: number,
  country_code: CountryCode,
  email: string,
  first_name: string,
  last_name: string,
  uses_period_control: boolean,
}

const config = {
  accessToken: process.env.ROLLBAR_REACT!,
  environment: process.env.NODE_ENV!,
}

const Index: React.FC<Props> = (props) => {
  const history = createBrowserHistory()
  const store = setupStore(history)

  return (
    <RollbarProvider config={config}>
      <RollbarErrorBoundary level={LEVEL_ERROR}>
        <Provider store={store}>
          {/* @ts-ignore */}
          <ConnectedRouter history={history}>
            <IndexV2 {...props} communityId={props.community_id} />
          </ConnectedRouter>
        </Provider>
      </RollbarErrorBoundary>
    </RollbarProvider>
  )
}

export default Index
