import { Controller } from "stimulus";
import I18n from 'i18n-js';

export default class extends Controller {
  static values = { optionsCount: Number }
  static targets = ['addOption', 'template', 'option']

  addAssociation(event){
    event.preventDefault();
    this.optionsCountValue += 1;
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, parseInt(Date.now()));
    content = content.replace(I18n.t('views.surveys.placeholder_option_number', { number: 3 }), I18n.t('views.surveys.placeholder_option_number', { number: this.optionsCountValue }) );
    content = this.replaceContent(content);
    this.addOptionTarget.insertAdjacentHTML('beforeBegin', content);
  }

    replaceContent(content){
      let row_id = content.match(/id="([^"]+)"/)[1]
      content = content.replace(row_id, `${row_id}${this.optionsCountValue}`);
      content = content.replace(`js-warning-${row_id}`, `js-warning-${row_id}${this.optionsCountValue}`);
      content = content.replace(`js-required-field-${row_id}`, `js-required-field-${row_id}${this.optionsCountValue}`);

      return content;
    }

  removeAssociation(event){
    event.preventDefault();
    let item = event.target.closest('.nested-fields');
    item.querySelector("input[name*=_destroy").value = 1;
    item.querySelector("input[name*=value").eliminated = 1;
    item.style.display = 'none';
    this.optionsCountValue -= 1;
    let changed = false;
    let counter = 1;
    this.optionTargets.forEach((target) => {
      if (target == item){
        changed = true;
        this.assingIdMessage(target, counter)
      }
      if (target.style.display != 'none') {
        if (changed){
          target.querySelector("input[name*='value'").placeholder = I18n.t('views.surveys.placeholder_option_number', { number: counter });
        }
        counter += 1;
      }
    });
  }

  assingIdMessage(target, counter){
    let messages = target.querySelectorAll('.text-danger')
    let row_id =  target.querySelector("input[name*='value'").dataset.rowId;
    let new_id = `${row_id}${counter}`;

    target.querySelector("input[name*='value'").dataset.rowId = new_id;
    messages[0].id = `js-warning-${new_id}`;
    messages[1].id = `js-required-field-${new_id}`;
  }

  validationInput(event){
    let input = event.target;
    let value = input.value;
    let row_id = input.dataset.rowId
    let max_length = input.dataset.maxLength;
    let message = document.getElementById(`js-warning-${row_id}`);
    let messagefield = document.getElementById(`js-required-field-${row_id}`);
    input.classList.remove('warning_input');
    input.classList.remove('invalid_input');

    if (value.length >= max_length) {
      input.classList.add('warning_input');
      message.classList.remove('hidden');
      messagefield.classList.add('hidden');
    } else if (value.length == 0) {
      input.classList.add('invalid_input');
      message.classList.add('hidden');
      messagefield.classList.remove('hidden');
    } else {
      input.classList.remove('mandatory-field');
      message.classList.add('hidden');
      messagefield.classList.add('hidden');
    }
  }

  validInputs(){
    let inputs = document.querySelectorAll('.option-text-field');

    inputs.forEach((input) => {
      this.validationInput({ target: input });
    });
  }
}
