const INITIAL_STATE = {
  userToken: null,
  propertyId: null,
  communityId: null,
  communityLocationUrl: null,
  property: null,
  userProperties: [],
  country_code: '',
  email: '',
  firstName: '',
  lastName: '',
  lastUri: null,
  code: null,
  loggedAsPropertyUser: null,
  createdByOauth: false,
  newPropertyRequest: false,
  historyData: false,
  dataSource: {},
  bill_id: null,
  incomeFromMorosity: false,
  resolution: null,
  marketingLandingUrl: '',
  error: null,
  lastPeriodExpense:'',
  currentPeriodExpense: true,
  noCommunity: false,
  res2_redirection: false,
};

export const GLOBAL_STATE = { ...INITIAL_STATE };

export function setGlobalState(newState) {
  let stateChanged = false;
  const changedValues = {};

  Object.keys(newState).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (GLOBAL_STATE.hasOwnProperty(key)) {
      if (newState[key] !== GLOBAL_STATE[key]) {
        GLOBAL_STATE[key] = newState[key];
        stateChanged = true;
        changedValues[key] = GLOBAL_STATE[key];
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(`${key} is not a valid GLOBAL_STATE property`);
    }
  });

  if (stateChanged) {
    onStateChangedEvent(changedValues);
  }
}

export function clearGlobalState() {
  setGlobalState(INITIAL_STATE);
}


const REGISTERED_LISTENERS = [];


function onStateChangedEvent(changedValues) {
  REGISTERED_LISTENERS.forEach((listener) => {
    const id = Object.keys(listener)[0];
    listener[id](changedValues);
  });
}
