import { Controller } from "stimulus"
import AutoNumeric from 'autonumeric'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['amount', 'invoiceAmount', 'dueDate', 'numberOfInstallments',
                    'numberOfInstallmentsWarning', 'createInstallmentsButton', 'submit',
                    'invoiceAmountWarning', 'createNewInstallmentButton']
  static values = { id: Number }

  initialize() {
    this.invalidDueDates = new Map()
  }

  amountTargetConnected(element) {
    this.initializeAutonumeric(element)
  }

  invoiceAmountTargetConnected(element) {
    this.initializeAutonumeric(element)

    if (AutoNumeric.getAutoNumericElement(this.invoiceAmountTarget).getNumber() < 0) {
      this.disableSubmit()
    }
  }

  invoiceAmountWarningTargetConnected() {
    if (AutoNumeric.getAutoNumericElement(this.invoiceAmountTarget).getNumber() < 0) {
      this.addAmountWarning()
    }
  }

  createNewInstallmentButtonTargetConnected() {
    const invoiceAmountValue = AutoNumeric
      .getAutoNumericElement(this.invoiceAmountTarget)
      .getNumber()

    if (this.invalidDueDates.size == 0 && invoiceAmountValue > 0) {
      this.enableSubmit()
    }
  }

  dueDateTargetDisconnected(element) {
    const index = parseInt(element.dataset.index)
    this.invalidDueDates.delete(index)
  }

  initializeAutonumeric(element) {
    new AutoNumeric(element, JSON.parse(element.dataset.autonumericFormat))
  }

  change(event) {
    const autonumericValue = AutoNumeric.getAutoNumericElement(event.target)
    const previousValue = parseFloat(autonumericValue.historyTable.at(-1).value)
    const value = autonumericValue.getNumber()

    let invoiceAmount = AutoNumeric.getAutoNumericElement(this.invoiceAmountTarget).getNumber()

    if (!isNaN(previousValue)) {
      invoiceAmount += previousValue
    }

    invoiceAmount -= value

    AutoNumeric.getAutoNumericElement(this.invoiceAmountTarget).set(invoiceAmount)

    if (invoiceAmount > 0) {
      this.enableSubmit()
      this.removeAmountWarning()
    } else {
      this.disableSubmit()
      this.addAmountWarning()
    }
  }

  changeDate(event) {
    const target = event.target
    const index = parseInt(target.dataset.index)
    const date = new Date(target.value).getTime()

    let valid = true
    let warningMessage = ''

    if (index < this.dueDateTargets.length - 1 && date > new Date(this.dueDateTargets[index + 1].value).getTime()) {
      valid = false
      warningMessage = I18n.t('views.admin.installments.index.form.table.warnings.due_date.greater')
    }

    if (valid && index > 0 && date < new Date(this.dueDateTargets[index - 1].value).getTime()) {
      valid = false
      warningMessage = I18n.t('views.admin.installments.index.form.table.warnings.due_date.less')
    }

    if (valid) {
      this.invalidDueDates.delete(index)
      this.removeDueDateWarning(index + 1)
      if (this.invalidDueDates.size == 0) {
        this.enableSubmit()
      }
    } else {
      this.invalidDueDates.set(index, true)
      this.disableSubmit()
      this.addDueDateWarning(index + 1, warningMessage)
    }
  }

  validateNumberOfInstallments() {
    const value = this.numberOfInstallmentsTarget.value
    if ( value < 2 || value > 120) {
      this.addNumberOfInstallmentsWarning()
      this.createInstallmentsButtonTarget.classList.add('disabled')
    } else {
      this.removeNumberOfInstallmentsWarning()
      this.createInstallmentsButtonTarget.classList.remove('disabled')
    }
  }

  newInstallmentsPath(installmentsNumber, firstDueDate) {
    return `${Routes.new_installments_admin_invoice_invoice_installments_path(this.idValue)}?installments_number=${installmentsNumber}&first_due_date=${firstDueDate}`
  }

  generateHref(event) {
    event.currentTarget.href = this.newInstallmentsPath(this.dueDateTargets.length - 1, this.dueDateTargets[0].value)
  }

  addNewInstallmentPath() {
    const firstDueDate = this.dueDateTargets[0].value
    const installmentsNumber = this.dueDateTargets.length + 1

    const path = this.newInstallmentsPath(installmentsNumber, firstDueDate)
    this.createNewInstallmentButtonTarget.href = path
  }

  calculateCumulativeAmount(index) {
    let amount = 0

    for( let i = 0; i < index; i++) {
      amount += AutoNumeric.getAutoNumericElement(this.amountTargets[i]).getNumber()
    }

    return amount
  }

  enableSubmit() {
    this.submitTarget.classList.remove('disabled')
  }

  disableSubmit() {
    this.submitTarget.classList.add('disabled')
  }

  removeDueDateWarning(index) {
    document.getElementById(`due_date_${index}-selector-input`).classList.remove('invalid_input')
    document.getElementById(`due_date_${index}-selector-dropdown`).classList.remove('invalid_input')
    document.getElementById(`${index}-due-date-warning`).style.display = ''
  }

  addDueDateWarning(index, warningMessage) {
    document.getElementById(`due_date_${index}-selector-input`).classList.add('invalid_input')
    document.getElementById(`due_date_${index}-selector-dropdown`).classList.add('invalid_input')
    let warning = document.getElementById(`${index}-due-date-warning`)
    warning.style.display = 'block'
    warning.innerHTML = warningMessage
  }

  addAmountWarning() {
    this.invoiceAmountTarget.classList.add('invalid_input')
    this.invoiceAmountWarningTarget.style.display = 'block'
  }

  removeAmountWarning() {
    this.invoiceAmountTarget.classList.remove('invalid_input')
    this.invoiceAmountWarningTarget.style.display = ''
  }

  addNumberOfInstallmentsWarning() {
    this.numberOfInstallmentsTarget.classList.add('invalid_input')
    this.numberOfInstallmentsWarningTarget.style.display = 'block'
  }

  removeNumberOfInstallmentsWarning() {
    this.numberOfInstallmentsTarget.classList.remove('invalid_input')
    this.numberOfInstallmentsWarningTarget.style.display = ''
  }
}
