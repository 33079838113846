import BaseController from '../base_controller'

export default class extends BaseController {
  static targets = ['goBackModal', 'goForwardModal', 'goForwardButton', 'goBackwardButton']

  showGoBackModal() {
    $(this.goBackModalTarget).modal()
  }

  showGoForwardModal() {
    $(this.goForwardModalTarget).modal()
  }

  goForwardOneMonth({ params: { disabledText, goForwardPath } }) {
    this.goForwardButtonTarget.classList.add('disabled')
    this.goForwardButtonTarget.innerText = disabledText
    window.location.href = goForwardPath
  }

  goBackwardOneMonth({ params: { disabledText, goBackwardPath } }) {
    this.goBackwardButtonTarget.classList.add('disabled')
    this.goBackwardButtonTarget.innerText = disabledText
    window.location.href = goBackwardPath
  }
}
