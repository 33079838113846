import React, { useState, forwardRef, useRef, useImperativeHandle } from "react";
import I18n from "i18n-js";
import { Box, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const useStyles = makeStyles(theme => ({
  
  showPass: {
    color: theme.palette.text.secondary,
    cursor: "pointer"
  },
  textfield: {
    fontSize: "14px",
    '& input:valid + fieldset': {
      borderWidth: 1,
    },
    '& input:invalid + fieldset': {
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderWidth: 2,
    },
    '& input:invalid:focus + fieldset': {
      borderWidth: 2,
    },
    "& .MuiInputBase-input":
    {
      letterSpacing: "10px",
      padding: "0 14px",
      height: "56px",
    },
    "& .MuiInputBase-root.Mui-focused": {
      letterSpacing: '0.022em',
    },
  },
}));

//function PasswordField(props) {
const PasswordField = forwardRef((props, ref) => {
  const  classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  let password;

  useImperativeHandle(ref, () => ({
    display(message) {
      setShowPasswordError(true);
      setPasswordError(message);
    },
    hide(val) {
      setShowPasswordError(false);
      setPasswordError("");
    }
  }));

  function togglePassword() {
    setShowPassword(!showPassword);
  };

  function onBlurPassword(event) {
    if (event.target.value === '') {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  }

  return (
    <TextField
      autoComplete='off'
      fullWidth
      className={classes.textfield}
      id="password"
      label={I18n.t('placeholders.user.password')}
      margin="normal"
      name="password"
      ref={node => { password = node }}
      type={!showPassword ? "password" : "text"}
      variant="outlined"
      onBlur={onBlurPassword}
      InputProps={{
        maxLength: 50,
        endAdornment: (
          <InputAdornment position="end">
            {!showPassword ? (
              <VisibilityOffOutlinedIcon
                className={classes.showPass}
                onClick={togglePassword}
              />
            ) : (
              <VisibilityOutlinedIcon
                className={classes.showPass}
                onClick={togglePassword}
              />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{ autoComplete: "off" }}
      {...props}
    />
  );
});
export default PasswordField;
