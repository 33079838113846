import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $("[data-toggle='tooltip']").tooltip()
    $.reloadAllAutonumeric()
  }

  setMask() {
    let price_field_autonumeric = AutoNumeric.getAutoNumericElement('#fine_amount')
    let currency_field = document.getElementById('fine_currency_id')
    let option = currency_field.options[currency_field.selectedIndex].text
    if (option == 'Pesos') {
      price_field_autonumeric.update({
        currencySymbol: '$ ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 0,
        digitGroupSeparator: '.',
        decimalCharacter: ','
      })
    } else {
      price_field_autonumeric.update({
        currencySymbol: '',
        currencySymbolPlacement: 'p',
        decimalPlaces: 4,
        digitGroupSeparator: '.',
        decimalCharacter: ','
      })
    }
    $.reloadAllAutonumeric();
  }
}
