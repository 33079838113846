import { Controller } from "@hotwired/stimulus"
import AutoNumeric from "autonumeric"

export default class extends Controller {
  static targets = ["dateSelector", "transferPercentage"]

  connect() {
    new AutoNumeric(this.transferPercentageTarget, {
      currencySymbol: '%',
      currencySymbolPlacement: 's',
      decimalPlacesOverride: 10,
      allowDecimalPadding: false,
      decimalPlacesShownOnFocus: 10,
      decimalPlacesShownOnBlur: 10
    })
    this.processDate()
  }

  processDate() {
    const input = this.dateSelectorTarget.value
    const parts = input.split('-')
    const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]))
    const lastDayOfMonth = new Date(parts[0], parts[1], 0)
    const newValue = ((date.getDate() - 1) * 100.0) / lastDayOfMonth.getDate()

    if (this.hasTransferPercentageTarget) {
      AutoNumeric.getAutoNumericElement(this.transferPercentageTarget).set(newValue)
      this.transferPercentageTarget.dispatchEvent(new Event('change'))
    }
  }
}
