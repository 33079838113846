import React, { useState } from "react";
import { Grid, Typography, Radio, RadioGroup, TextField } from "@material-ui/core/";
import I18n from "i18n-js";
import useStyles from './RegisterPartOwner.styles';
import addLocale from '../../utilities/actions/addLocale';

function arraySteps() {
    addLocale()
    return [
        I18n.t('modules.register.first'),
        I18n.t('modules.register.second'),
        I18n.t('modules.register.third'),
        I18n.t('modules.register.four'),
        I18n.t('modules.register.five')

    ];
}
function WizardTitle({ number, props }) {
    const classes = useStyles();
    return (
        <Typography variant="h4" className={classes.titleBox} >
            <label className={classes.wizardTitle}> {arraySteps()[number]} </label>
        </Typography>
    )
}

export default WizardTitle
