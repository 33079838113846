import { Controller } from "stimulus"
import I18n from "i18n-js"

export default class extends Controller {
  static values = { urlOptionPropertyUser: String }
  static targets = ['divLoadingMessage',
                    'divMultiselect']

  setOptions() {
    const url = this.urlOptionPropertyUserValue
    const multiselect = $('[id=property_user_select]')
    this.divLoadingMessageTarget.style.display = 'none'

    if (multiselect[0].options.length == 0) {
      this.divLoadingMessageTarget.style.display = 'block'
      this.divMultiselectTarget.style.display = 'none'
      fetch(url, { method: 'GET',
                  headers: { 'contentType': 'application/json',
                              'Accept': 'application/json',
                              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
                  })
                  .then(response => response.json())
                  .then(data => { this.prepareMultiselect(data) })
    }
  }

  prepareMultiselect(data) {
    const multiselect = $('[id=property_user_select]')

    multiselect.empty()

    data.forEach(function(option) {
      multiselect.append('<option value="' + option.value + '">' + option.label + '</option>')
    })

    multiselect.multiselect({
      maxHeight: 200,
      includeSelectAllOption: true,
      enableFiltering: true,
      filterBehavior: 'text',
      filterPlaceholder: I18n.t('views.commons.multiselect.search_property'),
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      maxHeight: 350
    });

    this.divMultiselectTarget.style.display = 'block'
    this.divLoadingMessageTarget.style.display = 'none'
  }
}
