import { Controller } from '@hotwired/stimulus'
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = ['multiselect']

  multiselectTargetConnected(target) {
    let context = this;
    console.log('multiselectTargetConnected')
    $(target).multiselect({
      includeSelectAllOption: true,
      filterBehavior: 'text',
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      maxHeight: 300,
      selectAllNumber: false,
      disableIfEmpty: true,
      disabledText: `${I18n.t('views.dashboard.funds.no_funds_selected')}`,
      includeFilterClearBtn: true,
      dropRight: true,
      includeSelectAllOption: false,
      nonSelectedText: `${I18n.t('views.dashboard.funds.no_funds_selected')}`,
      nSelectedText: 'seleccionados',
      filterPlaceholder: "Buscar fondo",
      allSelectedText: 'Todos los limites',
      onInitialized: function(option, checked) {
        context.limitFundsSelection()
      },
      onChange: function(option, checked) {
        context.limitFundsSelection()
      }
    });
  }


  limitFundsSelection() {
    var selectedOptions = $('.multiselect option:selected');
    if (selectedOptions?.length >= 2) {
      var nonSelectedOptions = $('.multiselect option').filter(function() {
        return !$(this).is(':selected');
      });
      nonSelectedOptions.each(function() {
        var input = $('input[value="' + $(this).val() + '"]');
        input.prop('disabled', true);
        input.parent('.multiselect-option').addClass('disabled');
      });
    }
    else {
      $('.multiselect option').each(function() {
        var input = $('input[value="' + $(this).val() + '"]');
        input.prop('disabled', false);
        input.parent('.multiselect-option').addClass('disabled');
      });
    }
  }
}
