import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['pictureDisplayer', 'loaderBox', 'fileInput', 'takePictureButton', 'canvas', 'swap', 'snap', 'cancelButton', 'tooltip']
  static values = { picturesCount: Number, maxPicturesAllowed: Number }

  connect() {
    this.videoDevicesIds = []
    this.initialized = false
    this.stream = null
    document.getElementById('photo_modal').addEventListener('hidden.bs.modal', this.stopTracks())
  }

  tooltipTargetsConnected(element) {
    $(element).tooltip();
  }

  stopTracks() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }
  }

  selectPicture() {
    this.fileInputTarget.click()
  }

  appendBox(pictureInput) {
    let boxElement = document.createElement('div')
    boxElement.classList.add('picture-box')
    this.appendRemoveButton(boxElement)
    boxElement.appendChild(pictureInput)
    this.pictureDisplayerTarget.insertBefore(boxElement, this.loaderBoxTarget)
    this.updateCounter(1)
  }

  appendRemoveButton(box) {
    let button = document.createElement('div')
    button.classList.add('btn', 'btn-default', 'btn-s', 'remove-button')
    button.setAttribute('data-action', 'click->picture-input#removePicture')
    button.setAttribute('data-picture-input-target', 'tooltip')
    button.setAttribute('data-toggle', 'tooltip')
    button.setAttribute('data-title', I18n.t('common.delete'))
    let icon = document.createElement('i')
    icon.classList.add('fa', 'fa-trash')
    button.appendChild(icon)
    this.tooltipTargetsConnected(button)
    box.appendChild(button)
  }

  removePicture(event) {
    event.currentTarget.closest('.picture-box').remove()
    this.updateCounter(-1)
  }

  uploadPicture() {
    const pictures = this.fileInputTarget.files
    if (pictures.length > 0) {
      const picture = pictures[0]
      if (this.validPicture(picture)) {
        this.appendPicture(pictures[0])
      } else {
        this.displayMessage()
      }
    }
  }

  validPicture(picture) {
    let extension = picture.name.split('.').pop().toLowerCase()
    return ['gif', 'img', 'jpg', 'jpeg', 'png'].includes(extension)
  }

  displayMessage() {
    let flashDiv = document.getElementById('flash')
    let flashMessageDiv = document.createElement('div')
    flashMessageDiv.classList.add('flash__error')
    let flashIcon = document.createElement('div')
    flashIcon.classList.add('flash-icon')
    let icon = document.createElement('div')
    icon.classList.add('fa', 'fa-times')
    flashIcon.appendChild(icon)
    flashMessageDiv.appendChild(flashIcon)
    let flashMessage = document.createElement('div')
    flashMessage.classList.add('flash-message')
    flashMessage.innerHTML = I18n.t('components.picture_input.error.not_allowed_extension')
    flashMessageDiv.appendChild(flashMessage)
    flashDiv.append(flashMessageDiv)
  }

  appendPicture(picture) {
    const newPicture = this.createPictureInput(picture)
    this.appendBox(newPicture)
  }

  createPictureInput(picture) {
    let pictureDiv = document.createElement('div')
    let reader = new FileReader();
    reader.onload = function(file) {
      let img = new Image();
      img.src = file.target.result;
      img.style.height = 'inherit'
      pictureDiv.appendChild(img);
    }
    reader.readAsDataURL(picture);
    const newPictureInput = document.createElement('input')
    newPictureInput.type = 'file'
    const newPictureList = new DataTransfer()
    newPictureList.items.add(picture)
    newPictureInput.files = newPictureList.files
    newPictureInput.style.display = 'none'
    newPictureInput.name = `assets[${this.picturesCountValue}][document]`
    newPictureInput.id = `assets_${this.picturesCountValue}_document`
    pictureDiv.appendChild(newPictureInput)

    return pictureDiv
  }

  updateCounter(value) {
    this.picturesCountValue += value
    if (this.hasMaxPicturesAllowedValue) {
      if (this.picturesCountValue >= this.maxPicturesAllowedValue) {
        this.loaderBoxTarget.style.display = 'none'
      } else {
        this.loaderBoxTarget.removeAttribute('style')
      }
    }
  }

  createPictureFromBlob() {
    const self = this
    const canvas = this.canvasTarget
    canvas.toBlob(
      blob => {
        const file = new File([blob], 'dot.png', blob)
        self.appendPicture(file)
      },
      'image/png'
    );
    this.stopTracks();

    $('#canvas_modal').modal('hide');
    $('#photo_modal').modal('hide');
  }

  takeSnap() {
    const canvas = this.canvasTarget
    const context = canvas.getContext('2d');
    const video = document.querySelector('video#video');

    context.canvas.height = video.videoHeight;
    context.canvas.width = video.videoWidth;

    $('#canvas_modal').modal();

    context.drawImage(video, 0, 0, context.canvas.width, context.canvas.height);
    document.querySelector('#canvas_modal').style.padding = '0';
  }

  async captureCamera(cameraId = null) {
    const constraints = {
      audio: false,
      video: {
        width: 1280,
        height: 720,
        deviceId: cameraId ? { exact: cameraId } : undefined
      },
    };

    return await navigator.mediaDevices.getUserMedia(constraints);
  }

  async getDevices() {
    const self = this
    await this.captureCamera()
      .then(stream => stream.getTracks().forEach(track => track.stop()))
      .catch((e) => alert('Permissions Denied'));
  
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      let videoDevices = devices.filter(device => device.kind == 'videoinput')

      if (videoDevices.length <= 1) {
        $('#camera-options').remove();
      } else {
        videoDevices.forEach((device) => {
          self.swapTarget.append(new Option(device.label, device.deviceId));
        });
      }

      this.videoDevicesIds = videoDevices.map(function (device) {
        return device.deviceId;
      });
    })
  }

  swapCamera() {
    const self = this
    async function init() {
      try {
        if (self.stream) {
          self.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
        self.stream = await self.setCamera(self.swapTarget.value);
        self.handleSuccess(self.stream);
      }
      catch (e) {
        console.log(e)
      }
    }
    init();
  }

  async setCamera(cameraId) {
    return await this.captureCamera(cameraId)
  }

  handleSuccess(stream) {
    const video = document.querySelector('video#video');
    window.stream = stream;
    video.srcObject = stream;
    this.snapTarget.removeAttribute('disabled');
    this.snapTarget.setAttribute('data-action', 'click->picture-input#takeSnap')
  }
  
  async init() {
    try {
      this.stream = await this.setCamera();
      this.handleSuccess(this.stream);
    }
    catch (e) {
      console.log(e)
    }
  }

  takePicture() {
    if (!this.initialized) {
      this.captureCamera()
      this.initialized = true
      this.getDevices();
    }
    this.init();

    $('#photo_modal').modal()
  }

  startDeleting(event) {
    event.preventDefault()
    let button = event.currentTarget
    let pictureBox = button.closest('.picture-box')
    let self = this
    let url = ''
    url = button.getAttribute('url')

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          pictureBox.remove()
          let flash = document.getElementById('flash')
          let flashSuccess = document.createElement('div')
          let flashIconDiv = document.createElement('div')
          flashIconDiv.classList.add('flash-icon')
          let flashIcon = document.createElement('div')
          flashIcon.classList.add('fa', 'fa-check')
          flashIconDiv.appendChild(flashIcon)
          let flashMessage = document.createElement('div')
          flashMessage.classList.add('flash-message')
          flashSuccess.classList.add('flash__success')
          flashMessage.innerHTML = I18n.t('components.file_input.message.file_deleted_successfully')
          flashSuccess.appendChild(flashIconDiv)
          flashSuccess.appendChild(flashMessage)
          flash.appendChild(flashSuccess)
          self.loaderBoxTarget.removeAttribute('style')
        } else {
          console.log(data)
        }
      })
  }
}
