import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'addNewButton' ]

  call(event) {
    let target = event.currentTarget.dataset.target
    let rows = document.querySelectorAll(`[id='collapsable-${target}']`)
    let collapse =  event.currentTarget.classList.contains('fa-chevron-up')

    for (let i = 0; i < rows.length; i++ ) {
      if (collapse) {
        rows[i].classList.add('hidden')
      } else {
        rows[i].classList.remove('hidden')
      }
    }

    let hiddenForm = document.getElementById(`hidden-form-${target}`)
    if (hiddenForm != null){
      if (collapse) {
        hiddenForm.classList.add('hidden')
      } else if (document.getElementById(`add-new-${target}`).classList.contains('disabled')) {
        hiddenForm.classList.remove('hidden')
      }
    }

    if (rows.length > 0 && !rows[0].parentElement.classList.contains('hidden')) {
      if (collapse) {
        event.currentTarget.classList.remove('fa-chevron-up')
        event.currentTarget.classList.add('fa-chevron-down')
      } else {
        event.currentTarget.classList.add('fa-chevron-up')
        event.currentTarget.classList.remove('fa-chevron-down')
      }
    }
  }

  changeToggle(event) {
    let target = event.currentTarget.dataset.target
    let rows = document.querySelectorAll(`[id='collapsable-${target}']`)

    if (rows.length > 0 && !rows[0].parentElement.classList.contains('hidden')) {
      let toggle = document.getElementById(`collapse-${target}`)
      if (toggle.classList.contains('fa-chevron-down')) {
        toggle.classList.add('fa-chevron-up')
        toggle.classList.remove('fa-chevron-down')
      }
    }
  }

}
