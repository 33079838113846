import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "accessControl", "accessControlEnableUsers" ];

  connect() {
    const accessControlSettingLabelsForm = document.getElementById('access-control-setting-labels');
    const selectAccessControl = this.accessControlTarget.querySelector('select');

    if (selectAccessControl.value == 0) {
      accessControlSettingLabelsForm.classList.add('hidden');
    }

    selectAccessControl.addEventListener('change', (e) => {
      if (e.currentTarget.value == 1){
        this.accessControlEnableUsersTarget.querySelector('select').removeAttribute('disabled');
        accessControlSettingLabelsForm.classList.remove('hidden');
      } else if ((e.currentTarget.value == 0)) {
        this.accessControlEnableUsersTarget.querySelector('select').disabled = true;
        accessControlSettingLabelsForm.classList.add('hidden');
      }
    })
  }
}
