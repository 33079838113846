import { Controller } from "stimulus";
import I18n from "i18n-js";

const circleFocusedColor = '#005FC5';
const circleUnfocusedColor = '#333333';

const doorStatusContainerIdentifier = 'door_status_';
const updateButtonIdentifier = 'door_update_button_';
const updateIconIdentifier = 'door_update_icon_';

const cancelButtonContainerIdentifier = 'door_cancel_';
const saveButtonContainerIdentifier = 'door_save_';
const updateButtonContainerIdentifier = 'door_update_';

export default class extends Controller {
  static targets = ['doors', 'map', 'sideInfo']

  static values = {
    communityLatitude: Number,
    communityLongitude: Number,
    mapId: String,
    updatePath: String,
    remoteApertureDistance: Number,
  }

  async connect() {
    if (window.GoogleMapsLoader) {
      await this.initializeAll()
    }
  }

  // Initializers

  async initializeAll() {
    if (this.alreadyLoading) return

    this.alreadyLoading = true
    await this.importMapsLibraries()
    this.initializeControllerProperties()
    this.initializeMap()
    this.initializeCommunityFixedMarker()
    this.initializeAllMarkers()
  }

  async importMapsLibraries() {
    const loader = window.GoogleMapsLoader
    const { AdvancedMarkerElement } = await loader.importLibrary('marker')
    const { Map, Circle } = await loader.importLibrary('maps')

    this.AdvancedMarkerElement = AdvancedMarkerElement
    this.Circle = Circle
    this.Map = Map
  }

  initializeControllerProperties() {
    this.circles = {}
    this.markers = {}
    this.communityCoordinates = this.initializeCommunityCoordinates()
    this.ongoingUpdates = 0
  }

  initializeCommunityCoordinates() {
    return { lat: parseFloat(this.communityLatitudeValue), lng: parseFloat(this.communityLongitudeValue) }
  }

  initializeMap() {
    const mapOptions = {
      center: this.communityCoordinates,
      zoom: 16,
      streetViewControl: false,
      fullscreenControl: false,
      mapId: this.mapIdValue
    }
    this.map = new this.Map(this.mapTarget, mapOptions)
  }

  initializeCommunityFixedMarker() {
    new this.AdvancedMarkerElement({
      content: this.buildCommunityFixedMarkerContent(),
      position: this.communityCoordinates,
      map: this.map
    })
  }

  initializeAllMarkers() {
    const doors = Array.from(this.doorsTarget.children)
    doors.forEach((element) => this.initializeMarkerWithCircle(element, true))
  }

  initializeMarkerWithCircle(door, returnIfNoDoorCoordinates = false) {
    const noDoorCoordinates = !this.coordinatesDefined(door)
    if (noDoorCoordinates && returnIfNoDoorCoordinates) return 

    const coordinates = noDoorCoordinates ? this.communityCoordinates : { lat: parseFloat(door.dataset.latitude), lng: parseFloat(door.dataset.longitude) }
    const doorCode = door.dataset.code

    this.initializeMarker(coordinates, doorCode, door.dataset.name)
    this.initializeCircle(coordinates, doorCode)

    const draggableMarker = this.markers[doorCode]
    const markerCircle = this.circles[doorCode]

    draggableMarker.notPersisted = noDoorCoordinates ? true : false

    draggableMarker.addListener('drag', function(event) {                     
      markerCircle.setOptions({ center: { lat: event.latLng.lat(), lng: event.latLng.lng() } })      
    })

  }

  initializeMarker(coordinates, doorCode, doorName) {
    const draggableMarker = new this.AdvancedMarkerElement({
      content: this.buildMarkerContent(doorName),
      position: coordinates,
      map: this.map
    })

    this.markers[doorCode] = draggableMarker
  }

  initializeCircle(coordinates, doorCode) {
    const markerCircle = new this.Circle({
      cursor: 'grab',
      strokeWeight: 0,
      fillColor: circleUnfocusedColor,
      fillOpacity: 0.2,
      map: this.map,
      center: coordinates,
      radius: this.remoteApertureDistanceValue
    })

    this.circles[doorCode] = markerCircle
  }

  // Event handlers

  hoverDoor(event) {
    this.toggleCircleColor(event.currentTarget.dataset.code, true)
  }

  leaveDoor(event) {
    const doorCode = event.currentTarget.dataset.code
    const updateButtonContainerClassList = document.getElementById(`${updateButtonContainerIdentifier}${doorCode}`).classList

    if (updateButtonContainerClassList.contains('hidden')) return
    this.toggleCircleColor(event.currentTarget.dataset.code)
  }

  cancelUpdate(event) {
    const code = event.currentTarget.dataset.code
    this.reorganizeButtons(code)
    this.changeSideInfoVisibility()
    this.makeMarkerNotDraggable(code)
    this.toggleCircleColor(code)
  }

  prepareUpdate(event) {
    const code = event.currentTarget.dataset.code
    this.reorganizeButtons(code)
    this.changeSideInfoVisibility(true)
    this.makeMarkerDraggable(event.currentTarget)
    this.toggleCircleColor(code, true)
  }

  submitUpdate(event) {
    const code = event.currentTarget.dataset.code
    this.reorganizeButtons(code)
    this.changeSideInfoVisibility()
    this.disableUpdate(code)
    this.toggleCircleColor(code)
    this.sendRequest(code)
  }

  // Request functions

  sendRequest(doorCode) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
    const newDoorPosition = this.markers[doorCode].position
    const url = this.updatePathValue
    const requestBody = {
      door: {
        code: doorCode,
        latitude: newDoorPosition.lat,
        longitude: newDoorPosition.lng
      }
    }

    return fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json())
    .then(data => this.handleResponse(data, doorCode))
  }

  handleResponse(data, doorCode) {
    this.enableUpdate(doorCode)
    if(data.success) {
      this.makeMarkerNotDraggable(doorCode, true)
      this.changeStatusToDone(doorCode)
      triggerNotice(data.message)
      allow_multiple_notices()
    } else {
      this.makeMarkerNotDraggable(doorCode)
      triggerAlert(data.message)
      allow_multiple_alerts()
    }
  }

  // Marker and circle behavior functions

  makeMarkerDraggable(door) { 
    const doorCode = door.dataset.code

    if (!this.markers[doorCode]) this.initializeMarkerWithCircle(door, false)

    const draggableMarker = this.markers[doorCode]
    draggableMarker.gmpDraggable = true
    draggableMarker.originalPosition = draggableMarker.position
  }

  makeMarkerNotDraggable(doorCode, success = false) {
    const draggableMarker = this.markers[doorCode]
    const draggableCircle = this.circles[doorCode]
    draggableMarker.gmpDraggable = false

    if (success) {
      draggableMarker.notPersisted = false
      draggableMarker.originalPosition = draggableMarker.position
    } else if (draggableMarker.notPersisted) {
      draggableMarker.setMap(null)
      draggableCircle.setMap(null)
      delete this.markers[doorCode]
      delete this.circles[doorCode]
      this.map.setCenter(this.communityCoordinates)
    } else {
      draggableMarker.position = draggableMarker.originalPosition
      draggableMarker.originalPosition = null
      draggableCircle.setOptions({ center: draggableMarker.position })
      this.map.setCenter(draggableMarker.position)
    }
  }

  toggleCircleColor(doorCode, focused = false) {
    const draggableCircle = this.circles[doorCode]
    if (!draggableCircle) return

    const newColor = focused ? circleFocusedColor : circleUnfocusedColor
    draggableCircle.setOptions({ fillColor: newColor })
  }

  // Elements appearance and visibility functions
 
  disableUpdate(code) {
    const updateButton = document.getElementById(`${updateButtonContainerIdentifier}${code}`).children[0]
    updateButton.setAttribute('disabled', true)
  }

  enableUpdate(code) {
    const updateButton = document.getElementById(`${updateButtonContainerIdentifier}${code}`).children[0]
    updateButton.removeAttribute('disabled')
  }

  changeSideInfoVisibility(newUpdate = false) {
    if (newUpdate) {
      this.ongoingUpdates += 1
      this.sideInfoTarget.classList.remove('hidden')
    } else {
      this.ongoingUpdates -= 1
      if (this.ongoingUpdates > 0) return
      this.sideInfoTarget.classList.add('hidden')
    }
  }

  changeStatusToDone(code) {
    const doorStatusContainer = document.getElementById(`${doorStatusContainerIdentifier}${code}`)
    doorStatusContainer.innerText = I18n.t('views.access_control.doors.status.done')
    doorStatusContainer.classList.remove('pending')
    doorStatusContainer.classList.add('done')

    const updateIconClassList = document.getElementById(`${updateIconIdentifier}${code}`).classList
    updateIconClassList.remove('fa-plus')
    updateIconClassList.add('fa-pencil')

    const updateButton = document.getElementById(`${updateButtonIdentifier}${code}`)
    // We need to use a Bootstrap tooltip method so we wrap the DOM element to convert it into a compatible JQuery object
    $(updateButton).tooltip().attr('data-original-title', I18n.t('views.access_control.doors.tooltips.update'))
  }

  reorganizeButtons(code) {
    const doorStatusContainer = document.getElementById(`${doorStatusContainerIdentifier}${code}`)
    const doorBoxClassList = doorStatusContainer.parentElement.classList
    const cancelButtonContainerClassList = document.getElementById(`${cancelButtonContainerIdentifier}${code}`).classList
    const saveButtonContainerClassList = document.getElementById(`${saveButtonContainerIdentifier}${code}`).classList
    const updateButtonContainerClassList = document.getElementById(`${updateButtonContainerIdentifier}${code}`).classList

    doorBoxClassList.toggle('door-box-selected')
    doorStatusContainer.classList.toggle('hidden')
    cancelButtonContainerClassList.toggle('hidden')
    saveButtonContainerClassList.toggle('hidden')
    saveButtonContainerClassList.toggle('leftmost')
    updateButtonContainerClassList.toggle('hidden')
  }

  // Auxiliary functions

  coordinatesDefined(door) {
    return door.dataset.latitude && door.dataset.longitude
  }

  buildCommunityFixedMarkerContent() {
    const content = document.createElement('div')
    content.innerHTML = `
      <div class='community-fixed-marker-container'>
        <div class='community-fixed-marker outer-circle'></div>
        <div class='community-fixed-marker inner-circle'></div>
      </div>
    `
    return content
  }

  buildMarkerContent(doorName) { 
    const content = document.createElement('div')
    content.innerHTML = `
      <div class='marker-tooltip-shape-container'>
        <div class='marker-tooltip-shape upper-oval'>
          <div class='marker-tooltip-shape-text'>${doorName}</div>
        </div>
        <div class='marker-tooltip-shape lower-tip'></div>
      </div>
    `
    return content
  }
}
