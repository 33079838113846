import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    trigger: String,
    fileName: String,
    attachTo: String
  }

  connect() {
    takePhoto({
      trigger: this.triggerValue,
      fileName: this.fileNameValue,
      attachTo: this.attachToValue
    })
  }
}
