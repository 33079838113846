import { Controller } from 'stimulus'
import I18n from 'i18n-js';
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = {
  currencySymbol: '$',
  currencySymbolPlacement: 'p',
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalPlaces: 0,
  minimumValue: 1,
  maximumValue: 2147483647,
  overrideMinMaxLimits: 'invalid',
  unformatOnSubmit: true
}

export default class extends Controller {
  static targets = [ 'modal', 'originAccount', 'destinationAccount', 'amount', 'confirm']

  connect() {
    this.destinationAccountTarget.parentElement.classList.toggle('disabled')
    this.openModal()
  }

  openModal() {
    let modal = this.modalTarget;
    modal.removeAttribute('aria-hidden');
    $(modal).modal();
  }

  closeModal() {
    let modal = this.modalTarget;
    $(modal).modal('hide')
    modal.setAttribute('aria-hidden', 'true');
    modal.style.display = 'none';
  }

  hideOption(event) {
    let selectorToHide = (event.target.id == this.originAccountTarget.id)
        ? this.destinationAccountTarget
        : this.originAccountTarget;
    let options = selectorToHide.nextElementSibling.children[1].children;
    Array.from(options).forEach(option => {
      ((option.dataset.value == event.target.value) && event.target.value != '0') ? option.hidden = true : option.hidden = false
    })
    if (event.target.value == '0' ) {
      this.destinationAccountTarget.parentElement.classList.add('disabled')
      this.destinationAccountTarget.nextElementSibling.querySelector('button#_destination_account_id-button').querySelector('.selected-value').innerText = I18n.t('views.bank_accounts.transfer_between_accounts.modal.account_placeholder')
      this.destinationAccountTarget.value = '0'

      if (event.target.id == this.originAccountTarget.id) {
        let originOptions = this.originAccountTarget.nextElementSibling.children[1].children;
        Array.from(originOptions).forEach(option => {
           option.hidden = false
        })
      }
    } else {
      this.destinationAccountTarget.parentElement.classList.remove('disabled')
    }
    this.enableConfirm()
  }

  enableConfirm() {
    let amount = AutoNumeric.getAutoNumericElement(this.amountTarget) || new AutoNumeric(this.amountTarget, AUTONUMERIC_FORMAT);

    if (this.originAccountTarget.value != '0' && this.destinationAccountTarget.value != '0' && amount.getNumber() != 0) {
      this.confirmTarget.classList.remove('disabled')
    } else {
      this.confirmTarget.classList.add('disabled')
    }
  }
}
