import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'
import I18n from 'i18n-js'

const AUTONUMERIC_FORMAT_DECIMAL = { decimalCharacter: '.', decimalPlaces: 2, decimalCharacterAlternative: ',', allowDecimalPadding: false, minimumValue: 0 }
const MAX_REDUCTION_PERCENTAGE = 50

export default class extends Controller {
  static targets = ['extraHourOne', 'extraHourTwo', 'extraHourThree', 'overtimeLimit', 'workedDays', 'dailyOvertimeLimit',
                    'reductionPercentage', 'employeeProtectionLaw', 'protectionLawCode']

  reductionPercentageTargetConnected(element) {
    new AutoNumeric(element, AUTONUMERIC_FORMAT_DECIMAL)
  }

  checkExtraHours(event) {
    let extraHours = 0
    if (this.hasExtraHourOneTarget) {
      extraHours += parseFloat(this.extraHourOneTarget.value)
    }
    if (this.hasExtraHourTwoTarget) {
      extraHours += parseFloat(this.extraHourTwoTarget.value)
    }
    if (this.hasExtraHourThreeTarget) {
      extraHours += parseFloat(this.extraHourThreeTarget.value)
    }

    if (extraHours > this.overtimeLimitTarget.value) {
      $('#extra-hours-article').modal('show')
    }
  }

  changeOvertimeLimit(event) {
    if (parseFloat(this.workedDaysTarget.value) > 0) {
      this.overtimeLimitTarget.value = parseFloat(this.workedDaysTarget.value) * parseFloat(this.dailyOvertimeLimitTarget.value)
    } else {
      this.overtimeLimitTarget.value = 0
    }
  }

  verifyReductionPercentage() {
    if (this.employeeProtectionLawTarget.value == 'false') {
      return
    } else if (this.protectionLawCodeTarget.value == 'reduccion_jornada_laboral') {
      if (parseFloat(this.reductionPercentageTarget.value) > MAX_REDUCTION_PERCENTAGE) {
        AutoNumeric.getAutoNumericElement(this.reductionPercentageTarget).set(MAX_REDUCTION_PERCENTAGE)
        this.reductionPercentageTarget.value = MAX_REDUCTION_PERCENTAGE
        this.showReductionPercentageModal()
      }
    }
  }

  showReductionPercentageModal() {
    document.getElementById('modal-title').innerHTML = I18n.t('views.remunerations.salary_payments.modals.reduction_percentage.title')
    document.getElementById('modal-body').innerHTML = I18n.t('views.remunerations.salary_payments.modals.reduction_percentage.body_html')
    $('#warning-modal').modal('show')
  }
}
