import { Controller } from 'stimulus';
export default class extends Controller {

  connect() {
    let tabBox = document.getElementById('tab-box');
    let tabsRow = tabBox.getElementsByClassName('tabs');
    let tabElements = tabsRow[0].getElementsByClassName('tab');
    let sum = 0;
    for (let tab of tabElements) {
      sum += tab.clientWidth
    }
    var scrollDuration = 300;
    let scrollLeft = this.getActiveTabPosition(tabElements, tabBox);

    (function() {
      $(tabsRow).animate({
        scrollLeft: scrollLeft,
      }, scrollDuration)
    })();
  }

  getActiveTabPosition(tabs, tabBox) {
    const chevronMargin = 28;
    const padding = 4;
    let sum = 0;
    let i = 0;
    while (i < tabs.length && !tabs[i].classList.contains('active')) {
      sum += tabs[i].clientWidth;
      i += 1
    }
  return Math.max(...[sum + tabs[i].clientWidth / 2 - tabBox.clientWidth / 2 + chevronMargin + padding * (i + 1), 0]);
}
}
