import { Controller } from 'stimulus';

export default class extends Controller {

  connect(){
    this.modal = document.getElementById('generic-delete-modal');
    this.modalTitle = document.getElementById('modal-body-title');
    this.modalBody = document.getElementById('modal-body-text');
    this.confirmButton = document.getElementById('confirm-button');
    this.toggleDestroyFutureFeesCheckbox = document.getElementById('toggle-destroy-future-fees');
  }

  openModal(event){
    const { massive = false } = event.params;
    const button = event.currentTarget;
    const deleteBody = button.dataset.deleteBody;
    const deleteTitle = button.dataset.deleteTitle;
    const confirmText = button.dataset.confirmText;

    this.modalBody.textContent = deleteBody;
    this.modalTitle.textContent = deleteTitle;
    this.confirmButton.textContent = confirmText;
    this.modal.dataset.childFineFormId = button.dataset.fineId;

    if(massive && this.propertyFineWithFutureFeesSelected()) {
      this.toggleDestroyFutureFeesCheckbox.classList.remove('hidden');
    } else {
      this.toggleDestroyFutureFeesCheckbox.classList.add('hidden');
    }
  }

  propertyFineWithFutureFeesSelected() {
    const propertyFineIds = document.getElementById('property_fines_to_delete_ids').value.split(',');

    for(let i = 0; i < propertyFineIds.length; i++) {
      let id = propertyFineIds[i];
      if(document.querySelector(`[data-fine-id="delete-fees-fine-${id}"]`) != null) {
        return true;
      }
    }

    return false;
  }

  delete(event){
    const childFineFormId = this.modal.dataset.childFineFormId;
    const childElement = document.querySelector(`[data-fine-id="${childFineFormId}"]`)
    childElement.closest('form').submit();
  }

  toggleDestroyFutureFees() {
    const checkbox = document.getElementsByName('toggle_destroy_future_fees')[0];
    document.getElementById('property_fines_to_delete_destroy_future_fees').value = checkbox.value;
  }
}
