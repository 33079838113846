import { Controller } from 'stimulus';

export default class extends Controller {

  update(event) {
    const originClass = $(`#targets`).data('origin_class');
    const ctId = $(`#targets`).data('ct_id');

    if (originClass === 'ServiceBilling') {
      const row = document.getElementById(`community_transaction_row_${ctId}`);

      row.querySelectorAll('.ct-action-btn').forEach(btn => btn.style.display = 'none');
      row.querySelector('.btn-light-blue.ct-action-btn').style.display = '';

      const form = row.querySelector('form');
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'change_service_billing';
      input.value = event.target.classList.contains('ct_modal_confirms');
      form.appendChild(input);

      form.requestSubmit();
    }
  }

  updateDate(event) {
    const btInput = event.currentTarget;
    const row = btInput.closest('.community_transaction_row');
    row.querySelectorAll('.ct-action-btn').forEach(btn => btn.style.display = 'none');
    row.querySelector('.btn-light-blue.ct-action-btn').style.display = '';
    const form = row.querySelector('form');
    if (form) {
      form.requestSubmit();
    }
  }
}
