import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['idsInput', 'divToShow', 'divToHide', 'itemsSelected']

  handleCheckBox(event) {
    const checkbox = event.target;
    const { ids, message } = checkbox.dataset;
    const allCheckboxes = document.querySelectorAll('.selected-property-fine');
    const selectAllCheckboxes = document.getElementById('select_all_checkboxes');

    if (checkbox.checked) {
      if (ids.length > 0) {
        this.fillForm(ids.split(','), message);
        this.disableCheckboxes(allCheckboxes, selectAllCheckboxes);
      }
    } else {
      this.emptyForm();
      this.enableCheckboxes(allCheckboxes, selectAllCheckboxes);
    }
  }

  fillForm(ids, message) {
    this.idsInputTargets.forEach((input) => {
      input.value = ids;
    });
    this.divToShowTargets.forEach((form) => {
      form.classList.remove('hide');
    });
    this.divToHideTarget.classList.add('hide');
    this.itemsSelectedTarget.classList.remove('hide');
    this.itemsSelectedTarget.innerHTML = `${ids.length} ${message}`;
  }

  emptyForm() {
    this.idsInputTargets.forEach((input) => {
      input.value = '';
    });
    this.divToShowTargets.forEach((form) => {
      form.classList.add('hide');
    });
    this.divToHideTarget.classList.remove('hide');  
    this.itemsSelectedTarget.classList.add('hide');
  }

  disableCheckboxes(allCheckboxes, selectAllCheckboxes) {
    allCheckboxes.forEach((input) => {
      input.disabled = true;
      input.checked = true;
    });
    selectAllCheckboxes.disabled = true;
    selectAllCheckboxes.checked = false;
  }

  enableCheckboxes(allCheckboxes, selectAllCheckboxes) {
    allCheckboxes.forEach((input) => {
      input.disabled = false;
      input.checked = false;
    });
    selectAllCheckboxes.disabled = false;
  }
}
