import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "downloadPropertiesIdsInput",
    "downloadSomeStatementsForm",
    "notifyPropertiesIdsInput",
    "notifySomeStatementsForm",
  ];

  downloadSomeStatements() {
    this.downloadPropertiesIdsInputTarget.value = this.getCheckedCheckboxes();
    this.downloadSomeStatementsFormTarget.submit();
  }

  notifySomeStatements() {
    this.notifyPropertiesIdsInputTarget.value = this.getCheckedCheckboxes();
    this.notifySomeStatementsFormTarget.submit();
  }

  getCheckedCheckboxes() {
    const ids_array = [];

    document
      .querySelectorAll("input[id^=property_]:checked")
      .forEach((checkbox) => ids_array.push(checkbox.id.match(/\d+/)[0]));

    return ids_array;
  }
}
