import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['selected']
  static targets = ['radioButtonDiv', 'input']

  changeInputValue(event) {
    this.radioButtonDivTargets.forEach(div => div.classList.remove('selected'))
    const inputValue = event.currentTarget.dataset.value
    this.inputTarget.value = inputValue
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}
