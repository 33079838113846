import BaseController from '../base_controller'
import I18n from 'i18n-js'

const AUTONUMERIC_FORMAT = {
  digitGroupSeparator: '.',
  decimalCharacter: ',',
  decimalPlaces: 2,
  unformatOnSubmit: true,
  allowDecimalPadding: false
}

export default class extends BaseController {
  static targets = ['basePrice', 'minimumPrice', 'exemptPercentage']

  connect() {
    super.connect()
    this.buildAutonumericInputs()
  }

  buildAutonumericInputs() {
    new AutoNumeric(this.basePriceTarget, AUTONUMERIC_FORMAT)
    new AutoNumeric(this.minimumPriceTarget, AUTONUMERIC_FORMAT)
    new AutoNumeric(this.exemptPercentageTarget, AUTONUMERIC_FORMAT)
  }

  getCurrencies(event) {
    let country_code = event.currentTarget.value
    if (country_code){
      let url = '/admin/paquetes_base/country_code/currency_options'
      let data = new URLSearchParams({ country_code: country_code })
      fetch(`${url}?${data}`)
        .then(response => response.json())
        .then(data => this.changeCurrencyTypeSelector(data.currencies))
    } else {
      this.resetCurrencyTypeSelector()
    }
  }

  changeCurrencyTypeSelector(currencies) {
    let selector = document.getElementById('base_package_currency_type-selector')
    this.resetCurrencyTypeSelector()
    currencies.forEach(currency => this.appendNewOption(selector, currency, currency))
  }

  resetCurrencyTypeSelector() {
    let selector = document.getElementById('base_package_currency_type-selector')
    this.clearOptions(selector)
    selector.querySelector('.selected-value').innerHTML = I18n.t('views.commons.select.currency')
    this.appendNewOption(selector, I18n.t('views.commons.select.currency'), '', true)
  }

  toggleMinimumPrice(event) {
    let priceType = event.currentTarget.value
    let autonumericElement = AutoNumeric.getAutoNumericElement(this.minimumPriceTarget)
    if (priceType == 'fixed') {
      autonumericElement.set(0)
      this.minimumPriceTarget.value = 0
      this.minimumPriceTarget.disabled = true
    } else {
      this.minimumPriceTarget.disabled = false
    }
  }
}
