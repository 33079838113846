import { css } from '@emotion/react';

export const MontserratLight = css`
  font-family: 'Montserrat', sans-serif;
`;
export const global = css`
  @import url(//fonts.googleapis.com/css?display=swap&family=Montserrat);
  * {
    outline: none;
  }
  body {
    font-family: 'Montserrat';
    scroll-behavior: smooth;
  }
  div[data-radix-popover-content-wrapper] {
    z-index: 999;
  }

.pac-container {

  font-family: inherit;
  padding: 9px 0;
  max-height: 324px;
  overflow: auto;
}

.pac-logo::after {
  content: "";
  display: none;
}

.pac-item {
  border-top: 0px solid transparent;
  padding: 4px 9px;
  margin: 3px 0;
  min-height: 49px;
  display: flex;
  align-items: center;
}

.pac-item:hover {
  background-color: #EEF3FE;
}

.pac-icon {
  margin-top: 0px;
}

.pac-item-query {
  font-size: 14px;
  padding-right: 7px;
}



`;
export default {
  global,
};
