import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/react-hooks';
import I18n from 'i18n-js';
import CompanyLogo from "../../components/CompanyLogo";
import { Redirect } from 'react-router-dom'
import PublicBtn from '../../components/PublicBtn';
import { Box } from "@material-ui/core";
import { UPDATE_USER_RECOVERY_PASSWORD_MUTATION } from '../../api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import base64 from 'react-native-base64'
import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import PasswordField from "../../components/PasswordField";
import useStepsStyles from "../RegisterPartOwner/steps/Steps.styles";
import { PASSWORD_CRITERIA, PasswordCriterion } from '../RegisterPartOwner/steps/StepFive';
import addLocale from '../../utilities/actions/addLocale';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "center",
  },
  divider: {
    margin: theme.spacing(2, 0),
    height: "0px",
  },
  submit: {
    marginTop: "48px",
    background: "#49F2A5",
    "font-size": "14px",
    display: "flex",
    color: "#09814B",
    "border-radius": "5px",
    "align-items": "center",
    "text-align": "center",
  },
  recovery_form: {
    paddingTop: "10%",
    marginTop: "15px",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    color: theme.palette.text.secondary,
  },
  signUpLink: {
    paddingTop: "18%",
  },
  confirmation: {
    margin: "5%",
    padding: theme.spacing(8),
  },
  MediaLogo: {
    width: "60%",
    alignItems: "left",
    justifySelf: "flex-start",
    paddingDown: "3em",
    marginDown: "10%",
    "margin-left": "auto",
    "margin-right": "auto",
    display: "block",
  },
  circularLoader: {
    "margin-left": "auto",
    "margin-right": "auto",
    display: "block",
  },
  exclamation_icon: {
    position: "relative",
    top: "5px",
    left: "5px",
    marginRight: theme.spacing(2),
  },
  companyIcon: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      "margin-left": "3%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      "margin-left": "15%",
    },
    [theme.breakpoints.up("md")]: {
      "margin-left": "7%",
    },
  },
}));

function invalidLength(newPassword, passwordConfirmation) {
  return newPassword.length < 4 || passwordConfirmation.length < 4;
}

function differentPassword(newPassword, passwordConfirmation) {
  return newPassword != passwordConfirmation;
}

function PasswordCriteria({ password }) {
  const classes = useStepsStyles();

  return (
    <Grid>
      <p className={classes.passwordCriteria}>
        {I18n.t("modules.register.password_criteria.title")}
      </p>
      {PASSWORD_CRITERIA.map((criterion) => (
        <PasswordCriterion
          key={criterion.name}
          password={password}
          {...criterion}
        />
      ))}
    </Grid>
  );
}

function NewPassword(props) {
  addLocale();
  let new_password, password_confirmation;
  const classes = useStyles();
  const [toHome, setToHome] = useState(false);
  const [security_token, setToken] = useState(props.location.props.security_token.value);
  const [showError, setShowError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(I18n.t('modules.new_password.mismatch_data'));

  const [saveNewPassword, { data, loading, error }] = useMutation(UPDATE_USER_RECOVERY_PASSWORD_MUTATION, {
    onCompleted(data) {
      if (data) {
        if (data.updateUserPasswordRecovery.success === true) {
          setShowConfirmation(true);
          setTimeout(function () { setToHome(true); }, 5000);
        }
      }
    }, onError(errors) {
      let errorMessages = "";
      errors.graphQLErrors.forEach(error => {
        errorMessages += error.message
      });
      setErrorMessage(errorMessages);
      showError ? null : setShowError(true);
    }
  });

  let response = toHome ? (
    <Redirect to="/" />
  ) : (
    <div>
      <Divider className={classes.divider} />
      <Grid container item xs={12} sm={9} direction="row">
        <CompanyLogo className={classes.companyIcon} />
      </Grid>
      <Divider className={classes.divider} />

      {showConfirmation ? (
          <Grid container item justifyContent="center" alignContent="center" alignItems="center" direction="row" xs={12}>
            <Grid container item justifyContent="center" alignContent="center" alignItems="center" direction="column" xs={12} sm={8} md={4}  >
              <Box component="span" className={classes.confirmation}>
                  <Divider className={classes.divider} />

                  <Typography variant="h6">
                    <AnnouncementRoundedIcon
                      fontSize='large'
                      className={classes.exclamation_icon}
                      />
                    {data.updateUserPasswordRecovery.message}
                  </Typography>
                  <Divider className={classes.divider} />
                </Box>
              <CircularProgress className={classes.circularLoader} />
            </Grid>
          </Grid>
      ) : (
        <div className={classes.recovery_form}>
          <Grid container item justifyContent="center" alignItems="center" direction="row" xs={12}>
            <Grid container item justifyContent="center" alignItems="center" direction="column" xs={12} sm={8} md={4}  >
              <Typography variant="h5" className={classes.title}>
                {I18n.t("modules.new_password.change_pass_title")}
              </Typography>
              <Divider className={classes.divider} />
              <form
                  noValidate
                  className={classes.form}
                  onSubmit={(event) => {
                    event.preventDefault();
                    if (invalidLength(newPassword, passwordConfirmation)) {
                      setErrorMessage(
                          I18n.t("messages.errors.users.short_password")
                      );
                      setShowError(true);
                    } else if (
                        differentPassword(newPassword, passwordConfirmation)
                    ) {
                      setErrorMessage(I18n.t("modules.new_password.mismatch_data"));
                      setShowError(true);
                    } else {
                      saveNewPassword({
                        variables: {
                          userInput: {
                            newPassword: base64.encode(newPassword),
                            confirmPassword: base64.encode(passwordConfirmation),
                            token: security_token,
                          },
                        },
                      });
                    }
                  }}
              >
                <PasswordField
                    onChange={(event) => {
                      setNewPassword(event.target.value);
                      setShowError(false);
                    }}
                    autoFocus
                    ref={(node) => {
                      new_password = node;
                    }}
                    label={I18n.t(
                        "modules.new_password.password_field_placeholder"
                    )}
                />
                <PasswordCriteria password={newPassword} />
                <PasswordField
                    onChange={(event) => {
                      setPasswordConfirmation(event.target.value);
                      setShowError(false);
                    }}
                    error={showError}
                    id="password_confirmation"
                    name="password_confirmation"
                    ref={(node) => {
                      password_confirmation = node;
                    }}
                    helperText={showError ? errorMessage : null}
                    label={I18n.t(
                        "modules.new_password.new_password_field_placeholder"
                    )}
                />
                {loading ? (
                    <CircularProgress className={classes.circularLoader} />
                ) : (
                    <PublicBtn
                        disabled={
                          showError ||
                          !newPassword ||
                          !passwordConfirmation ||
                          newPassword !== passwordConfirmation ||
                          PASSWORD_CRITERIA.some(({ regex }) => !regex.test(newPassword))
                        }
                        type="submit"
                        label={I18n.t("views.commons.save")}
                    />
                )}
              </form>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );

  return response;
}

export default NewPassword;
