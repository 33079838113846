import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    for (let element of this.element.getElementsByClassName('page-link')) {
      if (element.getAttribute('href') === '#') {
        element.removeAttribute('href');
      }
    };
  }
}
