import gql from "graphql-tag";

const VERIFY_EMAIL_MUTATION = gql`
  query ($input: AuthProviderData!) {
    validAuthProvider(authProvider: $input) {
      validAuthProvider
    }
  }
`;
export default VERIFY_EMAIL_MUTATION;
