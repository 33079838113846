import { Controller } from "stimulus"

export default class extends Controller {
  disableElement(event) {
    const link = event.currentTarget;
    const { id } = link.dataset;
    const targetElement = document.getElementById(id);
    
    targetElement.classList.add('hide');

    setTimeout(() => {
      targetElement.classList.remove('hide');
    }, 360000);
  }
}
