import BaseController from './base_controller'
import I18n from "i18n-js";
import { ThumbDownOutlined } from "@material-ui/icons";

export default class extends BaseController {
  static values = { monthsOptions: Object,
                    users: Object,
                    validPostalCodes: Array
                  }
  static targets = ['divPeriodicity',
                    'divPeriodicityMonth',
                    'divInvoiceTo',
                    'divInvoiceUserFields',
                    'selectedPayment',
                    'selectedPeriodicityType',
                    'selectedPeriodicityMonth',
                    'selectedInvoiceRecipient',
                    'selectedReceptor',
                    'modal',
                    'submitButton',
                    'businessName',
                    'rfc',
                    'fiscalDomicile',
                    'fiscalRegime',
                    'cfdi',
                    'p_business_name',
                    'p_rfc',
                    'p_postal_code',
                    'p_fiscal_regime',
                    'p_cfdi_use',
                    'checkboxSelectAllPayments',
                    'groupedIrs',
                    'irsAllSpanButton',
                    'irsAllModalTitleAll',
                    'irsAllModalTitleSelected',
                    'notifyIrs',
                    'PaymentIrsForm'
                  ];

  call(event) {
    this.getSelectedUsersFromPayments()
    this.prepareModal()
    this.hiddenElemnts()
    this.disableInput(this.submitButtonTarget)
    this.handleSelectedOptionsReceptors()
    $(this.modalTarget).modal();
  }

  connect() {
    this.checkPaymentBySessionStorage()
    this.checkedButtonAllPayments()
    this.showGroupedIrsButton()
  }

  getCheckboxesFromSessionStorage() {
    return JSON.parse(sessionStorage.getItem('checkboxes')) || {};
  }

  submit() {
    const form = this.PaymentIrsFormTarget;
    form.action = I18n.t('routes.controllers.mx_companies.irs_global');
    this.appendSelectedPaymentsToForm(form, this.getCheckboxesFromSessionStorage());
    form.submit();
    sessionStorage.removeItem('checkboxes');
  }

  appendSelectedPaymentsToForm(form, checkboxes) {
    Object.keys(checkboxes).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'payments[ids_hidden][]';
      input.value = key;
      form.appendChild(input);
    });
  }

  getSelectedUsersFromPayments() {
    // Using a Set to avoid duplicate values
    this.selected_users = new Set()
    this.properties = new Set()
    const checkboxes = this.getCheckboxesFromSessionStorage();
    Object.keys(checkboxes).forEach(key => {
      const values = checkboxes[key];
      if (values.user_id !== undefined) {
        this.selected_users.add(values.user_id)
        this.properties.add(values.property_id)
      }
    });
  }

  prepareModal() {
    const receptors_list = this.modalTarget.querySelector('#receptors_list-button')
    const receptors_list_value = this.modalTarget.querySelector('#receptors_list-hidden-value')
    const receptors_list_options = this.modalTarget.querySelector('#receptors_list-dropdown-menu')
    const more_than_one_user_selected = this.selected_users.size > 1
    const only_general_public_users = this.selected_users.size == 1 && this.selected_users.has(0)

    if(more_than_one_user_selected || only_general_public_users) {
      // Select gets disabled and set to 'general public'
      // as only option available
      receptors_list.disabled = true
    } else {
      receptors_list.disabled = false
    }

    this.selectedReceptorTarget.value = 'general_public'
    receptors_list.querySelector('.selected-value').innerHTML = I18n.t('views.fiscal_identification.global_invoice_modal.general_public')
    receptors_list_options.querySelector("[data-value='general_public']").className = 'cf-selector-option selected'
    receptors_list_options.querySelector("[data-value='add_invoice_data']").className = 'cf-selector-option'
  }

  submitPaymentIrsForm(event) {
    event.stopPropagation()
    const irsAllIcon = document.querySelectorAll('.irs-all > .fa');
    irsAllIcon.forEach(icon => {  icon.className = 'fa fa-fw fa-spinner fa-pulse'; });
    this.appendSelectedPaymentsToForm(event.target, this.getCheckboxesFromSessionStorage());
    sessionStorage.removeItem('checkboxes');
  }

  showGroupedIrsButton() {
    const checkboxes = this.getCheckboxesFromSessionStorage();
    const checked = Object.keys(checkboxes)
    const keyWithFalseGlobalInvoice = checked.find(key => checkboxes[key].global_invoice === 'false');

    if (keyWithFalseGlobalInvoice || checked.length < 2) {
      this.groupedIrsTarget.classList.add('collapse');
      this.groupedIrsTarget.setAttribute('disabled', 'disabled');
    } else {
      this.groupedIrsTarget.classList.remove('collapse');
      this.groupedIrsTarget.removeAttribute('disabled');
    }
  }

  selectAllPayments(event) {
    const checkboxes = this.getCheckboxesFromSessionStorage();
    this.selectedPaymentTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
      if (checkbox.checked) {
        this.saveCheckboxInSessionStorage(checkbox, checkboxes)
      } else {
        this.removeCheckboxFromSesionStorage(checkbox, checkboxes)
      }
    });
    this.showGroupedIrsButton()
    this.changeIrsButtonText()
  }

  checkedButtonAllPayments() {
    const checkboxAllPayments = this.checkboxSelectAllPaymentsTarget
    const checkboxesChecked = document.querySelectorAll('.checkbox_payment:checked')
    const numberOfCheckboxes = this.selectedPaymentTargets.length
    if (checkboxesChecked.length === numberOfCheckboxes && numberOfCheckboxes > 0) {
      checkboxAllPayments.checked = true
    } else {
      checkboxAllPayments.checked = false
    }
  }

  changeIrsButtonText() {
    const checkboxes = this.getCheckboxesFromSessionStorage();
    const checked = Object.keys(checkboxes).length;
    if (checked > 0) {
      this.irsAllSpanButtonTarget.textContent = I18n.t('views.mx_companies.navigation.bill_selected');
      this.irsAllModalTitleAllTarget.style.display = 'none';
      this.irsAllModalTitleSelectedTarget.style.display = 'block';
      this.notifyIrsTarget.style.display = 'block';
    } else {
      this.irsAllSpanButtonTarget.textContent = I18n.t('views.mx_companies.navigation.bill_payments');
      this.irsAllModalTitleAllTarget.style.display = 'block';
      this.irsAllModalTitleSelectedTarget.style.display = 'none';
      this.notifyIrsTarget.style.display = 'none';
    }
  };

  checkPaymentBySessionStorage() {
    const checkboxes = this.getCheckboxesFromSessionStorage();
    Object.keys(checkboxes).forEach(key => {
      const checkbox = document.getElementById(`payment_${key}`);
      if (checkbox) {
        checkbox.checked = true;
      }
    })
    this.changeIrsButtonText()
    this.showGroupedIrsButton()
  }

  prepareCheckbox(event) {
    const checkboxes = this.getCheckboxesFromSessionStorage();
    const checkbox = event.target
    if (checkbox.checked) {
      this.saveCheckboxInSessionStorage(checkbox, checkboxes)
    } else {
      this.removeCheckboxFromSesionStorage(checkbox, checkboxes)
    }
    this.checkedButtonAllPayments()
    this.showGroupedIrsButton()
    this.changeIrsButtonText()
  }

  saveCheckboxInSessionStorage(checkbox, checkboxes) {
    if (!checkboxes.hasOwnProperty(checkbox.value)) {
      checkboxes[checkbox.value] = {
        'user_id': checkbox.dataset.userId,
        'property_id': checkbox.dataset.propertyId,
        'global_invoice': checkbox.dataset.globalInvoice
      };
    }
    sessionStorage.setItem('checkboxes', JSON.stringify(checkboxes));
  }

  removeCheckboxFromSesionStorage(checkbox, checkboxes) {
    if (checkboxes.hasOwnProperty(checkbox.value)) {
      delete checkboxes[checkbox.value];
      sessionStorage.setItem('checkboxes', JSON.stringify(checkboxes));
    }
  }

  hiddenElemnts() {
    this.divPeriodicityTarget.style.display = 'none'
    this.divPeriodicityMonthTarget.style.display = 'none'
    this.divInvoiceToTarget.style.display = 'none'
    this.divInvoiceUserFieldsTarget.style.display = 'none'
  }

  // Logic modal for type of recipient (General Public or Invoice to)
  handleSelectedOptionsReceptors() {
    const receptors_list = this.selectedReceptorTarget

    if (receptors_list.value == 'general_public') {
      this.handlePeriodicity()
    } else {
      this.handleInvoiceTo()
    }
  }

  // Logic modal for General Public
  handlePeriodicity() {
    this.divInvoiceToTarget.style.display = 'none'
    this.divInvoiceUserFieldsTarget.style.display = 'none'
    this.divPeriodicityTarget.style.display = 'block'
    this.divPeriodicityMonthTarget.style.display = 'block'
    this.disableInput(this.submitButtonTarget)

    this.handleSelectedOptionsMonths()
  }

  handleSelectedOptionsMonths() {
    const divPeriodicity_type = this.selectedPeriodicityTypeTarget
    const month_select = this.selectedPeriodicityMonthTarget.nextElementSibling
    const monthly_values = [1,2,3,4]
    const type_monthly_hash = { 1: 'monthly', 5: 'bimonthly' }

    let options,
        type_monthly,
        type_of_periodicity_selected = divPeriodicity_type.value

    if (monthly_values.includes(Math.round(divPeriodicity_type.value))) {
      type_of_periodicity_selected = 1
    }

    type_monthly = typeof type_monthly_hash[Math.round(type_of_periodicity_selected)] == 'undefined' ? 'none' : type_monthly_hash[Math.round(type_of_periodicity_selected)]

    this.clearOptionsMonths()
    this.createOptions(type_monthly)
    this.setPromptMonths(month_select)

    this.handleFacturarButtonForGeneralPublic()
  }

  createOptions(type_monthly) {
    const periodicityTypeSelector = document.getElementById('periodicity_month-selector')
    const month_hash = this.monthsOptionsValue

    let array_of_options = typeof month_hash[type_monthly] == 'undefined' ? [] : month_hash[type_monthly]

    Object.keys(array_of_options).sort().forEach( key => this.appendNewOption(periodicityTypeSelector, array_of_options[key], key))
  }

  clearOptionsMonths() {
    const month_select = document.querySelector('#periodicity_month-dropdown-menu')
    month_select.innerHTML = ''
  }

  setPromptMonths(dropdown) {
    dropdown.querySelector('.selected-value').innerHTML = I18n.t('views.fiscal_identification.global_invoice_modal.month.prompt')
    dropdown.closest('.cf-selector').querySelector("input[type='hidden']").value = ''
    dropdown.querySelectorAll('.selected-true').forEach(div => {
      div.classList.remove('selected-true')
      div.classList.add('selected-false')
    })
  }

  // Logic modal for Invoice To
  handleInvoiceTo() {
    this.divPeriodicityTarget.style.display = 'none'
    this.divPeriodicityMonthTarget.style.display = 'none'
    this.divInvoiceToTarget.style.display = 'block'
    this.divInvoiceUserFieldsTarget.style.display = 'none'
    this.updateSelectOptions()

  }

  prepareMultiselect(data) {
    let action = 'click->cf-selector#changeOption'
    let dropdownMenu = document.getElementById('invoice_recipient-dropdown-menu')
    this.selectedInvoiceRecipientTarget.setAttribute('data-action','change->global-invoice#handleSelectedInvoiceRecipient')
    for (let element in data) {
      const div = document.createElement('div')
      div.classList.add('dropdown-item', 'dropdown-item-selectable')
      div.setAttribute('data-action', action)
      div.setAttribute('data-name', data[element].label)
      div.setAttribute('data-value', data[element].value)
      let iconDiv = document.createElement('div')
      let icon = document.createElement('div')
      icon.classList.add('fa', 'fa-check', 'selected-false')
      iconDiv.classList.add('icon-option')
      iconDiv.appendChild(icon)
      div.appendChild(iconDiv)
      let name = document.createElement('div')
      name.classList.add('name-option')
      name.innerHTML = data[element].label
      div.appendChild(name)
      dropdownMenu.appendChild(div)
    }
  }

  updateSelectOptions(){
    const dropdown = this.selectedInvoiceRecipientTarget.nextElementSibling
    let dropdownOptions = dropdown.querySelectorAll('.dropdown-item-selectable')
    dropdownOptions.forEach(dropdownOption => { dropdownOption.remove() })
    let user_options = [{label: I18n.t('views.fiscal_identification.global_invoice_modal.invoice_to.other'), value: 'new_user'} ]
    let visited = []
    this.properties.forEach(key => {
      let property_users = this.usersValue[key];
      Object.keys(property_users).forEach(id => {
        if(!visited.includes(id)){
          user_options.push({label: property_users[id], value: id})
          visited.push(id)
        }
      })
    });
    this.prepareMultiselect(user_options);
  }

  // Logic multiselect options of Bill to (Others or Propiertario)
  handleSelectedInvoiceRecipient(){
    this.divInvoiceUserFieldsTarget.removeAttribute('style')
    const select_invoice_user_select = this.selectedInvoiceRecipientTarget

    if (select_invoice_user_select.value == 'new_user') {
      this.cleanFields()
      this.handleFacturarButton()
    } else {
      this.getUserIdentificationData(select_invoice_user_select.value)
    }
  }

  getUserIdentificationData(resident_id){
    let data = new URLSearchParams({ user_id: JSON.stringify(resident_id) })

    fetch('/mx_companies/set_user_identification_data' + '?' + data, {
      method: 'GET',
      headers: { 'contentType': 'application/json',
                 'Accept': 'application/json',
                 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    })
    .then(response => response.json())
    .then(data => {
      this.automaticFillingOfResidentData(data)
    })
  }

  automaticFillingOfResidentData(response){

    let options_cfdi = JSON.parse(this.cfdiTarget.dataset.optiontexts)
    let option = document.createElement('option')
    let hash_of_fields = [{ element: this.businessNameTarget, value: response.business_name },
                          { element: this.rfcTarget, value: response.rfc },
                          { element: this.fiscalDomicileTarget, value: response.fiscal_domicile },
                          { element: this.fiscalRegimeTarget, value: response.fiscal_regime },
                          { element: this.cfdiTarget, value: response.cfdi }]

    option.value = response.cfdi
    option.innerHTML = response.cfdi + ' - ' + options_cfdi[response.cfdi]
    cfdi.append(option)

    hash_of_fields.forEach(function(field) {
      field.element.value = field.value
      field.element.setAttribute('readOnly', true)
    })
    this.handleFacturarButton()
  }

  validGlobalBusinessName(event){
    let input = event.target;
    let trim_business_name = input.value.trim();

    input.value = trim_business_name.toUpperCase();
  }

  handleFacturarButton(){
    this.businessNameTarget.value = this.businessNameTarget.value.replace(/^\s+/g, '')
    this.rfcTarget.value = this.rfcTarget.value.replace(/[^a-z0-9]/gi,'')
    this.fiscalDomicileTarget.value = this.fiscalDomicileTarget.value.replace(/[^0-9]/gi,'')
    const validBusinessName = this.businessNameTarget.value.length > 0,
          validRFC = validateRfc(this.rfcTarget.value).isValid,
          validPostalCode = this.validatePostalCode(this.fiscalDomicileTarget.value,this.validPostalCodesValue),
          validRegime = this.fiscalRegimeTarget.value,
          validCfdi = this.cfdiTarget.value

    const fiscal_identification_hash = { businessName: { valid: validBusinessName,
                                                         input: this.businessNameTarget,
                                                         div: this.p_business_nameTarget,
                                                         message: I18n.t('views.fiscal_identification.global_invoice_modal.error.business_name') },
                                         rfc:          { valid: validRFC,
                                                         input: this.rfcTarget,
                                                         div: this.p_rfcTarget,
                                                         message: I18n.t('views.fiscal_identification.global_invoice_modal.error.rfc') },
                                         postalCode:   { valid: validPostalCode,
                                                         input: this.fiscalDomicileTarget,
                                                         div: this.p_postal_codeTarget,
                                                         message: I18n.t('views.fiscal_identification.global_invoice_modal.error.postal_code') },
                                         fiscalRegime: { valid: validRegime,
                                                         input: this.fiscalRegimeTarget,
                                                         div: this.p_fiscal_regimeTarget,
                                                         message: I18n.t('views.fiscal_identification.global_invoice_modal.error.fiscal_regime') },
                                         cfdi:         { valid: validCfdi,
                                                         input: this.cfdiTarget,
                                                         div: this.p_cfdi_useTarget,
                                                         message: I18n.t('views.fiscal_identification.global_invoice_modal.error.cfdi') } }

    Object.values(fiscal_identification_hash).forEach(field_data => this.setErrorFiscalIdentification(field_data))

    if(validBusinessName && validRFC && validPostalCode && validRegime && validCfdi){
      this.enableInput(this.submitButtonTarget)
    } else{
      this.disableInput(this.submitButtonTarget)
    }

  }

  handleFacturarButtonForGeneralPublic(){
    const month_select = this.selectedPeriodicityMonthTarget

    if(month_select.value != ''){
      this.enableInput(this.submitButtonTarget)
    }else{
      this.disableInput(this.submitButtonTarget)
    }
  }

  cleanFields(){
    this.businessNameTarget.value = ''
    this.rfcTarget.value = ''
    this.fiscalDomicileTarget.value = ''
    this.fiscalRegimeTarget.value = ''
    this.cfdiTarget.value = ''
    this.enableInput(this.businessNameTarget)
    this.enableInput(this.rfcTarget)
    this.enableInput(this.fiscalDomicileTarget)
    this.enableInput(this.fiscalRegimeTarget)
    this.setInputToReadOnly(this.cfdiTarget)
  }

  enableInput(target){
    target.removeAttribute('disabled')
    target.removeAttribute('readOnly')
  }

  disableInput(target){
    target.setAttribute('disabled', true)
  }

  setInputToReadOnly(target){
    target.setAttribute('readOnly', true)
  }

  setErrorColor(element){
    if(element.className.includes(' errorField')){
      return
    }
    element.className += ' errorField'
  }

  setDefaultColor(element){
    element.className = element.className.replace(' errorField', '')
  }

  setErrorMessage(element, msg){
    if(element.children.hasOwnProperty('error_message_div')){
      return
    }
    const small = document.createElement('small')
    const nodes = element.querySelectorAll('label, span')
    const node = nodes[nodes.length - 1]
    small.setAttribute('id', 'error_message_div')
    small.classList.add('amplification', 'errorLabel')
    small.innerHTML = msg
    node.after(small)
  }

  removeErrorMessage(element){
    if(!element.children.hasOwnProperty('error_message_div')){
      return
    }
    element.children.error_message_div.remove()
  }

  setErrorFiscalIdentification(hash){
    if(!hash.valid ){
      this.setErrorColor(hash.input)
      this.setErrorMessage(hash.div, hash.message)
    }else{
      this.setDefaultColor(hash.input)
      this.removeErrorMessage(hash.div)
    }
  }

  validatePostalCode(number,validCodes){
    let valid_flag = false
    validCodes.forEach(range => {
      if(this.inRange(number, range[0], range[1])) valid_flag = true
    });
    return valid_flag
  }

  inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
  }

}
