import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { GLOBAL_STATE } from "../../state";

const authLink = setContext((_, { headers }) => {
  var customHeaders = {};
  if (GLOBAL_STATE.userToken)
    customHeaders.JWTACCESSTOKEN = GLOBAL_STATE.userToken;
  if (GLOBAL_STATE.communityId)
    customHeaders.communityId = GLOBAL_STATE.communityId;
  return {
    headers: {
      ...headers,
      ...customHeaders
    }
  };
});

const httpLink = new HttpLink({
  uri: "/graphql"
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
