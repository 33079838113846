import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['baseExcel', 'advancedExcel']

  displayExcels(event) {
    let type = event.currentTarget.value
    if (type == 'base') {
      this.baseExcelTargets.forEach(button => button.classList.remove('hidden'))
      this.advancedExcelTargets.forEach(button => button.classList.add('hidden'))
    } else {
      this.baseExcelTargets.forEach(button => button.classList.add('hidden'))
      this.advancedExcelTargets.forEach(button => button.classList.remove('hidden'))
    }
  }
}
