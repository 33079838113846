import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['modal', 'button', 'generalPublic'];

  call(event) {
    this.buildAndToggleModal(event)
  }

  checkboxCall(event) {
    let actuator = event.target
    if (actuator.checked) {
      this.buildAndToggleModal(event)
      const handleModalDismiss = (event) => {
        if (event.target.classList.contains('fade')) {
          const changeEvent = new Event('change');
          actuator.checked = false
          actuator.dispatchEvent(changeEvent);
          $(this.modalTarget).modal('hide')
        }
      }
      this.modalTarget.addEventListener('click', handleModalDismiss)
      $(this.modalTarget).one('hidden.bs.modal', function () {
        this.modalTarget.removeEventListener('click', handleModalDismiss)
      }.bind(this));
    }
  }

  buildAndToggleModal(event) {
    let actuator = event.target
    let missing_configurations = actuator.dataset.missingConfigurations;
    if (missing_configurations) {
      event.preventDefault();
      this.setMissingConfigurations(missing_configurations)
      this.setConfigurationLink(actuator.dataset.userId, actuator.dataset.profileId)
      $(this.modalTarget).modal();
      if (this.hasButtonTarget) {
        this.deactivateModal();
        $(this.modalTarget).on('hide.bs.modal', function () {
          this.activateModal();
        }.bind(this));
      }
    }
  }

  setMissingConfigurations(missing_configurations) {
    this.modalTarget.querySelector('#missing-configurations').innerText = missing_configurations;
  }

  setConfigurationLink(userId, profileId) {
    if (profileId) {
      this.modalTarget.querySelector('#to-config').href = this.translateRoute('edit_profile', { id: profileId })
    } else {
      this.modalTarget.querySelector('#to-config').href = userId ? this.translateRoute('edit_profile_profiles', { user_id: userId }) : this.translateRoute('property_users')
    }
  }

  toggleInvoiceModal(event) {
    event.target.checked ? this.activateModal() : this.deactivateModal()
  }

  activateModal() {
    this.buttonTarget.dataset.action = 'click->invoice-modal#call';
  }

  deactivateModal() {
    this.buttonTarget.removeAttribute('data-action')
  }

  submitDefault() {
    this.setGeneralPublic();
    this.buttonTarget.click();
  }

  setGeneralPublic() {
    if (this.generalPublicTarget.type == 'checkbox') {
      this.generalPublicTarget.checked = true
    } else {
      this.generalPublicTarget.value = true
    }
    $(this.modalTarget).modal('hide');

    let tour = document.getElementsByClassName('shepherd-content')[1]
    if (tour != null) tour.hidden = false
  }
}
