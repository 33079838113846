import { Controller } from "stimulus";

const DEFAULT_REJECTED_STATUS_VALUE = '5' //rejected

export default class extends Controller {
  static targets = ['linkToModal']
  static values = {
    'identifier': { type: String, default: DEFAULT_REJECTED_STATUS_VALUE }
  };

  showRejectionModalIfNeeded(event) {
    const statusValue = event.target.value;
    const changedToRejection = statusValue === this.identifierValue
    if (!changedToRejection) return

    this.linkToModalTarget.click()
    this.setPreviousStatus(event)
  }

  setPreviousStatus(event) {
    // Setting the select value to the previous value
    // just in case the user cancel the rejection modal
    // so the user cannot reject any payment wihtout a valid reason
    const { previousStatus } = event.target.dataset
    const { target } = event
    target.value = previousStatus
  }
}
