import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  toggleShow(event) {
    // Add data-cases = [truthy cases for hide] to dom element with 'action'
    // example: data: { action: 'change->form#call', cases: [0, 1] }
    // means if value is 0 or 1, the target 'input' will hide, will show if not.
    let formGroup = this.inputTarget.closest('div.form-group')
    if (event.currentTarget.dataset.cases.includes(event.currentTarget.value)) {
      if (!formGroup.classList.contains('collapse')) { formGroup.classList.add('collapse') }
    } else {
      formGroup.classList.remove('collapse')
    }
  }

  toggleDisabled(event) {
    if (event.currentTarget.dataset.cases.includes(event.currentTarget.value)) {
      if (!this.inputTarget.disabled) { this.inputTarget.setAttribute('disabled', true) }
    } else {
      this.inputTarget.removeAttribute('disabled')
    }
  }
}
