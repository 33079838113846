import { Controller } from 'stimulus'
export default class extends Controller {
  static values = { maxSize: Number }
  static targets = [ 'form', 'modal', 'template', 'successTemplate', 'submitButton', 'submitButtonSpinner', 'file', 'documentName', 'documentExtension', 'documentSize', 'description', 'errorMessage' ]

  connect() {
    if (this.hasTemplateTarget) this.buildModalFromTemplate()

    this.formTarget.addEventListener('submit', event => {
      event.preventDefault();
      event.stopPropagation();

      this.showSpinner()
      fetch(event.target.action, {
          method: 'POST',
          body: new FormData(event.target),
          headers: {
            'Accept': 'application/json',
          }
      }).then(response => {
          console.log(response)
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // or response.text() or whatever the server sends
      }).then(body => {
          let successHTML = this.successTemplateTarget.innerHTML
          this.modalTarget.innerHTML = successHTML
      }).catch(error => {
          console.log(error)
          // TODO handle error
      });
  });
  }

  showSpinner() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.style.display = 'none';
    this.submitButtonSpinnerTarget.style.display = 'flex';
  }

  hideSpinner() {
  }

  toggleUploadModal(event) {
    event.preventDefault();
    $(this.modalTarget).modal()
  }

  buildModalFromTemplate() {
    let modal = this.templateTarget.innerHTML
    this.modalTarget.innerHTML = modal
  }

  cancelModal(event) {
    $(event.currentTarget).closest('.modal-dialog').remove()
  }

  disableConfirmBtn(event){
    event.currentTarget.classList.add('disabled')
  }

  fileExtension() {
    let name = this.fileTarget.value || this.documentNameTarget.value;
    return name.split('.').pop().toLowerCase();
  }

  checkIfCanUpload() {
    this.documentExtensionTarget.style.color = '#757575'
    this.documentExtensionTarget.style.display = 'block'
    this.documentSizeTarget.style.display = 'none'
    if (!this.fileTarget.value && !this.documentNameTarget.value) {
      this.submitButtonTarget.disabled = true;
    } else if (!['doc', 'docx', 'gif', 'img', 'jpg', 'jpeg', 'pdf', 'png', 'ppt', 'pptx', 'rar', 'txt', 'xls', 'xlsx', 'zip'].includes(this.fileExtension())) {
      this.submitButtonTarget.disabled = true;
      this.documentExtensionTarget.style.color = '#DC4B5C';
    } else if (this.fileTarget.files.length > 0 && this.fileTarget.files[0].size > this.maxSizeValue) {
      this.submitButtonTarget.disabled = true;
      this.documentExtensionTarget.style.display = 'none';
      this.documentSizeTarget.style.display = 'block';
    } else {
      this.submitButtonTarget.disabled = false;
      this.documentNameTarget.value = this.fileTarget.files[0].name.split('.').slice(0, -1).join('.');
    }
  }
}
