import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['excelButton', 'meterSelector']

  changeMeterId() {
    var href = new URL(this.excelButtonTarget.href)
    href.searchParams.set('meter_id', this.meterSelectorTarget.value)
    this.excelButtonTarget.href = href
  }
}
