import { Controller } from 'stimulus'
const NOT_SHOW_FUND_INFORMATION = '2'

export default class extends Controller {
  fundLevelDetailInBill(event) {
    let value_setting = event.target.value
    let display = value_setting != NOT_SHOW_FUND_INFORMATION

    this.handleShowInBillFundSetting(display)
  }
  
  handleShowInBillFundSetting(display) {
    let element = document.getElementById('div_show_in_bill_fund_setting')

    if (display){
      element.removeAttribute('style')
    } else {
      element.style.display = 'none'
    }
  }
}
