import { Controller } from "stimulus"

export default class extends Controller {
  showLoading(event) {
    $(`#container_payment_information_${event.params.id}`).collapse()

    const icon = event.target.children[0]

    if (icon.classList.contains('fa-eye')) {
      icon.classList.remove('fa-eye')
      icon.classList.add('fa-eye-slash')
    } else {
      icon.classList.remove('fa-eye-slash')
      icon.classList.add('fa-eye')
      event.target.setAttribute('href', "#")
      event.target.setAttribute('data-turbo', false)
    }

    if (event.target.getAttribute('href') === '#') {
      event.preventDefault()
    }
  }
}
