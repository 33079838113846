import { Controller } from "stimulus";

const bulkEnableButtonFormIdentifier = '#enable-some';
const bulkEnableSubmitButtonIdentifier = '#bulk-enable-button';
const bulkFormIdentifier = '#bulk-property-user-labels-form';
const inputCheckboxIdentifier = 'input[type="checkbox"]';
const syncEnableButtonFormIdentifier = '#sync-all';

export default class extends Controller {
  // Single bulk actions called from views

  bulkEnablePropertyUsers(_event) {
    const isFromSyncCommunity = document.querySelector(bulkEnableButtonFormIdentifier).classList.contains('hidden')
    const checkboxes = this.checkboxes()
    const checkedLabelsQueryString = checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value).join(',')

    if(isFromSyncCommunity) {
      this.submitSync(checkedLabelsQueryString)
    } else {
      this.submitBulk(checkedLabelsQueryString)
    }
  }

  toggleLabelCheckbox(event) {
    event.currentTarget.toggleAttribute('checked')
    this.verifyValidSelection()
  }

  // Auxiliary functions

  checkboxes() {
    const form = document.querySelector(bulkFormIdentifier)
    return Array.from(form.querySelectorAll(inputCheckboxIdentifier))
  }

  submitBulk(checkedLabelsQueryString) {
    const enableButton = document.querySelector(bulkEnableButtonFormIdentifier)
    enableButton.action += `&labels=${checkedLabelsQueryString}`
    enableButton.dispatchEvent(new Event('submit'))
  }

  submitSync(checkedLabelsQueryString) {
    const enableButton = document.querySelector(syncEnableButtonFormIdentifier)
    enableButton.action += `?labels=${checkedLabelsQueryString}`
    enableButton.submit()
  }

  verifyValidSelection() {
    const checkboxes = this.checkboxes()
    const bulkEnablesubmitButtonClassList = document.querySelector(bulkEnableSubmitButtonIdentifier).classList
    const validSelection = checkboxes.some((checkbox) => checkbox.checked)
    validSelection ? bulkEnablesubmitButtonClassList.remove('disabled') : bulkEnablesubmitButtonClassList.add('disabled')
  }
}
