import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['form', 'totalAmount', 'invoicePayment', 'submitButton']

  validatePayment(event){
    event.preventDefault()
    this.submitButtonTarget.disabled = true
    let amountTotalUser = 0
    this.invoicePaymentTargets.forEach(input => {
      amountTotalUser += parseFloat(input.value)
    })

    if (amountTotalUser != this.totalAmountTarget.value ) {
      this.submitButtonTarget.disabled = false
      alert(I18n.t('views.admin.invoices.pay_multiple_invoices.errors.amount_diff'))
      return
    }
    if (this.formTarget.checkValidity()) {
      this.formTarget.submit()
    } else {
      this.submitButtonTarget.disabled = false
      this.formTarget.reportValidity()
    }
  }
}
