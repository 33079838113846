import { Controller } from 'stimulus';
import BaseController from '../base_controller'
import I18n from 'i18n-js';
import Sortable from 'sortablejs';

export default class extends BaseController {
  static targets = [
    'description',
    'endDate',
    'title',
    'submitButton',
    'saveButton',
    'hideButton',
    'questionsBlock',
    'publishSurvey',
    'countingMethod',
    'saveDataSurvey',
    'noSaveDataSurvey',
    'sortableList',
    'countingType',
    'votingWeight',
    'allowDefaulters',
    'closeSurveyButton',
    'deleteSurveyButton'
  ];
  static values = {
    surveyId: Number,
    published: Boolean,
   };

  connect() {
    if (this.hasSaveButtonTarget) {
      this.showLegalModal();
      this.addListenerToReturn();
      this.addListenersToMandatoryInputs();
      this.messageOnLeftPage();
      $('#cancel_edit_survey, #submit_edit_survey').on(
        'hidden.bs.modal',
        function (event) {
          window.onbeforeunload = function () {
            return 'message?';
          };
        }
      );
      this.draggableItems();
    }
  }

  async togglePublicSurvey(e) {
    e.currentTarget.classList.add('disabled-switch');

    await fetch(e.currentTarget.dataset.path, {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        'Accept': "text/vnd.turbo-stream.html",
        'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content')
      }
    }).then(response => response.text())
    .then(body => Turbo.renderStreamMessage(body));
  }

  toggleLegalAdvice(e) {
    const parent = e.currentTarget.closest('.legal-advice'),
    content = parent.querySelector('.legal-advice-content'),
    angleDown = parent.querySelector('.fa-angle-down'),
    angleUp = parent.querySelector('.fa-angle-up');

    content.classList.toggle('hidden');
    angleDown.classList.toggle('hidden');
    angleUp.classList.toggle('hidden');
  }

  showLegalModal() {
    if (document.querySelector('#legal_notice_modal')) {
      $('#legal_notice_modal').modal('show');
    }
  }

  draggableItems() {
    new Sortable(this.sortableListTarget, {
        animation: 150,
        onEnd: function(evt) {
          evt.from.querySelectorAll('li').forEach( (li, index) => {
            li.querySelector('.n-order').innerText = index + 1
          })
        }
    });
  }

  messageOnLeftPage() {
    window.onbeforeunload = function () {
      return 'message?';
    };
  }

  toggleHideButton(e) {
    e.preventDefault();
    if (this.questionsBlockTarget.style.display === '') {
      this.questionsBlockTarget.style.display = 'none';
      this.hideButtonTarget.textContent = I18n.t('views.commons.show');
    } else {
      this.questionsBlockTarget.style.display = '';
      this.hideButtonTarget.textContent = I18n.t('views.commons.hide');
    }
  }

  showQuestionsBlock() {
    if (this.questionsBlockTarget.style.display === 'none') {
      this.questionsBlockTarget.style.display = '';
      this.hideButtonTarget.textContent = I18n.t('views.commons.hide');
    }
  }

  showCancelModal() {
    $('#cancel_edit_survey').modal('show');
    window.onbeforeunload = null;
  }

  showSubmitModal() {
    if (this.getEmptyMandatoryInputs().length == 0) {
      $('#submit_edit_survey').modal('show');
      window.onbeforeunload = null;
    } else {
      this.checkInputs();
    }
  }

  showDeleteModal(e) {
    let path = e.currentTarget.dataset.path
    this.deleteSurveyButtonTarget.setAttribute('href', path);
    $('#delete_survey_modal').modal('show');
  }

  showCloseModal(e) {
    let path = e.currentTarget.dataset.path
    this.closeSurveyButtonTarget.setAttribute('href', path);
    $('#close_survey_modal').modal('show');
  }

  checkInputs() {
    this.getEmptyMandatoryInputs().forEach((input) => {
      let type = '';
      if (input.classList.contains('option-text-field')) {
        type = 'option';
      } else if (input.classList.contains('date_selector')) {
        type = 'endDate';
      }

      this.checkMandatoryInput(input, type);
    });
  }

  getEmptyMandatoryInputs() {
    const questionOptions = [...this.getQuestionOptionsInput()].filter(
        (input) => input.value == '' && input.eliminated != 1
      ),
      titleEndate = [this.titleTarget, this.endDateTarget].filter(
        (input) => input.value == ''
      );
    return [...questionOptions, ...titleEndate];
  }

  checkMandatoryInput(input, type) {
    const errorHtml = `
    <div class='mandatory-message'>${I18n.t('views.meters.blank_fields_warning')}</div>
    `;
    let nextElementSibling =
      type === 'option' || type === 'endDate'
        ? input.form.nextElementSibling
        : input.nextElementSibling;

    if (input.value) {
      input.classList.remove('mandatory-field');

      if (nextElementSibling && nextElementSibling.classList.contains('mandatory-message')) {
        nextElementSibling.remove();
      }
    } else {
      input.classList.add('mandatory-field');

      if (input.dataset.showAlert === 'false') { return };
      if (nextElementSibling && !nextElementSibling.classList.contains('mandatory-message')) {
        type === 'option' || type === 'endDate'
          ? input.form.insertAdjacentHTML('afterend', errorHtml)
          : input.insertAdjacentHTML('afterend', errorHtml);
      } else if ( nextElementSibling == null){
        input.form.insertAdjacentHTML('afterend', errorHtml)
      }
    }
  }

  addListenerToReturn() {
    let returnButton = document.getElementById('returnButton');
    returnButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.showCancelModal();
    });
  }

  addListenersToMandatoryInputs() {
    this.getQuestionOptionsInput().forEach((input) => {
      input.addEventListener('blur', () => {
        let type = input.classList.contains('option-text-field')
          ? 'option'
          : 'question';
        this.checkMandatoryInput(input, type);
      });
    });

    [this.titleTarget, this.endDateTarget].forEach((input) => {
      input.addEventListener('blur', () => {
        let type = input.classList.contains('date_selector')
          ? 'endDate'
          : 'title';
        this.checkMandatoryInput(input, type);
      });
    });
  }

  getQuestionOptionsInput() {
    return document.querySelectorAll(
      '.question-text-field, .option-text-field'
    );
  }

  async saveSurvey() {
    this.saveButtonTarget.classList.add('disabled');
    if (!this.publishedValue) {this.submitButtonTarget.classList.add('disabled')};
    this.saveDataSurveyTarget.classList.add('disabled');
    this.noSaveDataSurveyTarget.classList.add('disabled');

    try {
      await Promise.all(this.saveDataSurvey(this.surveyIdValue));
      window.onbeforeunload = null;
      window.location.href = this.publishedValue ? this.translateRoute('surveys') : this.translateRoute('surveys', { search: { published: false} });
    } catch (error) {
      triggerAlert(error);
      this.scrollToTop();
      this.saveButtonTarget.classList.remove('disabled');
      if (!this.publishedValue) {this.submitButtonTarget.classList.remove('disabled')};
      this.saveDataSurveyTarget.classList.remove('disabled');
      this.noSaveDataSurveyTarget.classList.remove('disabled');
    }
  }

  avoidWarning() {
    window.onbeforeunload = null;
  }

  async publishForm(e) {
    e.preventDefault();
    try {
      await Promise.all(this.saveDataSurvey(this.surveyIdValue));
      this.publishSurveyTarget.submit();
    } catch (error) {
      triggerAlert(error);
      $('#submit_edit_survey').modal('hide');
      this.scrollToTop();
      this.publishSurveyTarget.querySelector("input[type='submit']").removeAttribute("disabled");
    }
  }

  saveDataSurvey(surveyId) {
    const fetchPromises = [];

    // Save title
    let title = this.titleTarget.value;
    fetchPromises.push(this.submitForm(title, null, surveyId, 'title'));

    // Save description
    let description = this.descriptionTarget.value;
    fetchPromises.push(
      this.submitForm(description, null, surveyId, 'description')
    );

    // Save end date
    let endDate = this.endDateTarget.value;
    fetchPromises.push(this.submitForm(endDate, null, surveyId, 'end_date'));

    // Save questions titles
    let questions = document.querySelectorAll('.question-text-field');
    questions.forEach((q) => {
      let questionIndex = +q.getAttribute('data-id'),
        value = q.value;
      fetchPromises.push(
        this.submitForm(value, questionIndex, surveyId, 'question')
      );
    });

    // Save options value
    let options = document.querySelectorAll('.option-text-field');
    options.forEach((o) => {
      let optionIndex = +o.getAttribute('data-id'),
        value = o.value;
      fetchPromises.push(
        this.submitForm(value, optionIndex, surveyId, 'option')
      );
    });

    // Save old counting method
    if (this.hasCountingMethodTarget) {
      let countingMethod = this.countingMethodTarget.checked;
      fetchPromises.push(
        this.submitForm(countingMethod ? 'property_users_in_charge_weighted' : 'all_property_users_not_weighted', null, surveyId, 'counting_method')
      );
    }

    // Save new counting methods and order priority
    if (this.hasCountingTypeTarget) {
      let countingType = this.countingTypeTarget.checked;
      fetchPromises.push(
        this.submitForm(countingType ? 'one_vote_per_resident' : 'one_vote_per_property', null, surveyId, 'counting_type' )
      )

      if (countingType) {
        fetchPromises.push(
          this.submitForm('same_weight', null, surveyId, 'voting_weight')
        )
      }

      let data = {};

      if (countingType) {
        this.element.querySelectorAll('.vote-per-resident input.form-check-input:checked').forEach((input, index) => {
          data[input.value] = index + 1
        })
      } else {
        let inputs = this.sortableListTarget.querySelectorAll('input.form-check-input:checked');

        inputs.forEach((input, index) => {
          data[input.value] = index + 1
        })
      }

      fetchPromises.push(
        this.submitForm(data, null, surveyId, 'order_priority' )
      )
    }

    // Save voting weight
    if (this.hasVotingWeightTarget && !this.countingTypeTarget.checked) {
      let votingWeightMethod = this.votingWeightTarget.checked;
      fetchPromises.push(
        this.submitForm(votingWeightMethod ? 'same_weight' : 'proration', null, surveyId, 'voting_weight')
      );
    }

    // Save allow defaulters
    if (this.hasAllowDefaultersTarget) {
      let allowDefaulters = this.allowDefaultersTarget.checked;
      fetchPromises.push(
        this.submitForm(allowDefaulters, null, surveyId, 'allow_defaulters')
      );
    }

    return fetchPromises;
  }

  submitForm(textValue, fieldId, surveyId, type) {
    return new Promise((resolve, reject) => {
      this.timeout = setTimeout(() => {
        fetch(this.translateRoute('save_options_title_survey', { id: surveyId }), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
          },
          body: JSON.stringify({
            fieldId,
            type,
            textValue,
          }),
        }).then(r => r.json()).then( data => {
          if (data.updated) {
            resolve();
          } else {
            if (type === 'order_priority') {
              this.element.querySelectorAll('.option-priority-container').forEach(e => e.classList.add('mandatory-field'))
            }
            reject(data.errors);
          }
        });
      });
    });
  }

  scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
  }

  checkedPriority() {
    this.element.querySelectorAll('.option-priority-container').forEach(e => {
      if (e.classList.contains('mandatory-field')) {
        e.classList.remove('mandatory-field');
      }
    })
  }

  togglePriorityInput(){
    if (this.countingTypeTarget.checked) {
      this.element.querySelector('.vote-per-resident').classList.remove('hidden');
      this.element.querySelector('.vote-per-property').classList.add('hidden');
      this.element.querySelector('.vote-per-resident').scrollIntoView();
      this.element.querySelector('#prorationOption').classList.add('hidden');
      this.votingWeightTarget.checked = true;
    } else {
      this.element.querySelector('.vote-per-resident').classList.add('hidden');
      this.element.querySelector('.vote-per-property').classList.remove('hidden');
      this.element.querySelector('.vote-per-property').scrollIntoView();
      this.element.querySelector('#prorationOption').classList.remove('hidden');
    }
  }
}
