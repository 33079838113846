import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['maintenanceDiv', 'maintenanceName', 'maintenanceStartDate', 'maintenanceFrequency', 'maintenanceEndDate',
    'maintenanceUnit', 'supplierDiv']

  toggleCreateMaintenance(event) {
    let checked = event.currentTarget.checked
    if (checked) {
      this.maintenanceDivTarget.classList.remove('hide')
      this.supplierDivTarget.classList.remove('hide')
    } else {
      this.supplierDivTarget.classList.add('hide')
    }
  }
}
