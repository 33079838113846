import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        fontFamily: theme.typography.fontFamily,
    },
    twoSectionsRoot: {
        flexGrow: 1,
    },
    wizardLabels: {
        fontSize: '20px',
        fontFamily: theme.typography.fontFamily,
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '4%',
        fontWeight: 'normal',
        color: theme.palette.text.disabled
    },
    wizardLabelSelected: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '20px',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        padding: '4%',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
    },
    wizardTitle: {
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
        fontSize: '32px',
        fontWeight: 'bold',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
    },
    titleBox: {
        marginBottom: '14%'
    },
    smStepCounter: {
        height: '100vh',
        display: 'none',
        background: theme.palette.public.smooth_background,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.public.smooth_background,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'flex;',
        },
    },
    mdStepCounter: {
        display: 'none',
        background: theme.palette.public.smooth_background,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.public.smooth_background,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    bullet: {
        display: 'block',
        margin: '24px'
    },
    stepBlock: {
        margin: "auto",
        width: "35em",
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.up('md')]: {
            padding: '0%',
            paddingTop: '1px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "10%",
            padding: "20px 40px",
        },
    },
    companyIcon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            width: '265px',
            padding: '5%'
        },
    },
    smCompanyIcon: {
        [theme.breakpoints.up('xs')]: {
            width: '50%',
            margin: '20%'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    sidebar: {
        backgroundColor: theme.palette.public.smooth_background,
        height: '100vh',
        margin: '-10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    stepper:{
        backgroundColor: theme.palette.public.smooth_background,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        paddingTop: "30px",
        "padding-block-end": "25px"
    },
    chevronIcon: {
        color: theme.palette.action.active,
        textDecoration: 'none',
        "&:hover": {
            fontWeight: "bold",
        },
        "&:disabled": {
            color: theme.palette.action.disabled,
            opacity: 0.4,
        }
    },
}));

export default useStyles;
