import gql from "graphql-tag";

export const ALL_COMMUNITIES = gql`
  query ($input: CommunityFilterInput!) {
    communities(input: $input) {
      name
      id
      address
    }
  }
`;
export const ALL_PROPERTIES = gql`
  query ($communityId: ID!, $propertyInput: PropertyInput!) {
    communityProperties(communityId: $communityId, input: $propertyInput) {
      propertyId
      propertyName
    }
  }
`;

export const CREATE_PROPERTY_USER_REQUEST = gql`
  mutation ($code: String, $communityId: ID!, $propertyId: ID!) {
    createPropertyUserRequest(
      input: { code: $code, communityId: $communityId, propertyId: $propertyId }
    ) {
      id
    }
  }
`;

export const VALIDATE_PROPERTY_QR = gql`
  mutation ($code: String!, $communityId: ID!, $propertyId: ID!) {
    validatePropertyQr(
      input: { code: $code, communityId: $communityId, propertyId: $propertyId }
    ) {
      isValid
    }
  }
`;
