import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  call(event){
    this.toggle()
  }

  toggle(){
    let attribute = this.inputTarget.dataset.attribute
    this.inputTarget.toggleAttribute(attribute)
  }

  toggleClass(){
    let classToToggle = this.inputTarget.dataset.class
    this.inputTarget.classList.toggle(classToToggle)
  }
}
