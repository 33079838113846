import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

const AUTONUMERIC_FORMAT = { decimalCharacter: '.', decimalPlaces: 4, decimalCharacterAlternative: ',', allowDecimalPadding: false }

export default class extends Controller {
  static targets = ['modal', 'submitButton']

  openModal(event) {
    let currentTarget = event.currentTarget
    $(this.modalTarget).modal()
    this.submitButtonTarget.setAttribute('action', currentTarget.dataset.actionUrl)
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }

  setAutonumeric(event) {
    let element = event.currentTarget
    if (!AutoNumeric.getAutoNumericElement(element)) {
      new AutoNumeric(element, AUTONUMERIC_FORMAT)
    }
  }
}
