import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $.reloadAllAutonumeric()
  }

  setValues(event) {
    let debt_id = event.target.dataset.debtId
    let property_id = event.target.dataset.propertyId
    let rounding_number = event.target.dataset.roundingNumber
    let price = event.target.dataset.price

    var key_property = `property_id_${property_id}`
    var key_property_price = `property_id_${property_id}_price`
    var current_price = parseFloat(localStorage.getItem(key_property_price)) || 0
    let key_property_debts_amount = `property_id_${property_id}_debts_amount`
    let hidden_debts_amount = document.getElementById(`selected_debts_amount_${property_id}`)
    let debts_amount_json = JSON.parse(hidden_debts_amount.value.length == 0 ? '{}' :  hidden_debts_amount.value)
    let debt_ids = localStorage.getItem(key_property)?.split(',').filter(item => item !== '') || []

    let string_debts_json

    if (debt_id && !debt_ids.includes(`${debt_id}`)) {
      debt_ids.push(debt_id);
      localStorage.setItem(key_property, debt_ids);
      current_price += parseFloat(price)
      string_debts_json = this.addDebtAmount(debts_amount_json, debt_id, price)
      hidden_debts_amount.value = string_debts_json
      localStorage.setItem(key_property_debts_amount, string_debts_json);

    } else {
      debt_ids = debt_ids.filter(item => item !== `${debt_id}`)
      localStorage.setItem(key_property, debt_ids);
      this.updatedDiscounts(debt_id, current_price, key_property_price);
      current_price -= parseFloat(price)
      string_debts_json = this.removeDebtAmount(debts_amount_json, debt_id)
      hidden_debts_amount.value = string_debts_json
      localStorage.setItem(key_property_debts_amount, string_debts_json);
    }

    current_price = this.roundCurrentPrice(current_price, rounding_number);
    localStorage.setItem(key_property_price, current_price.toFixed(rounding_number));
    let hidden_debts = document.getElementById(`selected_debts_${property_id}`)
    hidden_debts.value = debt_ids.filter(item => item !== '').join(',')

    this.paymentAssignment(event.target)
  }
    
  addDebtAmount(debts_amount_json, debt_id, debt_price) {
    if (!debts_amount_json.hasOwnProperty(debt_id)) {
      debts_amount_json[debt_id] = debt_price
    }

    if (document.querySelector('#negative-warning')) { removeValueWarning() }

    return JSON.stringify(debts_amount_json)
  }
    
  removeDebtAmount(debts_amount_json, debt_id) {
    delete debts_amount_json[debt_id]

    if (Object.keys(debts_amount_json).length === 0) {
      if (document.querySelector('#negative-warning')) { addValueWarning() }
    }

    return JSON.stringify(debts_amount_json)
  }
    
  roundCurrentPrice(current_price, rounding_number) {
    if (current_price < 0) {
      return 0;
    }
    return parseFloat(current_price.toFixed(rounding_number));
  }
    
  updatedDiscounts(debt_id) {
    let debt_discounts = document.querySelectorAll(`.debt_${debt_id}_discounts:checked:not(:disabled)`);

    if (!debt_discounts.length) {
      return false;
    } else {
      debt_discounts.forEach((discount) => {
        discount.checked = false;
        discount.dispatchEvent(new Event('change'));
      });
    }
    return true
  }
    
  paymentAssignment(element) {
    let price_id

    let debt_amount_field = document.getElementById(`debts_amount_${element.getAttribute('id').split('_')[1]}`)
    debt_amount_field.disabled = !element.checked || false

    if ( Boolean($(element.getAttribute('target'))[0]) ) {
      price_id = element.getAttribute('target')
    } else {
      price_id = '#price_new_payment'
    }

    let property_id = element.getAttribute('property_id')
    let key_property_price = 'property_id_' + property_id + '_price'
    let price = parseFloat(localStorage.getItem(key_property_price)) || 0
    
    $(price_id).prop('disabled', $(element.getAttribute('friends')).is(':checked') || price != 0)

    AutoNumeric.getAutoNumericElement(price_id).set(price)
    $(price_id).siblings('#payment_price_val').val(price)
  }
}

