import BaseController from '../base_controller'
import I18n from 'i18n-js';

export default class extends BaseController {
  static targets = ['modal', 'serviceBillingRow', 'reassignFundsButton']

  showModal() {
    $(this.modalTarget).modal()
  }

  performReasignFunds() {
    const service_billing_proratables_ids = this.__getServiceBillingProratablesIds()
    const selected_fund_id = this.__getSelectedFundId()

    const url = Routes.reassign_funds_service_billings_path()
    const data = { reassign_funds: { selected_fund_id, service_billing_proratables_ids } }
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    this.reassignFundsButtonTarget.classList.add('disabled')
    this.reassignFundsButtonTarget.innerText = I18n.t('views.service_billings.index.reassigning_funds')

    fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers,
    })
      .finally(() => { window.location.reload() })
  }

  __getServiceBillingProratablesIds() {
    const selectedServiceBillingRows = this.serviceBillingRowTargets
      .filter(
        (serviceBillingRow) => serviceBillingRow
          .querySelector('input[type="checkbox"]')
          .checked
      )

    return selectedServiceBillingRows.map(
      (serviceBillingRow) => {
        const id = serviceBillingRow
          .querySelector('input[type="hidden"].service_billing_proratable_id')
          .value

        return id
      }
    )
  }

  __getSelectedFundId() {
    return document.querySelector('#funds-selector-hidden-value').value
  }
}
