import { Controller } from "stimulus"
import AutoNumeric from 'autonumeric'
import I18n from "i18n-js";

const AUTONUMERIC_FORMAT_DISCOUNT_HOURS = { decimalCharacter: '.', decimalPlaces: 2, decimalCharacterAlternative: ',', allowDecimalPadding: false }
const MAX_DISCOUNT_HOURS = 360;

export default class extends Controller {
  static targets = [ 'disabledButton', 'enabledButton', 'cleanAllButton', 'enabledEmployees',
                     'discountHoursInput', 'form' ]

  initialize() {
    this.submittingForm = false;
  }

  formTargetConnected() {
    this.submittingForm = false;
  }

  call(event){
    if (this.submittingForm) {
      return false;
    }

    switch (event.currentTarget.dataset.tab) {
      case 'worked_days':
        if (this.workedDaysTab(event)) {
          this.submitForm()
        }
        break;
      default:
        this.submitForm()
    }
  }

  async submitForm() {
    this.submittingForm = true
    await this.formTarget.requestSubmit()
    this.refreshModal()
  }

  discountHoursInputTargetConnected(element) {
    element.addEventListener('keyup', () => {
      let value = element.value
      if (value > MAX_DISCOUNT_HOURS) {
        element.value = MAX_DISCOUNT_HOURS
        this.addHoursWarning(element);
      } else {
        this.removeHoursWarning(element);
      }
    })
  }

  addHoursWarning (element) {
    let arr = element.id.split('-');
    let id = arr.pop();
    let warning = document.getElementById(`js-days-discount-warning-${id}`);
    warning.style.display = 'block';
    element.classList.add("warning-input");
  }

  removeHoursWarning (element) {
    let arr = element.id.split('-');
    let id = arr.pop();
    let warning = document.getElementById(`js-days-discount-warning-${id}`);
    warning.style.display = 'none';
    element.classList.remove('warning-input')
  }

  workedDaysTab(event) {
    let id = event.currentTarget.id.split('-').pop();
    let worked_days = document.getElementById(`worked-days-${id}`);
    let bono_days = document.getElementById(`bono-days-${id}`);
    let license_days = parseInt(document.getElementById(`license-days-${id}`).value);
    let discount_days = parseInt(document.getElementById(`discount-days-${id}`).value);

    if (worked_days == event.currentTarget) {
      if (bono_days != null) bono_days.value = event.currentTarget.value;
      if (license_days + discount_days > parseInt(event.currentTarget.value)) {
        worked_days.classList.add('invalid_input')
        worked_days.setAttribute('data-toggle', 'tooltip')
        worked_days.setAttribute('data-title', I18n.t('views.remunerations.salary_payment_drafts.worked_days_validation_warning'))
        $('[data-toggle="tooltip"]').tooltip();

        return false
      }

      worked_days.classList.remove('invalid_input')
      worked_days.setAttribute('data-title', '')
    }

    return true
  }

  enableHourDiscountSubmit() {
    let disabledButton = document.getElementById('salary-payment-disabled-button');
    let enabledButton = document.getElementById('salary-payment-enabled-button');

    enabledButton.classList.remove('hidden');
    disabledButton.classList.add('hidden');
  }

  enableSubmit() {
    let disabledButton = document.getElementById('salary-payment-disabled-button');
    let enabledButton = document.getElementById('salary-payment-enabled-button');

    enabledButton.classList.remove('hidden');
    disabledButton.classList.add('hidden');
  }

  enabledEmployeesTargetConnected() {
    const enabledEmployees = this.enabledEmployeesTarget.value

    if (enabledEmployees == 0) {
      this.cleanAllButtonTarget.classList.add('disabled')
    } else {
      this.cleanAllButtonTarget.classList.remove('disabled')
    }
  }

  async refreshModal() {
    await new Promise(resolve => setTimeout(resolve, 1000));

    var selectedMonth = document.getElementById('month').value;
    var selectedYear = document.getElementById('year').value;

    fetch(`/remuneraciones/libro_liquidaciones/refresh_modal/${selectedYear}/${selectedMonth}`, {
      method: 'GET',
      headers:  {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => response.json())
    .then(data  => {
      let massiveSalaryEmployees = document.getElementById('employees-count');
      let massiveSalaryWithoutWorkedDaysIds = document.getElementById('worked-days-ids');

      massiveSalaryEmployees.textContent = data.employees;
      if (data.has_part_time_employees_without_worked_days) {
        massiveSalaryWithoutWorkedDaysIds.classList.remove('hidden');
      }
      else{
        massiveSalaryWithoutWorkedDaysIds.classList.add('hidden');
      }
    })
  }
}
