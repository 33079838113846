import React, { useState, useEffect } from "react";
import StepLayout from "../StepLayout";
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
} from "@material-ui/core/";
import I18n from "i18n-js";
import PublicBtn from "../../../components/PublicBtn";
import { GLOBAL_STATE, setGlobalState } from "../../../state";
import WizardTitle from "../WizardTitle";
import useStyles from "./Steps.styles";

function StepTwoContent({ next }) {
  const classes = useStyles();
  const [showNameError, setNameShowError] = useState(false);
  const [showLastNameError, setLastNameShowError] = useState(false);
  const { email, firstName, lastName, country_code } = GLOBAL_STATE;
  const [disableButton, setDisableButton] = useState(true);
  const [name, setName] = useState(firstName);
  const [UserLastName, setUserLastName] = useState(lastName);
  const [country, setCountry] = useState(country_code);

  useEffect(() => {
    if (name && UserLastName && country) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [name, UserLastName, country]);

  function handleUserInfo() {
    setGlobalState({
      email: email,
      firstName: name,
      lastName: UserLastName,
      country_code: country,
    });
    next();
  }

  return (
    <Grid className={classes.formContainer}>
      <WizardTitle number={1} />
      <TextField
        autoComplete="name"
        autoFocus={true}
        className={classes.textfield}
        error={showNameError}
        fullWidth
        helperText={
          showNameError ? I18n.t("modules.forgot_pass.invalid_name") : null
        }
        id="name"
        inputProps={{ maxLength: 50, tabIndex: 1 }}
        label={I18n.t("modules.register.name")}
        margin="normal"
        name="name"
        variant="outlined"
        defaultValue={firstName}
        onChange={(event) => {
          setName(event.target.value);
        }}
      />
      <TextField
        autoComplete="lastname"
        className={classes.lastnameTextfield}
        error={showLastNameError}
        fullWidth
        helperText={
          showLastNameError
            ? I18n.t("modules.forgot_pass.invalid_lastname")
            : null
        }
        id="lastname"
        inputProps={{ maxLength: 50, tabIndex: 2 }}
        label={I18n.t("modules.register.lastname")}
        margin="normal"
        name="lastname"
        variant="outlined"
        defaultValue={lastName}
        onChange={(event) => {
          setUserLastName(event.target.value);
        }}
      />
      <div className={classes.radioContainer}>
        <Typography variant="body2">
          {I18n.t("modules.register.country")}
        </Typography>
        <RadioGroup
          aria-label="country"
          name="country"
          value={country}
          onChange={(event) => {
            setCountry(event.target.value);
          }}
        >
          <FormControlLabel
            value="CHILE"
            label={I18n.t("modules.register.countries.chile")}
            control={
              <Radio
                tabIndex={3}
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
          />
          <FormControlLabel
            value="MEXICO"
            label={I18n.t("modules.register.countries.mexico")}
            control={
              <Radio
                tabIndex={4}
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
          />
          <FormControlLabel
            value="UNITED_STATES"
            label={I18n.t("modules.register.countries.united_states")}
            control={
              <Radio
                tabIndex={5}
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
          />
        </RadioGroup>
      </div>
      <PublicBtn
        disabled={disableButton}
        label={I18n.t("modules.register.next")}
        onClick={handleUserInfo}
        tabIndex={5}
      />
    </Grid>
  );
}

function StepTwo({ previous, next }) {
  return (
    <StepLayout
      previous={previous}
      next={next}
      numberStepLabel={1}
      numberStep={2}
      StepContent={StepTwoContent}
    />
  );
}

export default StepTwo;
