import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'deleteButton']

  showModal(event) {
    const element = event.currentTarget
    const fileId = element.dataset.fileId
    const path = this.deleteButtonTarget.href
    this.deleteButtonTarget.href = `${path}/${fileId}`
    $(this.modalTarget).modal()
  }
}
