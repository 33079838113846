import { Controller } from "stimulus";
import I18n from "i18n-js";

const formIdentifier = '#property-user-labels-form';
const inputCheckboxIdentifier = 'input[type="checkbox"]';
const enableModalIdentifier = '#enable-access-control';
const enableSubmitButtonIdentifier = '#enable-button';

export default class extends Controller {
  // Bulk actions called from views

  bulkDisablePropertyUsers(_event) {
    const disableButton = document.querySelector('#disable-some')
    disableButton.dispatchEvent(new Event('submit'))
  }

  prepareBulkDisableModal(event) {
    event.preventDefault()
    $('#bulk-disable-access-control').modal()
  }

  prepareBulkEnableModal(event) {
    event.preventDefault()
    $('#bulk-enable-access-control').modal()
  }
  
  // Single actions called from views

  toggleStatus(event) {
    const slider = event.currentTarget
    const enable = slider.dataset.enable == 'true'
    const showLabels = slider.dataset.showLabels == 'true'

    if(enable) {
      showLabels ? this.prepareIndividualEnableModal(slider) : this.updateStatus(slider)
    } else {
      this.prepareIndividualDisableModal(slider)
    }
  }

  toggleLabelCheckbox(event) {
    event.currentTarget.toggleAttribute('checked')
    this.verifyValidSelection()
  }

  submitEnableAndLabelsUpdate(event) {
    this.labelsModal(false)
    const checkboxes = this.checkboxes()
    const checkedLabels = checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
    const slider = document.getElementById(event.currentTarget.dataset.sliderId)
    this.updateStatus(slider, checkedLabels)
  }

  // Auxiliary functions

  disablePropertyUser(event) {
    $('#disable-access-control').modal('hide');
    const slider = document.getElementById(event.currentTarget.dataset.sliderId)
    this.updateStatus(slider)
  }

  handleResponse(data, slider) {
    if(data.success) {
      this.refreshLabelsColumnsText(data)
      this.toggleLabelsEditIcon(slider.dataset.propertyUserId)
      triggerNotice(data.message)
      allow_multiple_notices()
    } else { 
      this.toggleSlider(slider)
      triggerAlert(data.message)
      allow_multiple_alerts()
    }
  }

  prepareIndividualDisableModal(slider) {
    const disableButton = document.querySelector('#disable-button')
    disableButton.dataset.sliderId = slider.id
    document.querySelector('#property-user-name').innerText = I18n.t('views.access_control.confirm_property_user_disable', { name: slider.dataset.name })
    $('#disable-access-control').modal()
  }

  toggleLabelsEditIcon(propertyUserId) {
    const editIcon = document.getElementById(`property_user_${propertyUserId}_edit`)
    editIcon?.classList?.toggle('hidden')
  }

  toggleSlider(slider) {
    if(slider.classList.contains("fa-toggle-on")) {
      slider.classList.remove("fa-toggle-on")
      slider.classList.add("fa-toggle-off")
      slider.dataset.enable = 'true'
    } else {
      slider.classList.add("fa-toggle-on")
      slider.classList.remove("fa-toggle-off")
      slider.dataset.enable = 'false'
    }
  }

  updateStatus(slider, checkedLabels = []) {
    const enable = slider.dataset.enable
    let url = slider.dataset.url + "?enable=" + enable
    if (checkedLabels.length > 0) url += `&labels=${checkedLabels.join(',')}`
    this.toggleSlider(slider)

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    fetch(url, {
      method: 'PUT',
      headers: headers
    })
    .then(response => response.json())
    .then(data => this.handleResponse(data, slider))
  }

  // Auxiliary functions used only when labels are involved

  checkboxes() {
    const form = document.querySelector(formIdentifier)
    return Array.from(form.querySelectorAll(inputCheckboxIdentifier))
  }

  labelsModal(show) {
    show ? $(enableModalIdentifier).modal() : $(enableModalIdentifier).modal('hide')
  }

  prepareIndividualEnableModal(slider) {
    const enableSubmitButton = document.querySelector(enableSubmitButtonIdentifier)
    enableSubmitButton.dataset.sliderId = slider.id
    $(enableModalIdentifier).modal()

    const labelsArray = JSON.parse(slider.dataset.labelsArray).map(label => parseInt(label))
    this.checkboxes().forEach(checkbox => checkbox.checked = labelsArray.includes(parseInt(checkbox.value)))
    this.verifyValidSelection()
    this.labelsModal(true)
  }

  refreshLabelsColumnsText(controllerResponseData) {
    const newLabelsColumnContent = controllerResponseData.labelsText
    const newLabelsColumnResponsiveContent = controllerResponseData.labelsTextResponsive

    const labelsColumn = document.getElementById(`property_user_${controllerResponseData.propertyUserId}_labels_td`)
    const labelsColumnResponsive = document.getElementById(`property_user_${controllerResponseData.propertyUserId}_labels_td_responsive`)
    if (labelsColumn) labelsColumn.textContent = newLabelsColumnContent
    if (labelsColumnResponsive) labelsColumnResponsive.textContent = newLabelsColumnResponsiveContent
  }

  verifyValidSelection() {
    const checkboxes = this.checkboxes()
    const enablesubmitButtonClassList = document.querySelector(enableSubmitButtonIdentifier).classList
    const validSelection = checkboxes.some((checkbox) => checkbox.checked)
    validSelection ? enablesubmitButtonClassList.remove('disabled') : enablesubmitButtonClassList.add('disabled')
  }
}
