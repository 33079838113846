import { Reducer, StateType } from 'typesafe-actions'
import { AnyAction, CombinedState, combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as wizards } from 'react-redux-wizard'
import { History } from 'history'

export const createRootReducer = (
  appHistory: History,
): Reducer<CombinedState<any>, AnyAction> =>
  combineReducers({ router: connectRouter(appHistory), wizards })

export type RootState = StateType<ReturnType<typeof createRootReducer>>
