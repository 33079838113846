import BaseController from './base_controller';
import I18n from 'i18n-js';

export default class extends BaseController {
  static targets = ['fileInput', 'files', 'inputContainer', 'examineButton', 'inputText', 'fileBox', 'destroyButton', 'modal', 'inputAccept', 'inputMaxFileSize']
  static values = { size: String }

  connect() {
    this.multiple = this.inputContainerTarget.getAttribute('multiple') == 'true'
    this.documentAttribute = this.inputContainerTarget.getAttribute('document')
    this.objectAttribute = this.inputContainerTarget.getAttribute('object')
    this.nameAttribute = this.inputContainerTarget.getAttribute('name')
  }

  selectFile() {
    this.fileInputTarget.click();
  }

  fileInputId(startAt) {
    if (this.multiple) {
      return `${this.objectAttribute}_${startAt}_${this.documentAttribute}`
    } else {
      return this.nameAttribute ? `${this.nameAttribute}_${this.documentAttribute}` : `${this.objectAttribute}_${this.documentAttribute}`;
    }
  }

  fileInputName(startAt) {
    if (this.multiple) {
      return this.nameAttribute ? `${this.nameAttribute}[${startAt}][${this.documentAttribute}]` : `${this.objectAttribute}[${startAt}][${this.documentAttribute}]`;
    } else {
      return `${this.objectAttribute}[${this.documentAttribute}]`
    }
  }

  updateFileInfo() {
    const files = this.fileInputTarget.files;
    if (files.length > 0) {
      const file = files[0]
      this.removeWarning()
      const validFile = this.validFile(file)
      if (validFile.valid) {
        this.appendFile(file)
      } else {
        this.removeFile()
        this.addWarning(validFile.message)
      }
    }
  }

  removeFile() {
    const dataTransfer = new DataTransfer()
    const input = this.fileInputTarget
    input.files = dataTransfer.files
    input.setAttribute('value', 'false')
    input.dispatchEvent(new Event('change'))
  }

  validFile(file) {
    const validFileSize = this.validFileSize(file)
    const validFileExtension = this.validFileExtension(file)
    const validFile = { valid: validFileSize.valid && validFileExtension.valid, message: [validFileExtension.message, validFileSize.message].filter(Boolean).join(', ') }
    return validFile
  }

  validFileExtension(file) {
    const acceptedExtensions = this.fileInputTarget.getAttribute('accept')
    if (acceptedExtensions) {
      const extension = file.name.split('.').pop().toLowerCase()
      const valid = acceptedExtensions.includes(extension)
      if (!valid) this.inputAcceptTarget.classList.add('invalid')
      const message = valid ? '' : I18n.t('components.file_input.message.file_format_not_allowed')
      return { valid: acceptedExtensions.includes(extension), message: message }
    } else {
      return { valid: true, message: '' }
    }
  }

  validFileSize(file) {
    if (this.hasinputMaxFileSizeTarget) {
      let maximumFileSize = this.fileInputTarget.getAttribute('max-file-size')
      if (maximumFileSize) {
        const valid = file.size <= maximumFileSize
        const message = valid ? '' : I18n.t('components.file_input.message.file_size_exceeded')
        if (!valid) this.inputMaxFileSizeTarget.classList.add('invalid')
        return { valid: valid, message: message }
      } else {
        return { valid: true, message: '' }
      }
    } else {
      return { valid: true, message: '' }
    }
  }

  appendFile(file) {
    let startAt = parseInt(this.inputContainerTarget.getAttribute('start-at'))
    const newFileInput = this.createFileInput(startAt, file)
    let fileElement = document.createElement('div')
    let fileNameElement = document.createElement('div')
    let fileIconElement = this.createFileIcon(startAt)
    fileElement.classList.add('file')
    fileElement.setAttribute('data-file-index', startAt)
    fileNameElement.classList.add('file-name')
    fileNameElement.innerHTML = file.name
    fileElement.appendChild(fileNameElement)
    fileElement.appendChild(fileIconElement)
    fileElement.appendChild(newFileInput)
    fileElement.setAttribute('id', `file-${startAt}`)
    this.filesTarget.append(fileElement)
    this.inputContainerTarget.setAttribute('start-at', startAt + 1)
    this.filesTarget.classList.add('active')
    let maximumFileAmount = this.fileInputTarget.getAttribute('max-file-amount')
    let currentFileCount = this.filesTarget.children.length
    if (!this.multiple) {
      this.disableExamineButton()
      if (this.sizeValue == 'sm') {
        this.inputContainerTarget.style.display = 'none'
      }
    }
    else if (currentFileCount == maximumFileAmount) {
      this.disableExamineButton()
    }
  }

  createFileInput(startAt, file) {
    const newFileInput = document.createElement('input')
    newFileInput.type = 'file'
    const newFileList = new DataTransfer();
    newFileList.items.add(file)
    newFileInput.files = newFileList.files
    newFileInput.style.display = 'none'
    newFileInput.name = this.fileInputName(startAt)
    newFileInput.id = this.fileInputId(startAt)

    return newFileInput
  }

  createFileIcon(startAt) {
    const id = `${this.fileInputId(startAt)}_delete_button`
    let fileIconElement = document.createElement('i')
    fileIconElement.classList.add('delete-icon', 'fa', 'fa-times')
    fileIconElement.setAttribute('data-action', 'click->file-input#deleteFile')
    fileIconElement.setAttribute('file-target', `file-${startAt}`)
    fileIconElement.setAttribute('id', id)

    return fileIconElement
  }

  deleteFile(event) {
    let element = event.currentTarget
    let id = element.getAttribute('file-target')
    this.filesTarget.querySelector(`#${id}`).remove()
    this.removeFile()
    let maximumFileAmount = this.fileInputTarget.getAttribute('max-file-amount')
    let currentFileCount = this.filesTarget.children.length
    if (!this.multiple) {
      this.enableExamineButton(false)
      if (this.sizeValue == 'sm') {
        this.inputContainerTarget.removeAttribute('style')
      }
    }
    else if (currentFileCount < maximumFileAmount){
      this.enableExamineButton(true)
    }
  }

  startDeleting(event) {
    event.preventDefault()
    let button = event.currentTarget
    let modal = this.modalTarget
    $(modal).modal('hide')
    let url = ''
    let fileBox = this.getFileBoxPill(button.dataset.fileId)
    url = fileBox.getAttribute('url')
    this.toggleAvailability(fileBox)
    fetch(url)
      .then(response => response.json())
      .then(data => this.handleResponse(data))
  }

  getFileBoxPill(fileBoxId) {
    let fileBox = document.createElement('div')
    let boxId = fileBoxId
    if (this.multiple) {
      fileBox = this.filesTarget.querySelector(`#file-box-${boxId}`)
    } else {
      fileBox = this.fileBoxTarget
    }

    return fileBox
  }

  toggleAvailability(fileBox) {
    let loadButton = fileBox.querySelector('#iconBox')
    let disabled = loadButton.disabled
    loadButton.disabled = !disabled
    loadButton.classList.toggle('disabled')
    let fileNameBox = fileBox.querySelector('.file-name')
    fileNameBox.classList.toggle('disabled')
    let icon = fileBox.querySelector('.fa-solid')
    if (disabled) {
      icon.classList.add('fa-times')
      icon.classList.remove('fa-circle-notch', 'fa-spin')
    } else {
      icon.classList.remove('fa-times')
      icon.classList.add('fa-circle-notch', 'fa-spin')
    }
  }

  handleResponse(data) {
    let fileBox = this.getFileBoxPill(data.file_id)
    if (data.success) {
      if (this.multiple) {
        fileBox.remove()
      } else {
        this.fileBoxTarget.remove()
        this.enableExamineButton(false)
        this.removeFile()
      }
      this.turboMessage('success', I18n.t('components.file_input.message.file_deleted_successfully'))
    } else {
      this.toggleAvailability(fileBox)
      this.turboMessage('alert', I18n.t('components.file_input.message.failure_deleting_file'))
    }
  }

  disableExamineButton() {
    this.examineButtonTarget.removeAttribute('data-action')
    this.examineButtonTarget.classList.add('disabled')
    this.inputTextTarget.setAttribute('disabled', 'disabled')
    this.inputTextTarget.classList.add('disabled')
    this.inputTextTarget.value = ''
    if (!this.multiple){
      this.inputTextTarget.setAttribute('placeholder', I18n.t('components.file_input.message.file_loaded'))
    }
    else {
      this.inputTextTarget.setAttribute('placeholder', I18n.t('components.file_input.message.files_loaded'))
    }
  }

  enableExamineButton(multiple) {
    this.examineButtonTarget.setAttribute('data-action', 'click->file-input#selectFile')
    this.examineButtonTarget.classList.remove('disabled')
    this.inputTextTarget.removeAttribute('disabled')
    this.inputTextTarget.classList.remove('disabled')
    this.inputTextTarget.removeAttribute('placeholder')
    if (!multiple){
      this.filesTarget.textContent = ''
      this.filesTarget.classList.remove('active')
    }
  }

  showDeleteModal(event) {
    if (this.multiple) {
      this.destroyButtonTarget.setAttribute('data-file-id', event.currentTarget.dataset.fileId)
    }
    $(this.modalTarget).modal()
  }

  addWarning(message) {
    const input = this.inputTextTarget
    input.classList.add('invalid')
    input.setAttribute('disabled', 'disabled')
    const finalMessage = message.charAt(0).toUpperCase() + message.slice(1).toLowerCase()
    input.setAttribute('placeholder', finalMessage)
    this.examineButtonTarget.classList.add('invalid')
  }

  removeWarning() {
    this.inputTextTarget.classList.remove('invalid')
    this.examineButtonTarget.classList.remove('invalid')
    if (this.hasInputAcceptTarget) this.inputAcceptTarget.classList.remove('invalid')
    if (this.hasinputMaxFileSizeTarget) this.inputMaxFileSizeTarget.classList.remove('invalid')
    this.inputTextTarget.removeAttribute('placeholder')
  }
}
