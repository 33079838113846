import BaseController from './base_controller'
import I18n from "i18n-js";

export default class extends BaseController {
  static targets = [
    "redoPasModalForm",
    "redoPasModalFormSubmitButton",
    "redoPasModalMessage",
    "redoPasModalTitle",
  ];

  connect() {
    this.addLocale();
  }

  addLocale() {
    const community_language = document.querySelector('#community_language').value;
    if (community_language) {
      I18n.locale = community_language;
    }
  }

  showRedoPasForPropertyConfirmationModal({
    currentTarget: {
      dataset: { property },
    },
  }) {
    const { id, name } = JSON.parse(property);

    this.redoPasModalTitleTarget.innerHTML = I18n.t(
      "views.no_period_bills.redo_pas.for_property.modal.title",
      { name }
    );
    this.redoPasModalMessageTarget.innerHTML = I18n.t(
      "views.no_period_bills.redo_pas.for_property.modal.message"
    );
    this.redoPasModalFormSubmitButtonTarget.setAttribute(
      "value",
      I18n.t("views.no_period_bills.redo_pas.for_property.action")
    );
    this.redoPasModalFormTarget.setAttribute(
      "action",
      this.translateRoute("generate_pas_forcefully_property", { id })
    );
  }

  showRedoPasForAllPropertiesConfirmationModal() {
    this.redoPasModalTitleTarget.innerHTML = I18n.t(
      "views.no_period_bills.redo_pas.for_all.modal.title"
    );
    this.redoPasModalMessageTarget.innerHTML = I18n.t(
      "views.no_period_bills.redo_pas.for_all.modal.message"
    );
    this.redoPasModalFormSubmitButtonTarget.setAttribute(
      "value",
      I18n.t("views.no_period_bills.redo_pas.for_all.modal.action")
    );
    this.redoPasModalFormTarget.setAttribute(
      "action",
      this.translateRoute("generate_pas_forcefully_all_properties")
    );
  }

  collapseNotExpiredDebts(event) {
    const collapsedIcon = 'fa-arrow-circle-right'
    const uncollapsedIcon = 'fa-arrow-circle-down'
    const anchor = event.target

    if (anchor.classList.contains(collapsedIcon)) {
      anchor.classList.replace(collapsedIcon, uncollapsedIcon)
    } else {
      anchor.classList.replace(uncollapsedIcon, collapsedIcon)
    }
  }
}
