import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.hidePreloader();
  }

  hidePreloader() {
    const preloader = document.getElementById('preloader-payments-spinner');
    preloader.classList.add('hidden');
  }

  showPaymentModal(event) {
    event.stopPropagation()
    event.preventDefault()
    $('#pay-card-modal').modal('show')
  }

}
