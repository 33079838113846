import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { querySelector: String }
  connect() {
    const autoNumericInput = document.querySelector(this.querySelectorValue)
    if (autoNumericInput == null) return

    if (!AutoNumeric.isManagedByAutoNumeric(autoNumericInput)) { 
      new AutoNumeric(
        this.querySelectorValue,
        $.parseAutonumericData(JSON.parse(autoNumericInput.dataset.autonumeric))
      )
    }

    $.reloadAllAutonumeric();
  }
}
