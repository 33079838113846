import { Controller } from 'stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['button']

  startLoading() {
    this.buttonTarget.classList.add('disabled')
    this.buttonTarget.innerHTML = '<i class="fa-solid fa-circle-notch fa-spin"></i>'
    this.buttonTarget.parentNode.querySelector('.tooltip-inner').innerHTML = I18n.t('common.loading_data', { data: '...' })
    Turbo.visit(this.buttonTarget.dataset.href, { turbo: true, acceptsStreamResponse: true })
  }

  stopLoading() {
    this.buttonTarget.disabled = false
    this.buttonTarget.innerHTML = this.originalHTML
  }
}
