import { Controller } from "stimulus"

export default class extends Controller {
  enableEdit(event) {
    const row = event.currentTarget.closest('form')
    row.dataset.original = JSON.stringify({
      name: row.querySelector('.name').value,
      price: row.querySelector('.price').value,
      valid_until: row.querySelector('.valid_until_text').value
    })
    this.showEdit(row)
  }

  disableEdit(event) {
    const row = event.currentTarget.closest('form')
    this.cancelChanges(row)
    this.hideEdit(row)
  }

  hideEdit(row) {
    row.querySelector('.name').classList.add('readonly')
    row.querySelector('.name').parentElement.classList.remove('p-top-17')
    row.querySelector('.price').classList.add('readonly')
    row.querySelector('.name').disabled = true
    row.querySelector('.price').disabled = true
    row.querySelector('.edit').classList.add('hidden')
    row.querySelector('.actions').classList.remove('hidden')
    row.querySelector('.validation-message').classList.add('hidden')
    row.querySelectorAll('.validation-alert').forEach((warning) => { warning.classList.add('hidden') })
    this.hideCalendar(row)
  }

  showEdit(row) {
    row.querySelector('.name').parentElement.classList.add('p-top-17')
    row.querySelector('.name').disabled = false
    row.querySelector('.price').disabled = false
    row.querySelector('.name').classList.remove('readonly')
    row.querySelector('.price').classList.remove('readonly')
    row.querySelector('.edit').classList.remove('hidden')
    row.querySelector('.actions').classList.add('hidden')
    row.querySelector('.validation-message').classList.remove('hidden')
    this.showCalendar(row)
  }

  submitForm(event) {
    const row = event.currentTarget.closest('form')
    this.convertToRawValues()
    this.hideFormGroups(row)
    row.querySelector('button[type="submit"]').click();
    this.hideEdit(row)
    this.confirmChanges(row)
  }

  hideFormGroups(row) {
    row.querySelectorAll('.form-group-without-margin').forEach((element) => {
      element.style.display = 'none';
    });
  }

  showCalendar(row) {
    let input = row.querySelector('.valid_until_text')
    let calendar = row.querySelector('.calendar')
    input.classList.add('hidden')
    calendar.classList.remove('hidden')
  }

  hideCalendar(row) {
    let input = row.querySelector('.valid_until_text')
    let calendar = row.querySelector('.calendar')
    input.classList.remove('hidden')
    calendar.classList.add('hidden')
  }

  confirmChanges(row) {
    let calendar = row.querySelector('.calendar')
    let datepicker = calendar.querySelector('.datepicker')
    row.querySelector('.valid_until_text').value = datepicker.querySelector('input').value
  }

  cancelChanges(row) {
    const attr = JSON.parse(row.dataset.original)
    row.dataset.original = null
    row.querySelector('.name').value = attr.name
    row.querySelector('.price').value = attr.price
    row.querySelector('.valid_until_text').value = attr.valid_until
    $(row.querySelector('.datepicker')).data('DateTimePicker').date(attr.valid_until)
  }

  convertToRawValues() {
    const autonumericInputs = document.querySelectorAll('input[data-autonumeric]')
    autonumericInputs.forEach((input) => {
      let e = AutoNumeric.getAutoNumericElement(input)
      input.value = e.rawValue
    })
  }
}
