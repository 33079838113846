import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton']
  static values = { importing: Boolean }

  enableSubmitButton(event) {
    if (this.filePresent() && !this.importingValue ) {
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.disabled = true
    }
  }

  filePresent() {
    return document.getElementById('file').files.length > 0 || document.getElementById('file').getAttribute('value') == 'true'
  }
}
