import { Controller } from "stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  static values = {
    month: Number,
    year: Number,
    monthName: String,
    activeEmployeesCount: Number,
    salaryPaymentsCount: Number,
    inactiveEmployeesCount: Number,
    hasRut: Boolean
  }

  confirmDownload() {
    let modalText = ''
    if (this.activeEmployeesCountValue === 0 || this.salaryPaymentsCountValue === 0) {
      modalText = I18n.t('views.remunerations.lre.not_salary_payments_alert', { month: this.monthValue, year: this.yearValue })
      document.getElementById('not-salary-payments-modal').querySelector('#body').innerHTML = modalText
      $('#not-salary-payments-modal').modal()
      return
    } else if (this.activeEmployeesCountValue !== this.salaryPaymentsCountValue && this.inactiveEmployeesCountValue == 0) {
      modalText = I18n.t('views.remunerations.lre.missing_salary_payments_alert', { month: this.monthNameValue, year: this.yearValue, active_employees_count: this.activeEmployeesCountValue, salary_payments_count: this.salaryPaymentsCountValue })
    } else if (this.inactiveEmployeesCountValue > 0) {
      modalText = I18n.t('views.remunerations.lre.inactive_employees_present')
    }

    if (modalText !== '') {
      document.getElementById('confirm-download-lre-modal').querySelector('#body').innerHTML = modalText
      $('#confirm-download-lre-modal').modal()
    } else {
      this.onConfirmDownload()
    }
  }

  onConfirmDownload() {
    this.hasRutValue ? this.validateLRE() : this.showRutModal()
  }

  showRutModal() {
    $('#community-without-rut').modal();
    $('#cancel-rut-modal').click(function() {
      $('#downloadLREbutton').removeAttr('disabled');
    });
    $('#close-rut-modal').click(function() {
      $('#downloadLREbutton').removeAttr('disabled');
    });
  }

  validateLRE() {
    fetch(`/remuneraciones/e_books/validate_lre?month=${this.monthValue}&year=${this.yearValue}`, {
      method: 'get',
      headers: {
        'contentType': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => response.json())
    .then(data => {
      data.result ? this.downloadLRE() : this.showLREModal(data.errors);
    })
    .finally( () => {
      $('#community-without-rut').modal('hide')
    })
  }

  downloadLRE() {
    fetch(`/remuneraciones/e_books/download_lre?month=${this.monthValue}&year=${this.yearValue}`, {
      method: 'get',
      headers: {
        'contentType': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    .then(response => {
      const filename = response.headers.get('Content-Disposition').split("UTF-8''")[1]
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        a.remove();
      });
    });
  }

  showLREModal(errors) {
    const maxLength = errors.length > 5 ? 5 : errors.length;

    if (maxLength < 5) {
      document.querySelector('#more_details_btn').style.display = 'none';
    };

    $('#lre-errors-modal').modal();

    let htmlToAppend = "";
    var i = -1;
    while (++i < maxLength) {
      htmlToAppend = htmlToAppend.concat(`<li>${errors[i].employee_fullname}: ${errors[i].message}</li>`)
    }

    document.getElementById('errors-list').innerHTML = htmlToAppend
  }
}
